import { useSuspenseQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import sumBy from 'lodash/sumBy';
import { Info } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Form,
  FormField,
  FormItem,
  FormMessage,
  H3,
  Input,
  PageTitle,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Textarea,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

import {
  ArtifactSearch,
  DataSetItem,
  artifactsFragment,
} from './ArtifactSearch';
import {
  ModelPromptTemplateSearch,
  modelArgsFragment,
  modelPromptTemplatesFragment,
} from './ModelPromptTemplateSearch';
import { WorkflowMatrixDetails } from './WorkflowMatrixDetails';

const getWorkflowOptionsQuery = graphql(
  `
    query getWorkflowOptions {
      ...modelArgs
      ...modelPromptTemplates
      ...artifacts
    }
  `,
  [modelPromptTemplatesFragment, modelArgsFragment, artifactsFragment],
);

const PROMPT_TEMPLATES = 'prompt_templates';
const ARTIFACTS = 'artifacts';

const createWokflowFormSchema = z.object({
  name: z.string().min(3),
  description: z.string().optional(),
});

type FormData = z.infer<typeof createWokflowFormSchema>;

export interface CreateWorkflowPageProps {}

export const CreateWorkflowPage: React.FC<CreateWorkflowPageProps> = () => {
  const logger = useNamedLogger('create-workflow-page');
  useSuspenseQuery(getWorkflowOptionsQuery);

  const form = useForm<FormData>({
    resolver: zodResolver(createWokflowFormSchema),
  });

  const navigate = useNavigate();

  const [
    factVerifierModelPromptTemplateId,
    setFactVerifierModelPromptTemplateId,
  ] = useState<string | undefined>(undefined);

  const [selectedModelPromptTemplates, setSelectedModelPromptTemplates] =
    useState<string[]>([]);

  const [selectedDataSetItems, setSelectedDataSetItems] = useState<
    DataSetItem[]
  >([]);

  const totalArtifacts = sumBy(selectedDataSetItems, (a) => a.totalArtifacts);
  const totalFacts = sumBy(selectedDataSetItems, (a) => a.totalFacts);

  const artifactsAndModelSelectionValid =
    selectedModelPromptTemplates.length > 0 && selectedDataSetItems.length > 0;

  const client = useApiClient();

  const onSubmit = async ({ name, description }: FormData) => {
    if (
      !artifactsAndModelSelectionValid ||
      !factVerifierModelPromptTemplateId
    ) {
      return;
    }

    try {
      const response = await client.eval.startCustomFactVerification({
        body: {
          name,
          description,
          dataSetIds: selectedDataSetItems.map((d) => d.id),
          modelPromptTemplateIds: selectedModelPromptTemplates,
          factVerifierModelPromptTemplateId,
        },
      });

      if (response.status !== 201) {
        toast.error('Failed to create workflow');
      } else {
        const { workflowId } = response.body;
        toast.success('Workflow created successfully');
        navigate(`/admin/fact-verification/${workflowId}`);
      }
    } catch (e) {
      toast.error('Failed to create workflow');
    }
  };

  return (
    <div className="space-y-3">
      <PageTitle subtitle="Select the model prompt templates and artifacts for this workflow">
        Fact Verification
      </PageTitle>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, () => {
            logger.debug('Invalid form submission attempted');
          })}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <Input className="bg-white/5" placeholder="Name" {...field} />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="max-w-lg">
                <label htmlFor="description" className="sr-only">
                  Description
                </label>
                <Textarea
                  className="bg-white/5"
                  placeholder="Description"
                  {...field}
                />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <Button
            className="mt-2"
            disabled={
              !artifactsAndModelSelectionValid ||
              !factVerifierModelPromptTemplateId
            }
          >
            Run Workflow
          </Button>
        </form>
      </Form>

      <H3>Fact Verifier</H3>
      {!factVerifierModelPromptTemplateId && (
        <Alert variant="warning">
          <Info />
          <AlertTitle>Invalid Selection</AlertTitle>
          <AlertDescription>
            Choose the Fact Verification Model Prompt template that will be used
            for this workflow
          </AlertDescription>
        </Alert>
      )}
      <ModelPromptTemplateSearch
        isSingleSelection
        onSelectionChanged={([id]) => setFactVerifierModelPromptTemplateId(id)}
        selectedIds={
          factVerifierModelPromptTemplateId
            ? [factVerifierModelPromptTemplateId]
            : []
        }
        filter={(pt) => pt.template.outputType === 'FACT_VERIFICATION'}
      />

      <H3>Selected Model Prompt Templates and Artifacts</H3>

      {match(artifactsAndModelSelectionValid)
        .with(true, () => (
          <Alert>
            <Info />
            <AlertTitle>Selection Details</AlertTitle>
            <AlertDescription className="space-y-1">
              <WorkflowMatrixDetails
                modelPromptTemplatesCount={selectedModelPromptTemplates.length}
                totalArtifacts={totalArtifacts}
                totalFacts={totalFacts}
              />
            </AlertDescription>
          </Alert>
        ))
        .otherwise(() => (
          <Alert variant="warning">
            <Info />
            <AlertTitle>Invalid Selection</AlertTitle>
            <AlertDescription>
              Select at least one Model Prompt Template and at least one
              Artifact
            </AlertDescription>
          </Alert>
        ))}
      <Tabs defaultValue={PROMPT_TEMPLATES}>
        <TabsList>
          <TabsTrigger value={PROMPT_TEMPLATES}>
            Model Prompt Templates
          </TabsTrigger>
          <TabsTrigger value={ARTIFACTS}>Artifact Datasets</TabsTrigger>
        </TabsList>
        <TabsContent value={PROMPT_TEMPLATES} className="px-2">
          <div className="grid gap-2">
            <ModelPromptTemplateSearch
              selectedIds={selectedModelPromptTemplates}
              onSelectionChanged={(ids) => setSelectedModelPromptTemplates(ids)}
              filter={(pt) => pt.template.outputType === 'SOAP_NOTE'}
            />
          </div>
        </TabsContent>
        <TabsContent value={ARTIFACTS} className="px-2">
          <div className="grid gap-2">
            <ArtifactSearch
              selectedDataSetItems={selectedDataSetItems}
              onSelectionChanged={(items) => setSelectedDataSetItems(items)}
            />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};
