import { patientFragment } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

export const patientsFragment = graphql(
  `
    fragment TenantPatients on Tenants @_unmask {
      patients {
        __typename
        ...patient
      }
    }
  `,
  [patientFragment],
);

export const getPatientsQuery = graphql(
  `
    query getTenantPatients($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantPatients
      }
    }
  `,
  [patientsFragment],
);
