import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@eluve/components';

export const PromptBreadCrumbs: React.FC = () => {
  const { promptTemplateId } = useParams() as {
    promptTemplateId?: string;
  };

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink text="Prompt Templates" to="/admin/prompts" />
        </BreadcrumbItem>
        {promptTemplateId && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink
                text="Prompt Template Details"
                to={`/admin/prompts/${promptTemplateId}`}
              />
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
