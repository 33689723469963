import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { HStack, LinkButton } from '@eluve/components';

import { FactVerifactionBreadcrumbs } from './FactVerifactionBreadcrumbs';
import { OutputTemplateBreadcrumbs } from './OutputTemplateBreadcrumbs';
import { PromptBreadCrumbs } from './PromptBreadcrumbs';
import { TenantBreadCrumbs } from './TenantBreadcrumbs';
import { UserBreadcrumbs } from './UserBreadcrumbs';

export interface AdminBreadcrumbsProps {}

export const AdminBreadcrumbs: React.FC<AdminBreadcrumbsProps> = () => {
  const { tenantId, appointmentId } = useParams() as {
    tenantId: string;
    appointmentId?: string;
  };

  const location = useLocation();
  const isAdminRoot = location.pathname.endsWith('/admin');
  const isPrompts = location.pathname.includes('prompts');
  const isFactVerification = location.pathname.includes('fact-verification');
  const isOutputTemplates = location.pathname.includes('output-templates');
  const isUsers = location.pathname.includes('users');

  if (isAdminRoot) {
    return null;
  }

  return (
    <nav className="-m-2 flex" aria-label="Breadcrumb">
      <HStack align="center" asChild gap={0}>
        <ol>
          <li>
            <div>
              <LinkButton
                icon={{ name: 'Home' }}
                text="Admin Home"
                to="/admin"
                type="ghost"
              />
            </div>
          </li>
          {tenantId && (
            <TenantBreadCrumbs
              tenantId={tenantId}
              appointmentId={appointmentId}
            />
          )}
          {isPrompts && <PromptBreadCrumbs />}
          {isFactVerification && <FactVerifactionBreadcrumbs />}
          {isOutputTemplates && <OutputTemplateBreadcrumbs />}
          {isUsers && !isPrompts && <UserBreadcrumbs />}
        </ol>
      </HStack>
    </nav>
  );
};
