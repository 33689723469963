import { useMutation } from '@apollo/client';
import React from 'react';

import {
  Box,
  Select,
  SelectTrigger,
  textStyles,
  toast,
} from '@eluve/components';
import {
  LlmOutputTypesLookup,
  isLlmOutputDynamicOutput,
} from '@eluve/graphql-types';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import { OutputTemplateSelectContent } from './OutputTemplateSelectContent';
import { PromptTemplateSelectContent } from './PromptTemplateSelectContent';
import { upsertPromptTemplateIdMutation } from './operations';
import { usePromptTemplates } from './usePromptTemplates';

export const PromptTemplateSettings: React.FC<{ className?: string }> = ({
  className,
}) => {
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();

  const {
    activeSOAPPromptTemplates,
    defaultPromptTemplateId,
    userSettingsPromptTemplateId,
    activeOutputTemplates,
    userSettingsOutputTemplateId,
  } = usePromptTemplates();

  const [upsertPromptTemplateId] = useMutation(upsertPromptTemplateIdMutation, {
    onError: () => toast.error('Failed to update prompt template'),
    optimisticResponse: (data) => ({
      updateTenantUserSettingsByPk: {
        __typename: 'TenantUserSettings' as const,
        tenantId,
        userId,
        promptTemplateId: data.promptTemplateId,
        llmOutputTemplateId: data.llmOutputTemplateId ?? null,
      },
    }),
  });

  const performUpsertPromptTemplateId = async (
    promptTemplateId: string,
    outputTemplateId: string | null,
  ) => {
    await upsertPromptTemplateId({
      variables: {
        promptTemplateId,
        llmOutputTemplateId: outputTemplateId,
        tenantId,
        userId,
      },
    });
  };

  const selectedPromptTemplateId =
    userSettingsPromptTemplateId ?? defaultPromptTemplateId;

  const selectedPromptTemplate =
    activeSOAPPromptTemplates?.find(
      (pT) => pT.id === selectedPromptTemplateId,
    ) ??
    activeSOAPPromptTemplates.find((t) => t.isCurrentDefault) ??
    activeSOAPPromptTemplates[0];

  const selectedOutputTemplate =
    activeOutputTemplates?.find(
      (oT) => oT.id === userSettingsOutputTemplateId,
    ) ?? activeOutputTemplates[0];

  const selectedOutputTemplateId = selectedOutputTemplate?.id;

  const handlePromptTemplateSelected = (promptTemplateId: string) => {
    const newTemplateOutputType = activeSOAPPromptTemplates.find(
      (template) => template.id === promptTemplateId,
    )?.outputType;

    if (
      selectedPromptTemplate?.outputType ===
        LlmOutputTypesLookup.DYNAMIC_OUTPUT &&
      newTemplateOutputType === LlmOutputTypesLookup.SOAP_NOTE
    ) {
      performUpsertPromptTemplateId(promptTemplateId, null);
    } else {
      performUpsertPromptTemplateId(
        promptTemplateId,
        selectedOutputTemplateId ?? null,
      );
    }
  };

  return (
    <>
      <Box className={className}>
        <Select
          value={selectedPromptTemplate?.name ?? ''}
          onValueChange={handlePromptTemplateSelected}
        >
          <SelectTrigger className="text-gray-12">
            {selectedPromptTemplate?.name}
          </SelectTrigger>

          <PromptTemplateSelectContent />
        </Select>
      </Box>
      {isLlmOutputDynamicOutput(selectedPromptTemplate?.outputType) &&
        activeOutputTemplates.length > 1 && (
          <Box className={className}>
            <h4 className={textStyles.body({ size: 's', weight: 'semibold' })}>
              Select an output template
            </h4>

            <Select
              value={selectedOutputTemplate?.name ?? ''}
              onValueChange={(outputTemplateId) => {
                performUpsertPromptTemplateId(
                  selectedPromptTemplateId!,
                  outputTemplateId,
                );
              }}
            >
              <SelectTrigger className="text-gray-12">
                {selectedOutputTemplate?.name}
              </SelectTrigger>

              <OutputTemplateSelectContent />
            </Select>
          </Box>
        )}
    </>
  );
};
