import { useMutation, useSuspenseQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { CircleAlert } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  QUERY_ROOT_ID,
  cacheUtils,
  useCompleteFragment,
} from '@eluve/apollo-client';
import { CopyTextButton } from '@eluve/blocks';
import {
  Box,
  ColDefBuilder,
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  ComboboxSelectCheck,
  DataTable,
  Dialog,
  DialogContent,
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  H2,
  HStack,
  Input,
  NewButton,
  P,
  PageTitle,
  VStack,
  toast,
} from '@eluve/components';
import { userFragment } from '@eluve/frontend-feature-location-hierarchy';
import { graphql } from '@eluve/graphql.tada';
import {
  useUserDisplayFromSession,
  useUserIdFromSession,
} from '@eluve/session-helpers';
import { tenantNameFragment } from '@eluve/smart-blocks';
import { formatHumanName } from '@eluve/utils';

const apiTokenFragment = graphql(
  `
    fragment ApiToken on ApiTokens @_unmask {
      id
      __typename
      name
      description
      createdAt
      revokedAt
      tenantId
      revoker {
        ...User
      }
      creator {
        ...User
      }
    }
  `,
  [userFragment, tenantNameFragment],
);

const apiTokensFragment = graphql(
  `
    fragment ApiTokens on query_root @_unmask {
      apiTokens {
        ...ApiToken
      }
    }
  `,
  [apiTokenFragment],
);

const tenantNamesFragment = graphql(
  `
    fragment TenantNames on query_root @_unmask {
      tenants {
        ...TenantName
      }
    }
  `,
  [tenantNameFragment],
);

const getApiTokensQuery = graphql(
  `
    query getApiTokensQuery {
      ...TenantNames
      ...ApiTokens
    }
  `,
  [tenantNamesFragment, apiTokensFragment],
);

const revokeApiTokenMutation = graphql(
  `
    mutation revokeApiToken($id: uuid!, $tenantId: uuid!) {
      updateApiTokensByPk(
        _set: { revokedAt: NOW }
        pkColumns: { id: $id, tenantId: $tenantId }
      ) {
        __typename
        id
        revokedAt
        revoker {
          ...User
        }
      }
    }
  `,
  [userFragment],
);

const createNewApiTokenMutation = graphql(
  `
    mutation createNewApiToken(
      $tenantId: uuid!
      $name: String!
      $description: String
      $token: String!
    ) {
      insertApiTokensOne(
        object: {
          tenantId: $tenantId
          description: $description
          hashedToken: $token
          name: $name
        }
      ) {
        ...ApiToken
      }
    }
  `,
  [apiTokenFragment],
);

const generateRandomToken = (length = 64) => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  const token = Array.from(array, (byte) => byte.toString(36).padStart(2, '0'))
    .join('')
    .slice(0, length);

  const friendlyName = `elu_${token.slice(0, 6)}...${token.slice(-4)}`;

  return { token: `elu_${token}`, friendlyName };
};

type TableRow = {
  id: string;
  tenantId: string;
  tenantName: string;
  apiTokenDescription: string | null;
  apiTokenName: string;
  createdBy: string;
  createdAt: string;
  revokedAt: string | null;
  revokedBy: string | null;
};

const RevokeApiTokenAction: React.FC<{ apiTokenId: string }> = ({
  apiTokenId,
}) => {
  const userDetails = useUserDisplayFromSession();
  const userId = useUserIdFromSession();

  const currentToken = useCompleteFragment({
    fragment: apiTokenFragment,
    key: { id: apiTokenId },
  });

  const [revokeApiToken] = useMutation(revokeApiTokenMutation, {
    optimisticResponse: (data) => ({
      updateApiTokensByPk: {
        id: data.id,
        __typename: 'ApiTokens' as const,
        revokedAt: new Date().toISOString(),
        revoker: {
          id: userId,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
          __typename: 'Users' as const,
        },
      },
    }),
    onError: () => {
      toast.error('Failed to revoke API token');
    },
  });

  const revoke = async () => {
    await revokeApiToken({
      variables: {
        tenantId: currentToken?.tenantId,
        id: apiTokenId,
      },
    });
  };

  return (
    <NewButton
      text={currentToken.revokedAt ? 'Revoked' : 'Revoke'}
      type="outline"
      disabled={Boolean(currentToken.revokedAt)}
      onClick={revoke}
    />
  );
};

const colDefs = new ColDefBuilder<TableRow>()
  .defaultSortable('tenantName', 'Tenant')
  .defaultSortable('tenantId', 'Tenant ID')
  .defaultSortable('apiTokenDescription', 'Description')
  .defaultSortable('apiTokenName', 'Name')
  .defaultSortable('createdBy', 'Created By')
  .dateSortable('createdAt', 'Created At')
  .dateSortable('revokedAt', 'Revoked At')
  .defaultSortable('revokedBy', 'Revoked By')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => <RevokeApiTokenAction apiTokenId={row.original.id} />,
  })
  .build();

const newApiTokenSchema = z.object({
  tenantId: z.string(),
  description: z.string().optional(),
});
type NewApiTokenForm = z.infer<typeof newApiTokenSchema>;

const TokenCreationForm: React.FC<{ setNewToken: (v: string) => void }> = ({
  setNewToken,
}) => {
  const newApiTokenForm = useForm<NewApiTokenForm>({
    resolver: zodResolver(newApiTokenSchema),
  });
  const { tenants } = useCompleteFragment({
    fragment: tenantNamesFragment,
    key: { id: QUERY_ROOT_ID },
  });
  const userId = useUserIdFromSession();
  const userDetails = useUserDisplayFromSession();

  const [createNewApiToken] = useMutation(createNewApiTokenMutation, {
    optimisticResponse: (data) => ({
      insertApiTokensOne: {
        id: 'optimistic-id',
        __typename: 'ApiTokens' as const,
        creator: {
          __typename: 'Users' as const,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
          id: userId,
        },
        revoker: null,
        revokedAt: null,
        description: data.description ?? null,
        name: data.name,
        createdAt: new Date().toISOString(),
        tenantId: data.tenantId,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: apiTokensFragment,
          key: { id: QUERY_ROOT_ID },
        },
        (existing) => {
          if (!existing || !data?.insertApiTokensOne) {
            return existing;
          }
          return {
            apiTokens: [...existing.apiTokens, data.insertApiTokensOne],
          };
        },
      );
    },
    onError: () => {
      toast.error('Failed to create API token');
    },
  });

  const onSubmitNewApiToken = async (data: NewApiTokenForm) => {
    const { token, friendlyName } = generateRandomToken();
    try {
      await createNewApiToken({
        variables: {
          name: friendlyName,
          token,
          ...data,
        },
      });
      setNewToken(token);
    } catch (e) {
      toast.error(`Failed creating API token: ${(e as Error)?.message}`);
    } finally {
      newApiTokenForm.reset({});
    }
  };

  return (
    <Form {...newApiTokenForm}>
      <form onSubmit={newApiTokenForm.handleSubmit(onSubmitNewApiToken)}>
        <VStack gap={3} wFull align="stretch">
          <FormField
            control={newApiTokenForm.control}
            name="tenantId"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormLabel>Tenant</FormLabel>
                <Combobox>
                  <ComboboxSelectButton className="w-full border-transparent hover:bg-gray-3">
                    <HStack>
                      {tenants.find((tenant) => tenant.id === field.value)
                        ?.name ?? 'Select Tenant'}
                    </HStack>
                  </ComboboxSelectButton>
                  <ComboboxDropdown searchPlaceholder="Select Tenant">
                    {tenants.map((tenant) => (
                      <ComboboxOption
                        key={tenant.id}
                        onSelect={() => field.onChange(tenant.id)}
                      >
                        <ComboboxSelectCheck
                          className="mr-2"
                          selected={field.value === tenant.id}
                        />
                        {tenant.name}
                      </ComboboxOption>
                    ))}
                  </ComboboxDropdown>
                </Combobox>
                <FormDescription>
                  A tenant for which this token will be created.
                </FormDescription>
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={newApiTokenForm.control}
            name="description"
            render={({ field, fieldState: { error } }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <Input
                  className="bg-white/5"
                  placeholder="Enter Description"
                  {...field}
                />

                <FormDescription>
                  A user friendly description for this token.
                </FormDescription>
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
          <Box>
            <NewButton
              submit
              wFull
              size="l"
              disabled={!newApiTokenForm.formState.isDirty}
              text="Submit"
            />
          </Box>
        </VStack>
      </form>
    </Form>
  );
};

const CreateNewApiToken: React.FC = () => {
  const [newToken, setNewToken] = useState('');

  const [newApiTokenDialogOpen, setNewApiTokenDialogOpen] = useState(false);

  return (
    <Box>
      <NewButton
        text="Create New Token"
        onClick={() => setNewApiTokenDialogOpen(true)}
      />

      <Dialog
        open={newApiTokenDialogOpen}
        onOpenChange={setNewApiTokenDialogOpen}
      >
        <DialogContent>
          {newToken ? (
            <VStack wFull align="stretch">
              <H2>Token Created!</H2>
              <HStack>
                <CircleAlert size={20} color="red" />
                <P>Copy your API token now. You won’t see it again.</P>
              </HStack>
              <HStack>
                <Input disabled value={newToken} />
                <CopyTextButton copyText={newToken} />
              </HStack>
              <NewButton
                wFull
                size="l"
                text="Got it!"
                onClick={() => {
                  setNewApiTokenDialogOpen(false);
                  setNewToken('');
                }}
              />
            </VStack>
          ) : (
            <VStack wFull align="stretch">
              <H2>Create New API Token</H2>
              <TokenCreationForm setNewToken={setNewToken} />
            </VStack>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export const ApiTokensPage: React.FC = () => {
  useSuspenseQuery(getApiTokensQuery);

  const { apiTokens } = useCompleteFragment({
    fragment: apiTokensFragment,
    key: { id: QUERY_ROOT_ID },
  });

  const { tenants } = useCompleteFragment({
    fragment: tenantNamesFragment,
    key: { id: QUERY_ROOT_ID },
  });

  const rows = apiTokens.map<TableRow>((token) => ({
    id: token.id,
    tenantId: token.tenantId,
    tenantName: tenants.find((t) => t.id === token.tenantId)?.name ?? '',
    apiTokenDescription: token.description,
    apiTokenName: token.name,
    createdBy: formatHumanName(token.creator.firstName, token.creator.lastName),
    createdAt: token.createdAt,
    revokedBy: formatHumanName(
      token.revoker?.firstName,
      token.revoker?.lastName,
    ),
    revokedAt: token.revokedAt,
  }));

  return (
    <VStack>
      <HStack justify="between">
        <PageTitle>API Tokens</PageTitle>
        <CreateNewApiToken />
      </HStack>

      <DataTable
        data={rows}
        columns={colDefs}
        isPaginated={false}
        enableGlobalSearch
        isCompact
      />
    </VStack>
  );
};
