import React from 'react';

import {
  Divider,
  HStack,
  NewButton,
  P,
  Tag,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  VStack,
  textStyles,
  tv,
} from '@eluve/components';
import { formatToLocale } from '@eluve/date-utils';

const styles = tv({
  slots: {
    container:
      'group cursor-pointer rounded-md border border-brandGray200 bg-white p-2 transition-colors hover:border-brandGray300 hover:bg-brandGray50',
    divider:
      'border-brandGray200 transition-colors group-hover:border-brandGray300',
    details: textStyles.body({
      color: 'secondary',
      size: 'm',
      weight: 'normal',
    }),
    notes: textStyles.body({ size: 's' }),
  },
  variants: {
    selected: {
      true: {
        container:
          'cursor-default border-2 border-brandGray300 bg-brandGray50 hover:border-brandGray400 hover:bg-brandGray100',
        divider: 'border-brandGray400 group-hover:border-brandGray400',
        title: 'text-contentPrimary',
        details: '',
      },
    },
  },
});

export type TemplateVariant = {
  variantNumber: number;
  isCurrentDefault: boolean;
  variantNotes: string | null;
  createdAt: string;
  createdBy?: string;
};

type TemplateVariantCardProps = {
  isSelected: boolean;
  onClick: () => void;
  onDuplicateClick?: () => void;
  onSetAsDefaultClick?: () => void;
  variant: TemplateVariant;
};

export const TemplateVariantCard: React.FC<TemplateVariantCardProps> = ({
  isSelected,
  onClick,
  onDuplicateClick,
  onSetAsDefaultClick,
  variant,
}) => {
  const { container, details, divider, notes } = styles({
    selected: isSelected,
  });

  const handleDuplicateClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onDuplicateClick) {
      onDuplicateClick();
    }
  };

  const handleSetAsDefaultClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onSetAsDefaultClick) {
      onSetAsDefaultClick();
    }
  };

  const hasActions = onDuplicateClick || onSetAsDefaultClick;

  return (
    <VStack className={container()} gap={1} onClick={onClick}>
      <HStack justify="between">
        <HStack gap={3}>
          <Tag label={`Variant ${variant.variantNumber}`} />
          {variant.isCurrentDefault && (
            <Tag label="Default" color="green" size="s" />
          )}
        </HStack>
        {hasActions && (
          <HStack align="end" gap={1} inline wFull={false}>
            {onSetAsDefaultClick && !variant.isCurrentDefault && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <NewButton
                      icon={{ name: 'Gem' }}
                      onClick={handleSetAsDefaultClick}
                      type="ghost"
                    />
                  </TooltipTrigger>
                  <TooltipContent sideOffset={4}>Set as default</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
            {onDuplicateClick && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <NewButton
                      icon={{ name: 'CopyPlus' }}
                      onClick={handleDuplicateClick}
                      type="ghost"
                    />
                  </TooltipTrigger>
                  <TooltipContent sideOffset={4}>
                    Use this to create a new variant
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </HStack>
        )}
      </HStack>
      <VStack gap={0}>
        <P className={details()}>
          {formatToLocale(variant.createdAt, { separator: '-' })}
        </P>
        {variant.createdBy && (
          <P className={details()}>{`By ${variant.createdBy}`}</P>
        )}
      </VStack>
      {variant.variantNotes && (
        <>
          <Divider className={divider()} />
          <P className={notes()}>{variant.variantNotes}</P>
        </>
      )}
    </VStack>
  );
};
