import { skipToken, useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useApiClient } from '@eluve/api-client-provider';
import {
  CreatePromptTemplate,
  SubmittablePromptSchema,
} from '@eluve/api-contract';
import { PageTitle, toast } from '@eluve/components';

import { CreatePromptTemplateForm } from './CreatePromptTemplateForm';
import { getExistingPromptTemplateQuery } from './prompt.operations';

type CreatePromptTemplatePageProps = {
  onDoneNavigateTo: string;
};

export const CreatePromptTemplatePage: React.FC<
  CreatePromptTemplatePageProps
> = ({ onDoneNavigateTo }) => {
  const client = useApiClient();
  const navigate = useNavigate();

  const { promptTemplateId } = useParams() as { promptTemplateId: string };

  const isCloning = promptTemplateId != null;

  const { data } = useSuspenseQuery(
    getExistingPromptTemplateQuery,
    isCloning ? { variables: { id: promptTemplateId } } : skipToken,
  );

  let initialFormValues;
  let subtitle;

  if (isCloning) {
    const promptTemplate = data?.promptTemplatesByPk;
    const variants = promptTemplate?.prompt_template_variants ?? [];
    const currentDefaultVariant = variants.find(
      (variant) => variant.isCurrentDefault,
    );
    const template = SubmittablePromptSchema.parse(
      currentDefaultVariant?.template,
    );

    initialFormValues = {
      name: `${promptTemplate?.name} (Copy)`,
      description: promptTemplate?.description ?? undefined,
      template,
      outputType: promptTemplate?.outputType,
    };
    subtitle = `Cloning from '${promptTemplate?.name}'`;
  }

  const onSubmit = async (data: CreatePromptTemplate) => {
    try {
      const response = await client.prompts.createPromptTemplate({
        body: data,
      });

      if (response.status === 201) {
        navigate(onDoneNavigateTo);
      } else if (response.status === 403) {
        toast.error('You do not have permission to create prompt templates');
      } else {
        toast.error('Failed to create new prompt template');
      }
    } catch (e) {
      toast.error('Failed to create new prompt template');
    }
  };

  return (
    <>
      <PageTitle subtitle={subtitle}>Create Prompt Template</PageTitle>
      <CreatePromptTemplateForm
        initialFormValues={initialFormValues}
        onCancel={() => navigate(onDoneNavigateTo)}
        onSubmit={onSubmit}
      />
    </>
  );
};
