import { zodResolver } from '@hookform/resolvers/zod';
import { useSelector } from '@xstate/react';
import isNil from 'lodash/isNil';
import { CircleHelp } from 'lucide-react';
import React from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useMount } from 'react-use';
import { z } from 'zod';

import {
  Box,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  HStack,
  Icon,
  Input,
  P,
  Switch,
  Textarea,
} from '@eluve/components';
import { BlockType } from '@eluve/llm-outputs';

import { TooltipLabel } from '../TooltipLabel';

import { templateBuilderStore } from './templateBuilderStore';
import { BuilderBlock } from './utilities';

const blockDetailsFormSchema = z.object({
  label: z.string().min(3),
  description: z.string().optional(),
  isRequired: z.boolean(),
  isAiEnabled: z.boolean(),
  isEmailEnabled: z.boolean().optional(),
  checkboxOptions: z
    .array(z.string())
    .optional()
    .refine(
      (options) =>
        isNil(options)
          ? true
          : options.length > 0 && options.every((opt) => opt.trim() !== ''),
      {
        message: 'At least one non-empty option is required',
      },
    ),
});

type BlockDetailsFormData = z.infer<typeof blockDetailsFormSchema>;

export interface BlockDetailsFormProps {
  block: BuilderBlock;
}

const BaseBlockForm: React.FC<{
  form: UseFormReturn<BlockDetailsFormData>;
  isReadonly: boolean;
  isImported?: boolean;
  type: BlockType;
}> = ({ form, isReadonly, isImported, type }) => {
  const isAiEnabled = form.watch('isAiEnabled');

  return (
    <>
      <FormField
        control={form.control}
        name="label"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Label</FormLabel>
            <FormControl>
              <Input
                placeholder="Label"
                {...field}
                disabled={isReadonly || isImported}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {type === 'text' && (
        <FormField
          control={form.control}
          name="isEmailEnabled"
          render={({ field }) => (
            <HStack asChild wFull={false}>
              <FormItem>
                <TooltipLabel label="If enabled, users will be given controls to send the contents of this block by email.">
                  <div>
                    <Icon size="md" className="mt-2" name="Info" />
                  </div>
                </TooltipLabel>
                <FormLabel className="mt-2 whitespace-nowrap">
                  Email Enabled
                </FormLabel>
                <Switch
                  disabled={isReadonly}
                  onCheckedChange={(checked) => field.onChange(checked)}
                  checked={field.value}
                />
              </FormItem>
            </HStack>
          )}
        />
      )}

      <FormField
        control={form.control}
        name="isAiEnabled"
        render={({ field }) => (
          <HStack asChild wFull={false}>
            <FormItem>
              <TooltipLabel label="If enabled, we will attempt to use AI to fill out this block.">
                <div>
                  <Icon size="md" className="mt-2" name="Info" />
                </div>
              </TooltipLabel>
              <FormLabel className="mt-2 whitespace-nowrap">
                AI Enabled
              </FormLabel>
              <Switch
                disabled={isReadonly}
                onCheckedChange={(checked) => field.onChange(checked)}
                checked={field.value}
              />
            </FormItem>
          </HStack>
        )}
      />
      {isAiEnabled && (
        <>
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Description"
                    {...field}
                    disabled={isReadonly}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isRequired"
            render={({ field }) => (
              <HStack asChild wFull={false}>
                <FormItem>
                  <TooltipLabel label="If required is set to true, generation will be considered 'failed' if no value is produced by AI.">
                    <div>
                      <Icon size="md" className="mt-2" name="Info" />
                    </div>
                  </TooltipLabel>
                  <FormLabel className="mt-2 whitespace-nowrap">
                    Required
                  </FormLabel>
                  <Switch
                    disabled={isReadonly || isImported}
                    onCheckedChange={(checked) => field.onChange(checked)}
                    checked={field.value}
                  />
                </FormItem>
              </HStack>
            )}
          />
        </>
      )}
    </>
  );
};

const CheckboxBlockForm: React.FC<{
  form: UseFormReturn<any>;
  isReadonly: boolean;
}> = ({ form, isReadonly }) => (
  <FormField
    control={form.control}
    name="checkboxOptions"
    render={({ field }) => (
      <FormItem>
        <FormLabel>Checkbox Options</FormLabel>
        <FormControl>
          <Box vStack className="gap-2">
            {field.value &&
              field.value.map((option: string, index: number) => (
                <HStack key={index} className="w-full">
                  <Input
                    placeholder={`Option ${index + 1}`}
                    value={option}
                    onChange={(e) => {
                      const updatedOptions = [...(field.value ?? [])];
                      updatedOptions[index] = e.target.value;
                      field.onChange(updatedOptions);
                    }}
                    disabled={isReadonly}
                  />
                  {!isReadonly && (
                    <Button
                      variant="destructive"
                      size="icon"
                      disabled={
                        (field.value && field.value.length <= 1) || isReadonly
                      }
                      onClick={() => {
                        const updatedOptions = [...(field.value ?? [])];
                        updatedOptions.splice(index, 1);
                        field.onChange(updatedOptions);
                      }}
                    >
                      <Icon name="Trash" size="sm" />
                    </Button>
                  )}
                </HStack>
              ))}
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                const updatedOptions = [...(field.value ?? []), ''];
                field.onChange(updatedOptions);
              }}
              disabled={isReadonly}
            >
              Add Option
            </Button>
          </Box>
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
);

export const BlockDetailsForm: React.FC<BlockDetailsFormProps> = ({
  block,
}) => {
  const isReadonly = useSelector(
    templateBuilderStore,
    (store) => store.context.isReadOnly,
  );
  const isImported = useSelector(
    templateBuilderStore,
    (store) => store.context.isImported,
  );

  const form = useForm<BlockDetailsFormData>({
    resolver: zodResolver(blockDetailsFormSchema),
    mode: 'all',
    values: {
      label: block.label,
      description: block.description,
      isRequired: block.isRequired ?? false,
      isAiEnabled: block.isAiEnabled ?? true,
      isEmailEnabled: block.isEmailEnabled ?? false,
      checkboxOptions:
        block.type === 'checkbox'
          ? block.options.map((o) => o.label)
          : undefined,
    },
  });

  const {
    watch,
    formState: { isValid },
  } = form;

  useMount(() => {
    form.trigger();
  });

  const formData = watch();

  const onSubmit = (data: BlockDetailsFormData) => {
    if (!isReadonly) {
      templateBuilderStore.send({
        type: 'setBlockDetails',
        id: block.id,
        label: data.label,
        description: data.description,
        isRequired: data.isRequired,
        checkboxOptions: data.checkboxOptions,
        isAiEnabled: data.isAiEnabled,
        isEmailEnabled: data.isEmailEnabled,
      });
    }
  };

  return (
    <Box vStack className="w-full gap-1">
      <Form {...form}>
        <form
          className="w-full space-y-3"
          onBlur={() => {
            if (isValid) {
              onSubmit(formData);
            }
          }}
        >
          <BaseBlockForm
            form={form}
            isReadonly={isReadonly}
            isImported={isImported}
            type={block.type}
          />
          {block.type === 'checkbox' && (
            <CheckboxBlockForm
              form={form}
              isReadonly={isReadonly || Boolean(isImported)}
            />
          )}
          {block.importedDescription && (
            <HStack>
              <P>Imported Description</P>
              <TooltipLabel label="This description was imported from the EHR. The imported description will be used as a hint to AI when generating a note. Override it by providing your own description above to be used for AI.">
                <CircleHelp size={16} />
              </TooltipLabel>
              <Textarea disabled={true} value={block.importedDescription} />
            </HStack>
          )}
        </form>
      </Form>
    </Box>
  );
};
