import { CloudUpload, Download, Trash } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  P,
  toast,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';
import { usePrevious } from '@eluve/utility-hooks';

import {
  useAppointmentTasksActor,
  useAppointmentTasksSelector,
} from './AppointmentTasksMachineProvider';
import { getSupportedAudioFormat } from './getSupportedAudioFormat';
import { FileRow, SWAP_FILE_EXT } from './opfs';

export interface FilesActionCellProps {
  appointmentId: string;
  appointmentDir: FileSystemDirectoryHandle;
  files: FileRow[];
  onLocalFilesUpdated: (id: string) => Promise<void>;
}

export const FilesActionCell: React.FC<FilesActionCellProps> = ({
  appointmentId,
  files,
  appointmentDir,
  onLocalFilesUpdated,
}) => {
  const logger = useNamedLogger('appointment-files');

  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { send } = useAppointmentTasksActor();

  const isUploadInProgress = useAppointmentTasksSelector(
    (s) =>
      s.matches({ InProgress: 'UploadFile' }) &&
      s.context.activeTask?.appointmentId === appointmentId,
  );

  const isSessionInProgress = files.some((f) => f.name.endsWith(SWAP_FILE_EXT));

  const wasPreviouslyUploading = usePrevious(isUploadInProgress);

  useEffect(() => {
    if (wasPreviouslyUploading && !isUploadInProgress) {
      onLocalFilesUpdated(appointmentId);
    }
  }, [
    isUploadInProgress,
    wasPreviouslyUploading,
    appointmentId,
    onLocalFilesUpdated,
  ]);

  const handleDeleteFiles = async () => {
    try {
      logger.info('Deleting files for appointment', {
        appointmentId,
        numFiles: files.length,
      });
      setIsDeleting(true);
      const deletePromises = files.map(async ({ name }) =>
        appointmentDir.removeEntry(name),
      );

      await Promise.all(deletePromises);
      toast.success('Files deleted successfully');
      setIsConfirmDeleteDialogOpen(false);
      await onLocalFilesUpdated(appointmentId);
    } catch (e) {
      logger.error(`Failed to delete files.`, {
        appointmentId,
        errorMessage: (e as { message?: string })?.message,
      });
      toast.error('Failed to delete files');
    } finally {
      setIsDeleting(false);
    }
  };

  const attemptReuploadFiles = () => {
    logger.info('Reuploading files for appointment', {
      appointmentId,
      numFiles: files.length,
    });

    for (const file of files) {
      // Remove the file extension here to get the recording started at
      // in order to correlate the segment on the backend
      const recordingStartedAt = file.name.split('.').slice(0, -1).join('.');
      logger.info('Reuploading file for appointment', {
        appointmentId,
        numFiles: files.length,
        fileName: file.name,
        recordingStartedAt,
      });

      send({
        type: 'TASK.UPLOAD_FILE',
        appointmentId,
        format: getSupportedAudioFormat()!,
        fileName: file.name,
        shouldBeTranscribed: true,
        recordingStartedAt,
        isBackgroundUpload: false,
        attemptNumber: 1,

        // TODO(jesse)[ELU-1684]:
        // We don't really know if the session was degraded or not.
        // If we stored info in something like IndexedDB we could reference it here
        wasDegraded: false,
      });
    }
  };

  const downloadFiles = () => {
    logger.info('Downloading files for appointment', {
      appointmentId,
      numFiles: files.length,
    });
    for (const file of files) {
      const url = window.URL.createObjectURL(file.file);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = url;
      link.download = file.name;
      link.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Box hStack className="gap-1">
        <TooltipLabel label="Try and re-upload files">
          <Button
            size="smallIcon"
            variant="outline"
            onClick={attemptReuploadFiles}
            disabled={isUploadInProgress || isSessionInProgress}
          >
            <CloudUpload className="size-5" />
          </Button>
        </TooltipLabel>
        <FeatureFlaggedComponent flag="DOWNLOAD_LOCAL_FILES">
          <TooltipLabel label="Download file(s)">
            <Button size="smallIcon" variant="outline" onClick={downloadFiles}>
              <Download className="size-5" />
            </Button>
          </TooltipLabel>
        </FeatureFlaggedComponent>
        <TooltipLabel label="Delete files">
          <Button
            disabled={isSessionInProgress}
            size="smallIcon"
            variant="destructive"
            onClick={() => setIsConfirmDeleteDialogOpen(true)}
          >
            <Trash className="size-5" />
          </Button>
        </TooltipLabel>
      </Box>
      <Dialog
        open={isConfirmDeleteDialogOpen}
        onOpenChange={setIsConfirmDeleteDialogOpen}
      >
        <DialogContent>
          <H2>Are you sure?</H2>
          <P className="text-xs">
            If these files are removed but haven't been sycned to our backend,
            data may be lost.
          </P>
          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => setIsConfirmDeleteDialogOpen(false)}
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="sm"
              variant="destructive"
              onClick={handleDeleteFiles}
            >
              Yes, I'm sure.
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
