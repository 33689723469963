import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { DebugJson } from '@eluve/components';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';

import { getUserGroupDetailsQuery } from './operations';

export interface UserGroupDetailsPageProps {}

export const UserGroupDetailsPage: React.FC<UserGroupDetailsPageProps> = () => {
  const tenantId = useAssignedTenantIdFromParams();
  const { userGroupId } = useParams() as { userGroupId: string };

  const { data } = useSuspenseQuery(getUserGroupDetailsQuery, {
    variables: {
      tenantId,
      userGroupId,
    },
  });

  return (
    <div>
      <span>UserGroupDetailsPage</span>
      <DebugJson value={data} />
    </div>
  );
};
