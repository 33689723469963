import { useMutation } from '@apollo/client';
import React from 'react';

import { HStack, Switch, toast } from '@eluve/components';
import {
  useAssignedTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import { upsertEnableSoundEffectsMutation } from './operations';
import { useTenantUserSettings } from './useTenantUserSettings';

export const EnableSoundEffects: React.FC = () => {
  const { settings } = useTenantUserSettings();
  const tenantId = useAssignedTenantIdFromParams();
  const userId = useUserIdFromSession();

  const [upsertEnableSoundEffects] = useMutation(
    upsertEnableSoundEffectsMutation,
    {
      onError: () => {
        toast.error(
          'Failed to update sound effects settings. Please try again.',
        );
      },
      optimisticResponse: (data) => ({
        updateTenantUserSettingsByPk: {
          __typename: 'TenantUserSettings' as const,
          tenantId,
          userId,
          enableSoundEffects: data.enableSoundEffects,
        },
      }),
    },
  );

  const enableSoundEffects = async (checked: boolean) => {
    await upsertEnableSoundEffects({
      variables: {
        enableSoundEffects: checked,
        userId,
        tenantId,
      },
    });
  };

  return (
    <HStack gap={4}>
      <label>
        <Switch
          checked={settings?.enableSoundEffects ?? true}
          onCheckedChange={enableSoundEffects}
        />
      </label>
    </HStack>
  );
};
