import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  ClassicSummary,
  DynamicSummary,
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import { useIsEluveAdmin } from '@eluve/session-helpers';
import { AppointmentSummarySchema } from '@eluve/utils';

import { useAppointmentContext } from './appointment.context';
import {
  getCurrentSummary,
  getCurrentSummaryForEluveAdmin,
} from './operations';

type SummaryReturn = {
  summary: ClassicSummary | DynamicSummary;
  isSummaryAvailable: boolean;
  llmOutputId: string | null;
  humanOutputId: string | null;
  refetch: () => void;
};

/**
 * Returns the current summary for an appointment if it is available
 *
 */
export const useSummary = (): SummaryReturn => {
  const { tenantId, appointmentId } = useAppointmentContext();
  const isEluveAdmin = useIsEluveAdmin();
  const summaryQuery = useMemo(
    () => (isEluveAdmin ? getCurrentSummaryForEluveAdmin : getCurrentSummary),
    [isEluveAdmin],
  );

  const { data, refetch } = useQuery(summaryQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const currentSoapNote = data?.appointmentsByPk?.humanOutputs?.[0];
  const summary = currentSoapNote?.output?.content ?? null;
  const llmOutputMetadata = currentSoapNote?.output?.llm_output?.metadata;

  const shared: Omit<
    SummaryReturn,
    'summary' | 'isSummaryAvailable' | 'refetch'
  > = {
    humanOutputId: currentSoapNote?.humanOutputId ?? null,
    llmOutputId: currentSoapNote?.output?.editedFromLlmOutputId ?? null,
  };

  const outputTemplate = llmOutputMetadata?.output_variant?.template;

  if (outputTemplate) {
    const outputTemplateParsed =
      dynamicArtifactTemplateSchema.parse(outputTemplate);

    const hydrated = hydrateDynamicArtifactTemplate(
      outputTemplateParsed,
      summary ?? {},
    );

    return {
      ...shared,
      isSummaryAvailable: true,
      refetch,
      summary: {
        type: 'DYNAMIC',
        blocks: hydrated.blocks,
      },
    };
  }

  const parsed = AppointmentSummarySchema.safeParse(summary);

  // Ensure that object keys are always in a predictable order since
  // the UI binds to the entries of this
  return parsed.success
    ? {
        ...shared,
        isSummaryAvailable: true,
        refetch,
        summary: {
          type: 'SOAP' as const,
          data: {
            SUBJECTIVE: parsed.data.SUBJECTIVE,
            OBJECTIVE: parsed.data.OBJECTIVE,
            ASSESSMENT: parsed.data.ASSESSMENT,
            PLAN: parsed.data.PLAN,
            CLIENT_RECAP: parsed.data.CLIENT_RECAP,
          },
        },
      }
    : {
        ...shared,
        isSummaryAvailable: false,
        refetch,
        summary: {
          type: 'SOAP',
          data: null,
        },
      };
};
