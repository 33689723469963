import React from 'react';

import { AudioPlayer } from '@eluve/audio-player';
import { HStack, NewButton } from '@eluve/components';

import { useSegmentAudioContext } from './SegmentAudioContext';

export interface SegmentAudioControlsProps {
  audioUrl: string;
}

export const SegmentAudioControls: React.FC<SegmentAudioControlsProps> = ({
  audioUrl,
}) => {
  const { store, audioPlayerHandle } = useSegmentAudioContext();

  return (
    <HStack>
      <AudioPlayer
        audioUrl={audioUrl}
        ref={audioPlayerHandle}
        onTimeUpdate={(timestamp) => {
          store.send({ type: 'updateTimestamp', timestamp });
        }}
      />
      <NewButton
        text="10"
        icon={{
          name: 'Rewind',
        }}
        onClick={() => audioPlayerHandle.current?.rewind(10)}
      />
    </HStack>
  );
};
