import { graphql } from '@eluve/graphql.tada';

export const tenantUserGroupsFragment = graphql(`
  fragment TenantUserGroups on Tenants @_unmask {
    __typename
    id
    user_groups {
      __typename
      id
      name
    }
  }
`);

export const userFragment = graphql(`
  fragment User on Users @_unmask {
    __typename
    id
    email
    firstName
    lastName
  }
`);

export const tenantUserFragment = graphql(
  `
    fragment TenantUser on TenantUsers @_unmask {
      __typename
      tenantId
      userId
      user {
        ...User
      }
    }
  `,
  [userFragment],
);

export const tenantUsersFragment = graphql(
  `
    fragment TenantUsers on Tenants @_unmask {
      __typename
      tenantUsers {
        ...TenantUser
      }
    }
  `,
  [tenantUserFragment],
);

export const userGroupUsersFragment = graphql(
  `
    fragment UserGroupUsers on UserGroups @_unmask {
      __typename
      id
      users {
        __typename
        id
        tenant_user {
          ...TenantUser
        }
      }
    }
  `,
  [tenantUserFragment],
);

export const getTenantUserGroupsQuery = graphql(
  `
    query getUserGroups($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantUserGroups
        ...TenantUsers
      }
    }
  `,
  [tenantUserGroupsFragment, tenantUsersFragment],
);

export const createUserGroupMutation = graphql(`
  mutation createUserGroup($name: String!) {
    insertUserGroupsOne(object: { name: $name }) {
      __typename
      id
      name
    }
  }
`);

export const getUserGroupDetailsQuery = graphql(
  `
    query getUserGroupDetails($tenantId: uuid!, $userGroupId: uuid!) {
      userGroupsByPk(id: $userGroupId, tenantId: $tenantId) {
        __typename
        id
        ...UserGroupUsers
      }
    }
  `,
  [userGroupUsersFragment],
);

export const addUserToUserGroupMutation = graphql(`
  mutation addUserToUserGroup($groupId: uuid!, $userId: uuid!) {
    insertUserGroupUsersOne(object: { groupId: $groupId, userId: $userId }) {
      __typename
      id
      tenantId
      groupId
      userId
    }
  }
`);

export const removeUserFromGroupMutation = graphql(`
  mutation removeUserFromGroup($tenantId: uuid!, $id: uuid!) {
    deleteUserGroupUsersByPk(tenantId: $tenantId, id: $id) {
      __typename
      id
    }
  }
`);
