import { QUERY_ROOT_ID, useCompleteFragment } from '@eluve/apollo-client';

import { reviewerLowQualityFactorsFragment } from './operations';

export const useLowQualityFactors = () => {
  const { lowQualityFactors } = useCompleteFragment({
    fragment: reviewerLowQualityFactorsFragment,
    key: QUERY_ROOT_ID,
  });

  return lowQualityFactors;
};
