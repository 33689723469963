import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditIcon, UserCogIcon } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Divider,
  FormField,
  Input,
  Label,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

const userFormSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid user email'),
});

const updateUserMutation = graphql(`
  mutation updateUser($userId: uuid!, $data: UsersSetInput!) {
    updateUsersByPk(pkColumns: { id: $userId }, _set: $data) {
      id
      firstName
      lastName
      email
    }
  }
`);

type UserFormValues = z.infer<typeof userFormSchema>;

interface UpdateUserActionProps {
  userId: string;
  defaultValues: UserFormValues;
}

export const UpdateUserAction: React.FC<UpdateUserActionProps> = ({
  userId,
  defaultValues,
}) => {
  const { control, formState, handleSubmit, reset } = useForm<UserFormValues>({
    resolver: zodResolver(userFormSchema),
    defaultValues,
  });

  const [updateUser] = useMutation(updateUserMutation, {
    onCompleted: ({ updateUsersByPk }) => {
      if (!updateUsersByPk) {
        return;
      }

      toast.success('User updated');
      reset(updateUsersByPk);
      setOpen(false);
    },
    onError: () => {
      toast.error('Failed to update user');
    },
  });

  const [open, setOpen] = useState(false);

  const handleUpdateUser = handleSubmit((data) => {
    return updateUser({
      variables: {
        userId,
        data,
      },
    });
  });

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <TooltipLabel label="Update user">
        <DialogTrigger asChild>
          <Button variant="outline" size="icon">
            <EditIcon />
          </Button>
        </DialogTrigger>
      </TooltipLabel>
      <DialogContent>
        <div className="flex gap-3">
          <UserCogIcon className="size-6" />
          <DialogHeader>
            <DialogTitle className="font-normal tracking-normal text-gray-11">
              Update user
            </DialogTitle>
          </DialogHeader>
        </div>

        <Divider />

        <Label>
          <Box vStack>
            First Name
            <FormField
              control={control}
              name="firstName"
              render={({ field }) => (
                <Input placeholder="First Name" {...field} />
              )}
            />
          </Box>
        </Label>

        <Label>
          <Box vStack>
            Last Name
            <FormField
              control={control}
              name="lastName"
              render={({ field }) => (
                <Input placeholder="Last Name" {...field} />
              )}
            />
          </Box>
        </Label>

        <Label>
          <Box vStack>
            Email
            <FormField
              control={control}
              name="email"
              render={({ field }) => <Input placeholder="Email" {...field} />}
            />
          </Box>
        </Label>

        <DialogFooter>
          <Button type="button" variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleUpdateUser}
            type="button"
            disabled={
              formState.isSubmitting || !formState.isValid || !formState.isDirty
            }
          >
            Update
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
