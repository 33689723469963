import { useMutation, useSuspenseQuery } from '@apollo/client';
import { produce } from 'immer';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CreatePromptTemplate,
  SubmittablePromptSchema,
} from '@eluve/api-contract';
import { cacheUtils } from '@eluve/apollo-client';
import { PageTitle, toast } from '@eluve/components';

import { CreatePromptTemplateForm } from './CreatePromptTemplateForm';
import {
  createPromptTemplateVariantMutation,
  getExistingPromptTemplateQuery,
  promptTemplateVariantsFragment,
} from './prompt.operations';

export const CreatePromptTemplateVariantPage: React.FC = () => {
  const navigate = useNavigate();

  const { promptTemplateId, variantNumber } = useParams() as {
    promptTemplateId: string;
    variantNumber: string;
  };

  const isDuplicating = variantNumber != null;

  const { data } = useSuspenseQuery(getExistingPromptTemplateQuery, {
    variables: { id: promptTemplateId },
  });

  let initialFormValues;
  let subtitle;

  if (isDuplicating) {
    const promptTemplate = data?.promptTemplatesByPk;
    const variants = promptTemplate?.prompt_template_variants ?? [];
    const variant = variants.find(
      (variant) => variant.variantNumber === Number(variantNumber),
    );
    const template = SubmittablePromptSchema.parse(variant?.template);

    initialFormValues = {
      name: promptTemplate?.name,
      description: promptTemplate?.description ?? undefined,
      template,
      outputType: promptTemplate?.outputType,
    };
    subtitle = `Using Variant ${variantNumber} of '${promptTemplate?.name}'`;
  }

  const navigateTo = `/admin/prompts/${promptTemplateId}/prompt`;

  const [createPromptTemplateVariant] = useMutation(
    createPromptTemplateVariantMutation,
  );

  const onSubmit = async (formData: CreatePromptTemplate) => {
    createPromptTemplateVariant({
      variables: {
        promptTemplateId,
        template: formData.template,
        variantNotes: formData.variantNotes,
      },
      onCompleted: (data) => {
        const newVariantNumber =
          data?.insertPromptTemplateVariantsOne?.variantNumber;
        navigate(
          newVariantNumber
            ? `${navigateTo}/variant/${newVariantNumber}`
            : navigateTo,
        );
      },
      onError: () => {
        toast.error('Failed to create new prompt template variant');
      },
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: promptTemplateVariantsFragment,
            key: {
              id: promptTemplateId,
            },
          },
          (existing) => {
            const newVariant = data?.insertPromptTemplateVariantsOne;
            if (!existing || !newVariant) {
              return existing;
            }

            return produce(existing, (draft) => {
              draft.prompt_template_variants.push(newVariant);
            });
          },
        );
      },
    });
  };

  return (
    <>
      <PageTitle subtitle={subtitle}>Create Prompt Template Variant</PageTitle>
      <CreatePromptTemplateForm
        initialFormValues={initialFormValues}
        onCancel={() => navigate(navigateTo)}
        onSubmit={onSubmit}
        shouldShowMetadata={false}
      />
    </>
  );
};
