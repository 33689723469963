import { tv } from '@eluve/components';

export const transcriptContainerVariants = tv({
  base: 'max-h-[50vh] w-full overflow-y-auto',
  variants: {
    compact: {
      true: 'max-h-[25vh]',
    },
  },
});
