import dayjs from 'dayjs';

export const formatToLocale = (
  timestamp: string | undefined | null,
  config?: {
    noTime?: boolean;
    separator?: string;
  },
): string => {
  const format = config?.noTime
    ? 'M/D/YYYY'
    : `M/D/YYYY ${config?.separator ? `${config.separator} ` : ''}HH:mm`;
  return timestamp ? dayjs(timestamp).format(format) : '';
};
