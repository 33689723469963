import { useMutation, useSuspenseQuery } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import { Layers } from 'lucide-react';
import React, { useState } from 'react';

import { cacheUtils } from '@eluve/apollo-client';
import { Button, FormItem, Input, toast } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { DeleteLayerAction } from './DeleteLayerAction';
import { DepthContainer } from './DepthContainer';
import { EditableLayerName } from './EditableLayerName';
import { TreeNodeContainer } from './TreeNodeContainer';

const layerDataFragment = graphql(`
  fragment LayerData on Layers @_unmask {
    __typename
    id
    name
    path
    depth
  }
`);

const tenantLayersFragment = graphql(
  `
    fragment TenantLayers on Tenants @_unmask {
      __typename
      layers {
        ...LayerData
      }
    }
  `,
  [layerDataFragment],
);

export const getTenantLayersQuery = graphql(
  `
    query getTenantLayers($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantLayers
      }
    }
  `,
  [tenantLayersFragment],
);

export const createLayerMutation = graphql(
  `
    mutation createLayer($input: LayersInsertInput!) {
      insertLayersOne(object: $input) {
        ...LayerData
      }
    }
  `,
  [layerDataFragment],
);

const OPTIMISTIC_ID = 'optimistic';

const CreateLayer: React.FC<{
  depth: number;
  parentPath: string;
  tenantId: string;
}> = ({ depth, parentPath, tenantId }) => {
  const [isCreatingLayer, setIsCreatingLayer] = useState(false);
  const [newLayerName, setNewLayerName] = useState('');
  const [createLayer] = useMutation(createLayerMutation, {
    optimisticResponse: ({ input }) => ({
      insertLayersOne: {
        __typename: 'Layers' as const,
        id: OPTIMISTIC_ID,
        name: input.name!,
        path: `${input.path!}.${OPTIMISTIC_ID}`,
        depth: depth + 1,
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: tenantLayersFragment,
          key: {
            id: tenantId,
          },
        },
        (existing) => {
          if (!existing || !data?.insertLayersOne) {
            return existing;
          }

          return {
            ...existing,
            layers: [...existing.layers, data.insertLayersOne],
          };
        },
      );
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async () => {
    if (!newLayerName) {
      return;
    }
    try {
      setIsSubmitting(true);
      await createLayer({
        variables: {
          input: {
            path: parentPath,
            name: newLayerName,
          },
        },
      });
      toast.success(`Layer '${newLayerName}' created`);
    } catch (e) {
      toast.error(`Failed to create layer '${newLayerName}'`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DepthContainer depth={depth}>
      {!isCreatingLayer && (
        <button
          type="button"
          className="border-gray-300 hover:border-gray-400 relative block w-full rounded-lg border-2 border-dashed px-4 py-2 text-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          onClick={() => setIsCreatingLayer(true)}
        >
          <Layers className="text-gray-400 mx-auto h-6 w-6" />
          <span className="text-gray-900 mt-2 block text-sm font-semibold">
            Add a new layer
          </span>
        </button>
      )}
      {isCreatingLayer && (
        <div className="w-full p-2">
          <FormItem>
            <Input
              onChange={(e) => setNewLayerName(e.target.value)}
              autoFocus
              onKeyUp={(evt) => {
                if (evt.key === 'Enter') {
                  submit();
                }
              }}
            />
            <div className="flex items-center gap-2">
              <Button onClick={submit} disabled={isSubmitting}>
                Save
              </Button>
              <Button
                variant={'outline'}
                disabled={isSubmitting}
                onClick={() => {
                  setIsCreatingLayer(false);
                  setNewLayerName('');
                }}
              >
                Cancel
              </Button>
            </div>
          </FormItem>
        </div>
      )}
    </DepthContainer>
  );
};

export const LayersTree: React.FC<{ tenantId: string }> = ({ tenantId }) => {
  const { data } = useSuspenseQuery(getTenantLayersQuery, {
    variables: {
      tenantId,
    },
  });

  const layers = sortBy(data?.tenantsByPk?.layers ?? [], (x) => x.path);
  const bottomLayerPath: string = layers[layers.length - 1]?.path ?? '';

  return (
    <div className="grid gap-2">
      {layers.map((l, i, { length }) => (
        <TreeNodeContainer key={l.id} depth={l.depth}>
          <div className="flex w-full items-center gap-4">
            {length - 1 === i && i !== 0 && (
              <DeleteLayerAction id={l.id} name={l.name} depth={l.depth} />
            )}
            <EditableLayerName id={l.id} name={l.name} />
          </div>
        </TreeNodeContainer>
      ))}
      {!bottomLayerPath.includes(OPTIMISTIC_ID) && (
        <CreateLayer
          key={bottomLayerPath}
          tenantId={tenantId}
          depth={layers.length}
          parentPath={bottomLayerPath}
        />
      )}
    </div>
  );
};
