import { RefreshCcw } from 'lucide-react';
import React, { useState } from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { useDialog } from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  P,
  toast,
} from '@eluve/components';
import {
  useAppointmentId,
  useSummary,
} from '@eluve/frontend-appointment-hooks';
import { useAppointmentTranscript } from '@eluve/frontend-appointment-hooks';
import { useAppointmentActivity } from '@eluve/frontend-appointment-hooks';
import { useNamedLogger } from '@eluve/logger';
import { useCaptureEvent } from '@eluve/posthog-react';
import {
  useTenantIdFromParams,
  useUserIdFromSession,
} from '@eluve/session-helpers';

import { TemplateSelector } from './TemplateSelector';
import { useAppointmentTemplates } from './useAppointmentTemplates';

export interface RegenerateSummaryButtonProps {}

export interface AppointmentTemplate {
  initialPromptTemplateId: string;
  initialOutputTemplateId: string | null;
}

export const RegenerateSummaryButton: React.FC<
  RegenerateSummaryButtonProps
> = () => {
  const logger = useNamedLogger('RegenerateSummaryButton');
  const { isDialogOpen, toggleDialog, setIsDialogOpen } = useDialog();

  const userId = useUserIdFromSession();
  const tenantId = useTenantIdFromParams();
  const currentSummary = useSummary();
  const { transcript } = useAppointmentTranscript();
  const apiClient = useApiClient();
  const appointmentId = useAppointmentId();

  const {
    initialLlmOutputTemplateId,
    initialPromptTemplateId,
    updateAppointmentTemplates,
  } = useAppointmentTemplates();

  const [selectedTemplates, setSelectedTemplates] =
    useState<AppointmentTemplate | null>(null);

  const captureEvent = useCaptureEvent({
    tenantId,
    appointmentId,
    llmOutputId: currentSummary.llmOutputId,
  });

  const { isSummarizationInProgress, isTranscriptionInProgress } =
    useAppointmentActivity();

  const regenerationDisabled =
    isSummarizationInProgress || isTranscriptionInProgress;

  const handleRegenerate = async () => {
    toggleDialog();

    logger.info('Regenerate Summary Button Clicked', {
      userId,
      tenantId,
      appointmentId,
      currrentSummaryLlmOutputId: currentSummary.llmOutputId,
    });

    // update template if changes
    if (selectedTemplates) {
      await updateAppointmentTemplates({
        variables: {
          tenantId: tenantId!,
          id: appointmentId,
          initialPromptTemplateId: selectedTemplates.initialPromptTemplateId,
          initialLlmOutputTemplateId: selectedTemplates.initialOutputTemplateId,
        },
      });
    }

    captureEvent('user_regenerated_note_summary', {
      llmOutputId: currentSummary.llmOutputId,
    });

    try {
      const result = await apiClient.llm.summarizeAppointment({
        body: {},
        params: {
          tenantId: tenantId!,
          appointmentId,
        },
      });

      if (result.status !== 201) {
        throw new Error('Failed to start summarization job');
      }

      toast.success('Regenerating your summary', {
        description: 'Your new summary should be available soon',
      });
    } catch (e) {
      toast.error('Failed to regenerate summary', {
        description: 'Please try again',
      });
    }

    return;
  };

  if (transcript.length < 300) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlinesecondary"
        disabled={regenerationDisabled}
        onClick={() => {
          captureEvent('user_opened_regenerate_note_summary_dialog');
          toggleDialog();
        }}
        size={'xs'}
      >
        <RefreshCcw className="mr-2" size={16} />
        Regenerate
      </Button>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="flex flex-col gap-5 p-8">
          <H2>Regenerating your summary</H2>
          <P>This will overwrite the current summary.</P>
          <div className="h-[0.5px] w-full justify-between rounded-sm bg-gray-5" />

          <div className="flex flex-col gap-1">
            <P>Your new note will be generated with the following template:</P>
            <div className="flex  w-full justify-between rounded-b-xl">
              <TemplateSelector
                selectedPromptTemplateId={
                  selectedTemplates?.initialPromptTemplateId ??
                  initialPromptTemplateId ??
                  undefined
                }
                selectedOutputTemplateId={
                  selectedTemplates?.initialOutputTemplateId ??
                  initialLlmOutputTemplateId ??
                  undefined
                }
                onSelectionChanged={({
                  initialPromptTemplateId,
                  initialOutputTemplateId,
                }) => {
                  setSelectedTemplates({
                    initialPromptTemplateId,
                    initialOutputTemplateId,
                  });
                }}
              />
            </div>
          </div>
          <DialogFooter className="w-full flex-row justify-end gap-2">
            <Button
              variant="ghost"
              className="w-fit bg-gray-3 hover:bg-gray-4"
              onClick={toggleDialog}
            >
              Cancel
            </Button>
            <Button onClick={handleRegenerate} className="w-fit">
              <RefreshCcw className="h-4 pr-1" />
              Regenerate
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
