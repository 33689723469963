import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import React, { useMemo } from 'react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  ColDefBuilder,
  DataTable,
  NewButton,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';
import { P } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { tenantReviewerFragment } from './gql-operations';

const removeTenantReviewerMutation = graphql(`
  mutation removeTenantReviewer($tenantId: uuid!, $userId: uuid!) {
    deleteTenantReviewersByPk(tenantId: $tenantId, userId: $userId) {
      __typename
      tenantId
      userId
    }
  }
`);

type ReviewersRow = {
  tenantId: string;
  userId: string;
  userEmail: string;
  userName: string;
  userFirstName: string;
  userLastName: string;
  addedAt: string;
};

const RemoveReviewerButton: React.FC<{
  userId: string;
  tenantId: string;
  userName: string;
}> = ({ userId, tenantId, userName }) => {
  const [deleteTenantReviewer] = useMutation(removeTenantReviewerMutation, {
    onError: () => toast.error(`Failed to remove ${userName} as a reviewer`),
    optimisticResponse: {
      deleteTenantReviewersByPk: {
        __typename: 'TenantReviewers' as const,
        tenantId,
        userId,
      },
    },
    update: () => {
      cacheUtils.updateFragment(
        {
          fragment: tenantReviewerFragment,
          key: { id: tenantId },
        },
        (existing) =>
          produce(existing, (draft) => {
            if (!draft) return;
            if (!draft.reviewers) return;

            draft.reviewers = draft.reviewers.filter(
              (reviewer) => reviewer.userId !== userId,
            );
          }),
      );
    },
  });

  const deleteReviewer = async () => {
    await deleteTenantReviewer({
      variables: {
        tenantId,
        userId,
      },
    });
  };

  return <NewButton text="Remove" type="subtle" onClick={deleteReviewer} />;
};

const tenantReviewerColumns = new ColDefBuilder<ReviewersRow>()
  .defaultSortable('userId', 'User ID')
  .defaultSortable('userName', 'User Name')
  .defaultSortable('userEmail', 'User Email')
  .dateSortable('addedAt', 'Added At')
  .colDef({
    header: 'Actions',
    cell: ({ row }) => (
      <RemoveReviewerButton
        tenantId={row.original.tenantId}
        userId={row.original.userId}
        userName={row.original.userName}
      />
    ),
  })
  .build();

export const TenantReviewersList: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;

  const data = useCompleteFragment({
    fragment: tenantReviewerFragment,
    key: {
      id: tenantId,
    },
  });

  const rows = useMemo(() => {
    return (data?.reviewers ?? []).map<ReviewersRow>((r) => ({
      tenantId,
      userId: r.user.id,
      userEmail: r.user.email,
      userFirstName: r.user.firstName,
      userLastName: r.user.lastName,
      userName: `${r.user.firstName} ${r.user.lastName}`,
      addedAt: r.createdAt,
    }));
  }, [data, tenantId]);

  return (
    <VStack>
      <span className={textStyles.title({ size: 's', weight: 'bold' })}>
        Current Reviewers
        <P>These users have reviewer access to this tenant</P>
      </span>
      <DataTable
        data={rows}
        columns={tenantReviewerColumns}
        isPaginated={false}
        enableGlobalSearch
        isCompact
        initialSortingState={[
          {
            id: 'userName',
            desc: false,
          },
        ]}
      />
    </VStack>
  );
};
