import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Button, toast } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { useCaptureEvent } from '@eluve/posthog-react';

const bytesToMB = (bytes: number) => bytes / 1024 / 1024;

const UsedJSHeapSizeThreshold = 1000;
const DEVICE_STATS_REPORTING_INTERVAL = 5 * 60 * 1000;

export const AppLayout: React.FC = () => {
  const logger = useNamedLogger('App');
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const captureEvent = useCaptureEvent();

  useEffect(
    function reportMemoryUsage() {
      const interval = setInterval(() => {
        if (window.performance && window.performance.memory) {
          const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } =
            window.performance.memory;

          logger.info('Memory usage', {
            jsHeapSizeLimit: bytesToMB(jsHeapSizeLimit),
            totalJSHeapSize: bytesToMB(totalJSHeapSize),
            usedJSHeapSize: bytesToMB(usedJSHeapSize),
          });

          if (bytesToMB(usedJSHeapSize) > UsedJSHeapSizeThreshold) {
            logger.info('Prompting user to reload the page to release memory');
            setToastOpen(true);
          }
        }
      }, DEVICE_STATS_REPORTING_INTERVAL);

      return () => {
        clearInterval(interval);
      };
    },
    [logger, setToastOpen],
  );

  useEffect(
    function reportStorageEstimate() {
      const interval = setInterval(async () => {
        try {
          if (navigator.storage.estimate) {
            const { usage, quota } = await navigator.storage.estimate();

            logger.info('Storage estimate', {
              usage: usage ? bytesToMB(usage) : undefined,
              quota: quota ? bytesToMB(quota) : undefined,
            });
          }
        } catch (e) {
          Sentry.captureException(e);
        }
      }, DEVICE_STATS_REPORTING_INTERVAL);

      return () => clearInterval(interval);
    },
    [logger],
  );

  useEffect(
    function displayToast() {
      if (!toastOpen) {
        return;
      }
      toast(
        <div className="flex w-full flex-col items-center justify-center sm:flex-row">
          <div className="mr-3">
            Things might run smoother with a quick page reload. Care to try?
          </div>
          <div>
            <Button
              variant="toastprimary"
              size="xs"
              onClick={() => {
                captureEvent('user_reloaded_for_high_memory', undefined, {
                  send_instantly: true,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 50);
              }}
            >
              Reload now
            </Button>
            <Button
              variant="toastsecondary"
              size="xs"
              className="mt-1"
              onClick={() => toast.dismiss()}
            >
              Dismiss
            </Button>
          </div>
        </div>,
        {
          position: 'bottom-right',
          duration: Infinity,
          onDismiss: () => {
            setToastOpen(false);
          },
        },
      );
    },
    [toastOpen, setToastOpen, captureEvent],
  );

  return <Outlet />;
};
