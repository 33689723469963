import { useMutation } from '@apollo/client';

import { cacheUtils, eluveAdminHasuraContext } from '@eluve/apollo-client';
import { toast } from '@eluve/components';

import { getAdminTenantsSummaryQuery } from '../../../components/query';
import {
  AddUserToTenantMutation,
  TenantUsersFragment,
  UserFragment,
} from '../operations';

export const useAddUserToTenant = (
  tenantId: string,
  onUpdate: () => Promise<void> = () => Promise.resolve(),
) => {
  const [addUserToTenant] = useMutation(AddUserToTenantMutation, {
    context: eluveAdminHasuraContext,
    refetchQueries: [getAdminTenantsSummaryQuery],
    onError: () => toast.error('Failed to add user to tenant'),
    optimisticResponse: (vars) => {
      const user = cacheUtils.readFragment({
        fragment: UserFragment,
        key: { id: vars.userId },
      });

      return {
        __typename: 'Mutation' as const,
        insertTenantUsersOne: {
          __typename: 'TenantUsers' as const,
          tenantId: vars.tenantId,
          userId: vars.userId,
          role: vars.role,
          createdAt: new Date().toISOString(),
          lastAccessedAt: new Date().toISOString(),
          user: {
            ...user,
            __typename: 'Users' as const,
            id: vars.userId,
          },
        },
      };
    },

    update(_, { data }) {
      onUpdate().then(() => {
        cacheUtils.updateFragment(
          {
            fragment: TenantUsersFragment,
            key: { id: tenantId },
          },
          (existingData) => {
            if (!data?.insertTenantUsersOne) return existingData;

            if (
              existingData?.tenantUsers.some(
                (tu) => tu.userId === data.insertTenantUsersOne?.userId,
              )
            ) {
              return existingData;
            }

            return {
              tenantUsers: [
                ...(existingData?.tenantUsers ?? []),
                data.insertTenantUsersOne,
              ],
            };
          },
        );
      });
    },
  });

  return addUserToTenant;
};
