import { zodResolver } from '@hookform/resolvers/zod';
import capitalize from 'lodash/capitalize';
import { RocketIcon } from 'lucide-react';
import React from 'react';
import { useForm } from 'react-hook-form';

import {
  CreatePromptTemplate,
  CreatePromptTemplateSchema,
} from '@eluve/api-contract';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Divider,
  Form,
  FormField,
  FormItem,
  FormMessage,
  H2,
  HStack,
  Input,
  Label,
  NewButton,
  P,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Textarea,
  VStack,
} from '@eluve/components';
import { LLM_OUTPUT_TYPE_VARIABLES } from '@eluve/llm-outputs';
import { useNamedLogger } from '@eluve/logger';

const formDefaults: Partial<CreatePromptTemplate> = {
  name: '',
  outputType: 'SOAP_NOTE',
  template: [
    { role: 'system', content: '' },
    { role: 'user', content: '' },
    { role: 'assistant', content: '' },
  ],
};

export interface CreatePromptTemplateFormProps {
  initialFormValues?: Partial<CreatePromptTemplate>;
  onCancel: () => void;
  onSubmit: (data: CreatePromptTemplate) => void;
  shouldShowMetadata?: boolean;
}

export const CreatePromptTemplateForm: React.FC<
  CreatePromptTemplateFormProps
> = ({ initialFormValues, onCancel, onSubmit, shouldShowMetadata = true }) => {
  const logger = useNamedLogger('CreatePromptTemplateForm');
  const defaultValues = initialFormValues ? initialFormValues : formDefaults;

  const form = useForm<CreatePromptTemplate>({
    resolver: zodResolver(CreatePromptTemplateSchema),
    defaultValues,
    mode: 'onChange',
  });

  const selectedOutputType = form.watch('outputType');

  const availableTemplateVariables =
    LLM_OUTPUT_TYPE_VARIABLES[selectedOutputType];

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, (invalid) =>
          logger.debug(
            `Invalid form submission attempted: ${JSON.stringify(invalid)}`,
          ),
        )}
      >
        <HStack justify="end" className="-mt-14">
          <NewButton disabled={!form.formState.isDirty} submit text="Create" />
          <NewButton onClick={onCancel} text="Cancel" type="subtle" />
        </HStack>
        <Divider className="mb-4 mt-6" />
        {shouldShowMetadata && (
          <>
            <H2>Metadata</H2>
            <FormField
              control={form.control}
              name="outputType"
              render={({ field, fieldState: { error } }) => (
                <FormItem className="mt-4 max-w-lg">
                  <Select
                    value={field.value}
                    onValueChange={(val) => {
                      field.onChange(val);
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Output Type" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.keys(LLM_OUTPUT_TYPE_VARIABLES)
                        .sort()
                        .map((type) => (
                          <SelectItem key={type} value={type}>
                            {type.split('_').map(capitalize).join(' ')}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  {error && (
                    <FormMessage className="mt-4">{error.message}</FormMessage>
                  )}
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <FormItem className="max-w-lg">
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <Input className="bg-white/5" placeholder="Name" {...field} />
                  {error && (
                    <FormMessage className="mt-4">{error.message}</FormMessage>
                  )}
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field, fieldState: { error } }) => (
                <FormItem className="max-w-lg">
                  <label htmlFor="description" className="sr-only">
                    Description
                  </label>
                  <Textarea
                    className="bg-white/5"
                    placeholder="Description"
                    {...field}
                  />
                  {error && (
                    <FormMessage className="mt-4">{error.message}</FormMessage>
                  )}
                </FormItem>
              )}
            />
            <Divider className="mb-4 mt-8" />
          </>
        )}

        <H2>Prompt Variant</H2>

        {Boolean(availableTemplateVariables?.length) && (
          <Alert className="mt-4">
            <RocketIcon className="h-4 w-4" />
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              <P>
                You can substitute the following variables in your messages:{' '}
                {availableTemplateVariables.map((variable) => (
                  <span key={variable}>
                    <code className="rounded-sm bg-gray-6 p-0.5 text-gray-12">{`{{${variable}}}`}</code>{' '}
                  </span>
                ))}
              </P>
            </AlertDescription>
          </Alert>
        )}
        <FormField
          control={form.control}
          name="template"
          render={({ field, fieldState: { error } }) => (
            <>
              {error && <FormMessage>{error.message}</FormMessage>}
              <FormItem className="w-full">
                <Label>System Message</Label>
                <Textarea
                  rows={10}
                  className="bg-white/5"
                  placeholder="Include system context to set the stage for the prompt"
                  value={field.value[0]?.content}
                  onChange={(e) => {
                    const fieldValue = field.value;
                    const systemMessage = fieldValue[0];
                    if (systemMessage) {
                      systemMessage.content = e.target.value;
                      field.onChange(fieldValue);
                    }
                  }}
                />
              </FormItem>
              <FormItem className="w-full">
                <Label>User Message</Label>
                <Textarea
                  rows={10}
                  className="bg-white/5"
                  placeholder="Instructions and context for the assistant about what it should do"
                  value={field.value[1]?.content}
                  onChange={(e) => {
                    const fieldValue = field.value;
                    const userMessage = fieldValue[1];
                    if (userMessage) {
                      userMessage.content = e.target.value;
                      field.onChange(fieldValue);
                    }
                  }}
                />
              </FormItem>
              <FormItem className="w-full">
                <Label>Assistant Message</Label>
                <Textarea
                  rows={10}
                  className="bg-white/5"
                  placeholder="A message to guide the assistant on what to return"
                  value={field.value[2]?.content}
                  onChange={(e) => {
                    const fieldValue = field.value;
                    const assistantMessage = fieldValue[2];
                    if (assistantMessage) {
                      assistantMessage.content = e.target.value;
                      field.onChange(fieldValue);
                    }
                  }}
                />
              </FormItem>
            </>
          )}
        />

        <VStack className="mt-2">
          <FormField
            control={form.control}
            name="variantNotes"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="w-full">
                <Label>Notes (Internal)</Label>
                <Input
                  className="bg-white/5"
                  placeholder="Jot down any internal notes about this template. These are not visible to the user."
                  value={field.value ?? ''}
                  onChange={(e) => field.onChange(e.target.value)}
                />
                {error && (
                  <FormMessage className="mt-4">{error.message}</FormMessage>
                )}
              </FormItem>
            )}
          />
        </VStack>
      </form>
    </Form>
  );
};
