import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { FeedbackModal, Tag } from '@eluve/blocks';
import { GetFeedbackTagsDocument } from '@eluve/client-gql-operations';
import { P, toast } from '@eluve/components';
import { useIsAppointmentReadonly } from '@eluve/frontend-appointment-hooks';
import { FeedbackTypeEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

const insertSummaryFeedbackMutation = graphql(`
  mutation insertSummaryFeedback(
    $appointmentId: uuid!
    $comment: String
    $summarySection: String
    $type: FeedbackTypeEnum!
    $llmOutputId: uuid
  ) {
    insertSummaryFeedbackOne(
      object: {
        appointmentId: $appointmentId
        comment: $comment
        summarySection: $summarySection
        type: $type
        llmOutputId: $llmOutputId
      }
    ) {
      id
      type
      __typename
    }
  }
`);

const updateSummaryFeedbackMutation = graphql(`
  mutation updateSummaryFeedback(
    $id: uuid!
    $comment: String
    $summarySection: String
    $rating: Int
  ) {
    updateSummaryFeedback(
      where: { id: { _eq: $id } }
      _set: {
        comment: $comment
        summarySection: $summarySection
        rating: $rating
      }
    ) {
      affectedRows
    }
  }
`);

const insertSummaryFeedbackTagsMutation = graphql(`
  mutation insertSummaryFeedbackTags(
    $feedbackTags: [SummaryFeedbackTagsInsertInput!]!
  ) {
    insertSummaryFeedbackTags(objects: $feedbackTags) {
      affectedRows
    }
  }
`);

export interface SummaryFeedbackModalProps {
  appointmentId: string;
  llmOutputId: string | null;
  summarySection?: string;
}

export type SummaryFeedbackTags = Tag & { type: FeedbackTypeEnum | null };

export const SummaryFeedbackModal: React.FC<SummaryFeedbackModalProps> = ({
  appointmentId,
  summarySection,
  llmOutputId,
}) => {
  const logger = useNamedLogger('summary-feedback-modal');
  const [tags, setTags] = useState<SummaryFeedbackTags[]>([]);
  const [selectedFeedbackType, setFeedbackType] =
    useState<FeedbackTypeEnum | null>(null);

  const [feedbackId, setFeedbackId] = useState<string>('');

  const { data: getFeedbackTagsData } = useQuery(GetFeedbackTagsDocument);

  const [insertFeedbackMutation] = useMutation(insertSummaryFeedbackMutation, {
    onCompleted: (data) => {
      const message =
        data.insertSummaryFeedbackOne?.type === 'POSITIVE'
          ? 'Thanks for the feedback! Glad you liked this one!'
          : 'Sorry that this summary missed the mark. Every piece of feedback you give (positive or negative) helps us improve our future results.';

      toast.success(message);
    },
    onError: () => toast.error('Failed to save feedback'),
  });

  const [updateFeedbackMutation] = useMutation(updateSummaryFeedbackMutation, {
    onCompleted: () => {
      toast.success('Feedback updated');
    },
    onError: () => toast.error('Failed to update feedback'),
  });

  const [insertFeedbackTagMutation] = useMutation(
    insertSummaryFeedbackTagsMutation,
    {
      onCompleted: () => {
        toast.success('Feedback tags updated');
      },
      onError: () => toast.error('Failed to update feedback tags'),
    },
  );
  const isReadonly = useIsAppointmentReadonly();

  useEffect(() => {
    setTags(
      getFeedbackTagsData?.feedbackTags?.map((tag) => ({
        ...tag,
        isTagged: false,
      })) ?? [],
    );
  }, [getFeedbackTagsData]);

  const insertFeedback = async (
    type: FeedbackTypeEnum,
    comment?: string,
    section?: string,
  ) => {
    const { data } = await insertFeedbackMutation({
      variables: {
        appointmentId,
        type,
        comment: comment ?? null,
        summarySection: section ?? null,
        llmOutputId,
      },
    });

    setFeedbackId(data?.insertSummaryFeedbackOne?.id ?? '');
  };

  const onFeedbackClick = async (type: FeedbackTypeEnum) => {
    setFeedbackType(type);

    // save thumbs up / down feedback
    await insertFeedback(type);
  };

  const onTagPressedChange = async (tagId: number, isTagged: boolean) => {
    setTags(
      tags.map((t) => {
        if (t.id === tagId) {
          return { ...t, isTagged };
        }
        return t;
      }),
    );
  };

  const onAdditionalFeedbackSubmit = async (
    type: FeedbackTypeEnum | null,
    comment: string,
    tags: Tag[],
    rating: number | null,
  ) => {
    if (feedbackId) {
      await updateFeedbackMutation({
        variables: {
          id: feedbackId,
          comment,
          summarySection: summarySection ?? null,
          rating,
        },
      });
    } else if (selectedFeedbackType) {
      await insertFeedback(selectedFeedbackType, comment, summarySection);
    }

    if (!feedbackId) {
      logger.error('Failed to save feedback');
      return;
    }

    const feedbackTagsInput = tags
      .filter((tag) => tag.isTagged)
      .map((tag) => ({ summaryFeedbackId: feedbackId, feedbackTagId: tag.id }));

    await insertFeedbackTagMutation({
      variables: {
        feedbackTags: feedbackTagsInput,
      },
    });
  };

  return (
    !isReadonly && (
      <>
        <P>Was this medical summary helpful?</P>
        <FeedbackModal
          feedbackType={selectedFeedbackType}
          tags={[
            ...tags.filter((tag) => tag.type === selectedFeedbackType),
            ...tags.filter((tag) => tag.type !== selectedFeedbackType),
          ]}
          onFeedbackClick={onFeedbackClick}
          onTagPressedChange={onTagPressedChange}
          onAdditionalFeedbackSubmit={onAdditionalFeedbackSubmit}
        />
      </>
    )
  );
};
