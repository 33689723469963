import { useQuery } from '@apollo/client';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { DetailsList } from '@eluve/blocks';
import {
  Box,
  Divider,
  H3,
  H4,
  HStack,
  LinkButton,
  PageTitle,
  VStack,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import {
  formatHumanName,
  getDatadogUrlForUser,
  getPosthogUrlForUser,
} from '@eluve/utils';

import { appConfig } from '../../../../config';

import { UpdateUserAction } from './UpdateUserAction';

const GetUserInfoQuery = graphql(`
  query gerUserInfo($id: uuid!) {
    usersByPk(id: $id) {
      id
      firstName
      lastName
      email
      createdAt
      appointmentsAggregate {
        aggregate {
          count
        }
      }
      tenants {
        userId
        role
        tenantId
        tenant {
          name
        }
      }
    }
  }
`);

export const UserPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { loading, data, error } = useQuery(GetUserInfoQuery, {
    variables: {
      id: userId ?? 'no-id',
    },
  });

  let datadogUrl;
  let posthogUrl;
  if (userId) {
    datadogUrl = getDatadogUrlForUser(userId);
    posthogUrl = getPosthogUrlForUser(
      appConfig.VITE_POSTHOG_PROJECT_ID,
      userId,
    );
  }

  return (
    <div>
      <PageTitle
        isFullWidth
        subtitle={
          <HStack justify="between" wrap>
            <H3>{`ID: ${userId ?? '-'}`}</H3>
            {data && userId && (
              <HStack inline wFull={false}>
                {datadogUrl && (
                  <LinkButton
                    href={datadogUrl}
                    text="View Datadog Logs"
                    type="outline"
                  />
                )}
                {posthogUrl && (
                  <LinkButton
                    href={posthogUrl}
                    text="View Posthog Events"
                    type="outline"
                  />
                )}
                <UpdateUserAction
                  userId={userId}
                  defaultValues={{
                    firstName: data.usersByPk?.firstName ?? '',
                    lastName: data.usersByPk?.lastName ?? '',
                    email: data.usersByPk?.email ?? '',
                  }}
                />
              </HStack>
            )}
          </HStack>
        }
      >
        User Details
      </PageTitle>

      <HStack className="ml-14">
        {loading && <Box hStack>Loading ...</Box>}
        {error && (
          <H4>
            <span className="text-negative500">Unable to find User</span>
          </H4>
        )}
      </HStack>

      {data && (
        <VStack align="stretch">
          <DetailsList
            title={{ label: 'Info' }}
            items={[
              {
                label: 'Name',
                value: formatHumanName(
                  data.usersByPk?.firstName,
                  data.usersByPk?.lastName,
                ),
              },
              { label: 'Email', value: data.usersByPk?.email },
              {
                label: 'Associated Appointments',
                value:
                  data.usersByPk?.appointmentsAggregate?.aggregate?.count ??
                  '-',
              },
            ]}
          />
          <VStack className="rounded-lg p-6 shadow-md">
            <H4>Associated Tenants</H4>
            <Divider />
            <Box className="grid grid-cols-2 gap-x-8 gap-y-3">
              <b>Name</b>
              <b>Role</b>
              {data.usersByPk?.tenants.map(({ role, tenantId, tenant }) => (
                <React.Fragment key={tenantId}>
                  <Link
                    to={`/admin/tenants/${tenantId}`}
                    className="hover:underline"
                  >
                    {tenant.name}
                  </Link>
                  <p>{role}</p>
                </React.Fragment>
              ))}
            </Box>
          </VStack>
        </VStack>
      )}
    </div>
  );
};
