import { useMutation, useSuspenseQuery } from '@apollo/client';
import React, { useMemo } from 'react';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  ColDefBuilder,
  DataTable,
  EluveLogo,
  Switch,
  Tag,
  toast,
} from '@eluve/components';
import {
  getTenantOutputTemplatesQuery,
  updateTenantOutputTemplateMutation,
} from '@eluve/frontend-feature-prompt-templates';
import { VendorTypesEnum } from '@eluve/graphql-types';
import { useIsEluveAdmin } from '@eluve/session-helpers';
import { getVendorProvider } from '@eluve/vendor';

type OutputTemplateRow = {
  id: string;
  name: string;
  description: string | null;
  createdAt: string;
  isActive: boolean;
  source: VendorTypesEnum | 'Eluve';
  sourceArgs?: {
    domain: string;
    externalChartTemplateId: string;
    externalChartTemplateOwnerId: string;
  };
  variantCount: number;
  hasNewerVariant: boolean;
};

const ToggleCell: React.FC<{
  isActive: boolean;
  templateId: string;
}> = ({ isActive, templateId }) => {
  const isEluveAdmin = useIsEluveAdmin();

  const [updateTenantOutputTemplate] = useMutation(
    updateTenantOutputTemplateMutation,
  );

  const submit = (isChecked: boolean) => {
    updateTenantOutputTemplate({
      context: eluveAdminHasuraContext,
      variables: {
        isActive: isChecked,
        templateId,
      },
      onError: () =>
        toast.error(`Failed to ${isChecked ? '' : 'de'}activate template`),
      optimisticResponse: () => ({
        updateLlmOutputTemplatesByPk: {
          __typename: 'LlmOutputTemplates' as const,
          id: templateId,
          isActive: isChecked,
        },
      }),
    });
  };

  return (
    <Switch
      checked={isActive ?? false}
      disabled={!isEluveAdmin}
      isCompact
      onCheckedChange={(isChecked) => submit(isChecked)}
    />
  );
};

const SourceCell: React.FC<{
  source: VendorTypesEnum | 'Eluve';
  sourceArgs?: {
    domain: string;
    externalChartTemplateId: string;
    externalChartTemplateOwnerId: string;
  };
}> = ({ source, sourceArgs }) => {
  if (source === 'Eluve') {
    return <EluveLogo />;
  }

  const vendorProvider = getVendorProvider(source);
  const logoData = vendorProvider.getLogo();

  if (vendorProvider.getChartTemplateUrl && sourceArgs) {
    const { domain, externalChartTemplateId, externalChartTemplateOwnerId } =
      sourceArgs;
    const url = vendorProvider.getChartTemplateUrl({
      domain,
      externalChartTemplateOwnerId,
      externalChartTemplateId,
    });
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img className="w-10" src={logoData} alt={`${source} logo`} />
      </a>
    );
  } else {
    return <img className="w-10" src={logoData} alt={`${source} logo`} />;
  }
};

const columns = new ColDefBuilder<OutputTemplateRow>()
  .linkSortable('name', (row) => `./${row.id}`)
  .colDef({
    header: 'Is Active',
    cell: ({
      row: {
        original: { id, isActive },
      },
    }) => <ToggleCell isActive={isActive} templateId={id} />,
  })
  .colDef({
    header: 'Source',
    cell: ({
      row: {
        original: { source, sourceArgs },
      },
    }) => {
      return (
        <TooltipLabel label={source}>
          <div>
            <SourceCell source={source} sourceArgs={sourceArgs} />
          </div>
        </TooltipLabel>
      );
    },
  })
  .defaultSortable('description')
  .dateSortable('createdAt', 'Created At')
  .defaultSortable('variantCount', '# Variant')
  .colDef({
    header: 'Tags',
    cell: ({ row }) =>
      row.original.hasNewerVariant ? (
        <Tag label="Newer Variant Available" size="xs" pill color="teal" />
      ) : null,
  })
  .build();

export interface TenantOutputTemplatesListProps {}

export const TenantOutputTemplatesList: React.FC<
  TenantOutputTemplatesListProps
> = () => {
  const { data } = useSuspenseQuery(getTenantOutputTemplatesQuery, {
    context: eluveAdminHasuraContext,
  });

  const rows = useMemo(() => {
    const { externalEhr, llmOutputTemplates } = data;
    return llmOutputTemplates.map<OutputTemplateRow>((template) => {
      const ehr = externalEhr?.find(
        (e) => e.id === template.external_chart_templates[0]?.externalEhrId,
      );
      const vendor = ehr?.vendor;
      const sourceArgs =
        vendor &&
        template.external_chart_templates[0]?.externalChartTemplateId &&
        template.external_chart_templates[0]?.externalChartTemplateOwnerId
          ? {
              domain: ehr.domain,
              externalChartTemplateId:
                template.external_chart_templates[0]?.externalChartTemplateId,
              externalChartTemplateOwnerId:
                template.external_chart_templates[0]
                  ?.externalChartTemplateOwnerId,
            }
          : undefined;
      return {
        id: template.id,
        name: template.name,
        description: template.description,
        createdAt: template.createdAt,
        isActive: template.isActive,
        sourceArgs,
        source: vendor ?? 'Eluve',
        variantCount: template.variants.length,
        hasNewerVariant:
          Boolean(template.tenantId) &&
          template.variants[0]?.isCurrentDefault === false,
      };
    });
  }, [data]);

  return (
    <DataTable
      columns={columns}
      data={rows}
      enableGlobalSearch
      initialSortingState={[
        {
          id: 'createdAt',
          desc: true,
        },
      ]}
    />
  );
};
