import { useSuspenseQuery } from '@apollo/client';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { TabNav } from '@eluve/smart-blocks';

import { AppointmentAnnotationTools } from './AppointmentAnnotationTools';
import {
  draftContentFragment,
  ehrDiffContentFragment,
  summaryContentFragment,
} from './AppointmentArtifacts';
import {
  appointmentAnnotationsFragment,
  appointmentLowQualityFactorsFragment,
  appointmentReviewLinksFragment,
  appointmentTagOptionsFragment,
  appointmentTagsFragment,
} from './operations';
import { reviewAppointmentDetailsTabNav } from './routes';
import { useAppointmentParams } from './useAppointmentParams';

export interface ReviewAppointmentLayoutProps {
  showTools?: boolean;
}

const appointmentArtifactsQuery = graphql(
  `
    query appointmentArtifacts(
      $tenantId: uuid!
      $appointmentId: uuid!
      $includeAnnotations: Boolean!
    ) {
      appointmentsByPk(tenantId: $tenantId, id: $appointmentId) {
        ...SummaryContent
        ...DraftContent
        ...EhrDiffContent
        ...Annotations @include(if: $includeAnnotations)
        ...LowQualityFactors @include(if: $includeAnnotations)
        ...ReviewLinks @include(if: $includeAnnotations)
        ...Tags @include(if: $includeAnnotations)
      }
      ...TagOptions @include(if: $includeAnnotations)
    }
  `,
  [
    summaryContentFragment,
    draftContentFragment,
    ehrDiffContentFragment,
    appointmentAnnotationsFragment,
    appointmentLowQualityFactorsFragment,
    appointmentReviewLinksFragment,
    appointmentTagOptionsFragment,
    appointmentTagsFragment,
  ],
);

export const ReviewAppointmentLayout: React.FC<
  ReviewAppointmentLayoutProps
> = ({ showTools = false }) => {
  const { tenantId, appointmentId } = useAppointmentParams();

  useSuspenseQuery(appointmentArtifactsQuery, {
    variables: { tenantId, appointmentId, includeAnnotations: showTools },
  });
  return (
    <div>
      {showTools && <AppointmentAnnotationTools />}

      <Box>
        <TabNav tabs={reviewAppointmentDetailsTabNav} />
      </Box>
      <Outlet />
    </div>
  );
};
