import { useMutation } from '@apollo/client';
import { TrashIcon } from 'lucide-react';
import { useState } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { getAdminTenantsSummaryQuery } from './query';

interface DeleteTenantActionProps {
  tenantId: string;
}

const deleteTenantMutation = graphql(`
  mutation deleteTenant($tenantId: uuid!, $deletedAt: timestamptz!) {
    updateTenantsByPk(
      pkColumns: { id: $tenantId }
      _set: { deletedAt: $deletedAt }
    ) {
      id
    }
  }
`);

const tenantUsersFragment = graphql(`
  fragment TenantUsers on Tenants {
    tenantUsersAggregate {
      aggregate {
        count
      }
    }
  }
`);

const userTenantsFragment = graphql(`
  fragment UserTenants on Users {
    tenants {
      userId
      tenantId
    }
  }
`);

export const DeleteTenantAction: React.FC<DeleteTenantActionProps> = ({
  tenantId,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteTenant, { loading }] = useMutation(deleteTenantMutation, {
    refetchQueries: [getAdminTenantsSummaryQuery],
  });

  const tenantUsers = useCompleteFragment({
    fragment: tenantUsersFragment,
    key: { id: tenantId },
  })?.tenantUsersAggregate.aggregate?.count;

  const handleDeleteTenant = async () => {
    deleteTenant({
      variables: {
        tenantId,
        deletedAt: new Date().toISOString(),
      },
      onCompleted: () => {
        setIsDialogOpen(false);
      },
      onError: () => {
        toast.error('Failed to delete tenant');
      },
    });
  };

  if (tenantUsers === undefined || tenantUsers > 0) {
    return null;
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen} modal>
      <DialogTrigger asChild>
        <Button variant="destructive" size="icon">
          <TooltipLabel label="Delete Tenant">
            <TrashIcon />
          </TooltipLabel>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Tenant</DialogTitle>
        </DialogHeader>
        <p>Are you sure you want to soft delete this tenant?</p>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => setIsDialogOpen(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteTenant}
            variant="destructive"
            disabled={loading}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
