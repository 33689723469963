import { useMutation, useSuspenseQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  DynamicArtifactTemplateBuilder,
  templateBuilderStore,
} from '@eluve/blocks';
import { NewButton, PageTitle, toast } from '@eluve/components';
import {
  DynamicArtifactTemplate,
  dynamicArtifactTemplateSchema,
} from '@eluve/llm-outputs';

import {
  createOutputTemplateVariantMutation,
  getOutputTemplateVariantQuery,
} from './operations';

export const CreateOutputTemplateVariantPage: React.FC = () => {
  const { outputTemplateId, baseVariantNumber } = useParams() as {
    outputTemplateId: string;
    baseVariantNumber: string;
  };
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  const [createOutputTemplateVariant] = useMutation(
    createOutputTemplateVariantMutation,
    {
      onCompleted: ({ insertLlmOutputTemplateVariantsOne: template }) => {
        templateBuilderStore.send({ type: 'reset' });
        if (template) {
          navigate(
            `/admin/output-templates/${template.outputTemplateId}/variant/${template.variantNumber}`,
          );
        }
      },
      onError: () => toast.error('Failed to create output template'),
    },
  );

  const handleSubmit = () => {
    const { context } = templateBuilderStore.getSnapshot();
    const dynamicArtifact: DynamicArtifactTemplate = {
      name: context.name,
      description: context.description,
      blocks: context.blocks,
    };

    createOutputTemplateVariant({
      variables: {
        outputTemplateId,
        template: dynamicArtifact,
        variantNotes: context.variantNotes,
      },
    });
  };

  const {
    data: { llmOutputTemplatesByPk: baseTemplateData },
  } = useSuspenseQuery(getOutputTemplateVariantQuery, {
    variables: {
      id: outputTemplateId,
      variantNumber: Number(baseVariantNumber),
    },
  });

  const baseTemplate = useMemo(() => {
    const parsed = dynamicArtifactTemplateSchema.safeParse(
      baseTemplateData?.variants?.[0]?.template,
    );
    if (!parsed.success) {
      return undefined;
    }

    return parsed.data;
  }, [baseTemplateData]);

  useEffect(() => {
    if (baseTemplate) {
      templateBuilderStore.send({
        type: 'hydrateArtifact',
        artifactTemplate: baseTemplate,
        outputType: baseTemplateData?.llmOutputType ?? 'SOAP_NOTE',
      });
    }
  }, [baseTemplate, baseTemplateData?.llmOutputType]);

  return (
    <div className="container">
      <PageTitle subtitle={`Adding new variant for ${baseTemplate?.name}`}>
        Add New Output Template Variant
      </PageTitle>
      <NewButton disabled={!isValid} onClick={handleSubmit} text="Submit" />
      <DynamicArtifactTemplateBuilder
        showJsonSchema
        onIsValidChange={setIsValid}
      />
    </div>
  );
};
