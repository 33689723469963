import { Label } from '@radix-ui/react-context-menu';
import noop from 'lodash/noop';
import React, { useState } from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { Box, HStack, VStack } from '@eluve/components';
import {
  DynamicArtifactTemplate,
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import {
  DynamicArtifactRenderer,
  SummarySectionMarkdown,
} from '@eluve/smart-blocks';
import { Translator } from '@eluve/translation';
import {
  AppointmentSummaryKeys,
  summaryKeyToSectionTitleMap,
} from '@eluve/utils';

import { useAppointmentParams } from '../useAppointmentParams';

import { draftContentFragment } from './operations';

export const DraftContent: React.FC = () => {
  const { appointmentId } = useAppointmentParams();

  const appointment = useCompleteFragment({
    fragment: draftContentFragment,
    key: { id: appointmentId },
  });
  const [translatedContent, setTranslatedContent] = useState<string | null>(
    null,
  );

  let hydratedHumanOutput: DynamicArtifactTemplate | undefined = undefined;
  let translatedHydratedHumanOutput: DynamicArtifactTemplate | undefined =
    undefined;
  const humanSoapNote = appointment?.humanOutputs?.[0];
  const humanSoapNoteContent = humanSoapNote?.output?.content;

  const toTranslate = {
    humanSoapNoteContent,
    additionalNotes: appointment?.doctor_interaction?.additionalNotes,
  };

  const parsedTranslatedContent: typeof toTranslate = translatedContent
    ? JSON.parse(translatedContent)
    : null;

  const translatedHumanSoapNoteContent =
    parsedTranslatedContent?.humanSoapNoteContent;
  const translatedAdditionalNotes = parsedTranslatedContent?.additionalNotes;

  const outputVariant =
    humanSoapNote?.output?.llm_output?.metadata?.output_variant;
  if (outputVariant) {
    const artifactTemplate = dynamicArtifactTemplateSchema.parse(
      outputVariant.template,
    );

    hydratedHumanOutput = hydrateDynamicArtifactTemplate(
      artifactTemplate,
      humanSoapNoteContent ?? {},
    );
    if (translatedContent) {
      translatedHydratedHumanOutput = hydrateDynamicArtifactTemplate(
        artifactTemplate,
        translatedHumanSoapNoteContent ?? {},
      );
    }
  }

  return (
    <VStack>
      <Box className="w-1/6">
        <Label>Need translation?</Label>
        <Translator
          originalContent={JSON.stringify(toTranslate)}
          setTranslatedContent={setTranslatedContent}
        />
      </Box>
      <HStack align="start">
        <VStack>
          <Label>Doctor Draft</Label>
          {hydratedHumanOutput ? (
            <DynamicArtifactRenderer
              isReadonly={true}
              blocks={hydratedHumanOutput.blocks}
            />
          ) : (
            <VStack>
              {Object.entries(summaryKeyToSectionTitleMap)
                .filter(([summaryKey]) => humanSoapNoteContent?.[summaryKey])
                .map(([key, sectionTitle]) => {
                  const summaryValue = humanSoapNoteContent?.[key];
                  const summaryKey = key as AppointmentSummaryKeys;
                  return (
                    <SummarySectionMarkdown
                      appointmentId={appointmentId}
                      key={summaryKey}
                      summaryKey={summaryKey}
                      sectionTitle={sectionTitle}
                      content={summaryValue}
                      disabled={true}
                      handleContentChange={noop}
                    />
                  );
                })}
            </VStack>
          )}
          <Box>
            {appointment?.doctor_interaction?.additionalNotes && (
              <SummarySectionMarkdown
                appointmentId={appointmentId}
                key="AdditionalNotes"
                summaryKey={`AdditionalNotes` as any}
                sectionTitle="Additional Notes"
                content={appointment?.doctor_interaction?.additionalNotes}
                disabled={true}
                handleContentChange={noop}
              />
            )}
          </Box>
        </VStack>
        {translatedHumanSoapNoteContent && (
          <VStack>
            <Label>Doctor Draft (translated)</Label>
            {translatedHydratedHumanOutput ? (
              <DynamicArtifactRenderer
                isReadonly={true}
                blocks={translatedHydratedHumanOutput.blocks}
              />
            ) : (
              <VStack>
                {Object.entries(summaryKeyToSectionTitleMap)
                  .filter(
                    ([summaryKey]) =>
                      translatedHumanSoapNoteContent?.[summaryKey],
                  )
                  .map(([key, sectionTitle]) => {
                    const summaryValue = translatedHumanSoapNoteContent?.[key];
                    const summaryKey = key as AppointmentSummaryKeys;
                    return (
                      <SummarySectionMarkdown
                        appointmentId={appointmentId}
                        key={summaryKey + summaryValue}
                        summaryKey={summaryKey}
                        sectionTitle={sectionTitle}
                        content={summaryValue}
                        disabled={true}
                        handleContentChange={noop}
                      />
                    );
                  })}
              </VStack>
            )}
            <Box>
              {translatedAdditionalNotes && (
                <SummarySectionMarkdown
                  className="w-full"
                  appointmentId={appointmentId}
                  key={'AdditionalNotes' + translatedAdditionalNotes}
                  summaryKey={`AdditionalNotes` as any}
                  sectionTitle="Additional Notes"
                  content={translatedAdditionalNotes}
                  disabled={true}
                  handleContentChange={noop}
                />
              )}
            </Box>
          </VStack>
        )}
      </HStack>
    </VStack>
  );
};
