import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { produce } from 'immer';
import { v4 } from 'uuid';

import { cacheUtils } from '@eluve/apollo-client';
import { MedicalCode } from '@eluve/blocks';
import { H4, HStack, Icon, VStack, textStyles, toast } from '@eluve/components';
import { useAppointmentActivity } from '@eluve/frontend-appointment-hooks';
import { AppointmentBillingCodeRecommendationsBoolExp } from '@eluve/graphql-types';
import { FragmentOf } from '@eluve/graphql.tada';
import { FeatureFlaggedComponent } from '@eluve/smart-blocks';

import { AppointmentBillingCodePrices } from './AppointmentBillingCodePrices';
import { updateAppointmentBillingCodePriceMutation } from './AppointmentBillingCodePrices.operations';
import {
  acceptBillingCodeRecommendationMutation,
  addAppointmentBillingCodeMutation,
  appointmentBillingCodeRecommendationsFragment,
  appointmentBillingCodesFragment,
  getAppointmentBillingCodePrice,
  getAppointmentBillingCodesQuery,
  medicalCodeFragment,
  removeBillingCodeMutation,
} from './AppointmentBillingCodes.operations';
import {
  BillingCodeOption,
  MedicalCodeSource,
  SearchableBillingCodes,
} from './SearchableBillingCodes';

type MedicalCode = Omit<FragmentOf<typeof medicalCodeFragment>, '__typename'>;

export interface AppointmentBillingCodesProps {
  tenantId: string;
  appointmentId: string;
  isReadonly?: boolean;
  locationId: string;
}

export const AppointmentBillingCodes: React.FC<
  AppointmentBillingCodesProps
> = ({ tenantId, appointmentId, isReadonly = false, locationId }) => {
  const { appointmentLlmJobs } = useAppointmentActivity();

  const { jobStatus } =
    appointmentLlmJobs.find(
      (job) => job.llmOutputType === 'BILLING_CODE_RECOMMENDATION',
    ) ?? {};

  const { data } = useQuery(getAppointmentBillingCodesQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
    skip: jobStatus !== 'COMPLETED',
  });

  const billingCodes = data?.appointmentsByPk?.billingCodes ?? [];
  const recommendations =
    data?.appointmentsByPk?.billing_code_recommendations ?? [];

  const medicalCodesSource: MedicalCodeSource = data?.tenantsByPk
    ?.tenantMedicalCodesExist
    ? 'tenant_medical_codes'
    : 'medical_codes';

  const codeRecommendationsToShow =
    recommendations.filter(
      (c) =>
        !c.isFinal &&
        (Boolean(c.medical_code) || Boolean(c.tenant_medical_code)),
    ) ?? [];

  const [getBillingCodePrice] = useLazyQuery(getAppointmentBillingCodePrice);

  const [updateAppointmentBillingCodePrice] = useMutation(
    updateAppointmentBillingCodePriceMutation,
  );

  const setInheritedBillingCodePrice = async (
    appointmentBillingCodeId: string,
    billingCodeId: string,
    type: BillingCodeOption['codeType'],
  ) => {
    if (type !== 'CPT') {
      return;
    }

    const result = await getBillingCodePrice({
      variables: { billingCodeId, locationId },
    });
    const price =
      result.data?.inheritedBillingCodeLocationPrices[0]?.price ?? 0;

    updateAppointmentBillingCodePrice({
      variables: {
        billingCodeId: appointmentBillingCodeId,
        price,
      },
      onError: () => toast.error('We could not set the price.'),
      optimisticResponse: ({ billingCodeId: id, price }) => {
        return {
          updateAppointmentBillingCodesByPk: {
            __typename: 'AppointmentBillingCodes' as const,
            id,
            price,
          },
        };
      },
    });
  };

  const [acceptRecommendation] = useMutation(
    acceptBillingCodeRecommendationMutation,
    {
      onError: () => toast.error('Failed to accept recommendation'),
      optimisticResponse: ({ appointmentId, billingCodeId, tenantCodeId }) => ({
        insertAppointmentBillingCodesOne: {
          __typename: 'AppointmentBillingCodes' as const,
          id: v4(),
        },
        updateAppointmentBillingCodeRecommendations: {
          returning: [
            {
              __typename: 'AppointmentBillingCodeRecommendations' as const,
              id: v4(),
              appointmentId,
              codeId: billingCodeId ?? null,
              tenantCodeId: tenantCodeId ?? null,
              isFinal: true,
            },
          ],
        },
      }),
    },
  );

  const [removeBillingCode] = useMutation(removeBillingCodeMutation, {
    onError: () => toast.error('Failed to remove billing code'),
  });

  const [addBillingCode] = useMutation(addAppointmentBillingCodeMutation, {
    onError: () => toast.error('Failed to add billing code'),
    optimisticResponse: () => ({
      insertAppointmentBillingCodesOne: {
        __typename: 'AppointmentBillingCodes' as const,
        id: v4(),
      },
    }),
  });

  const onRemoveBillingCode = async ({
    medicalCode,
    tenantMedicalCode,
  }: {
    medicalCode?: MedicalCode;
    tenantMedicalCode?: MedicalCode;
  }) => {
    if (isReadonly) {
      return;
    }
    const billingCode = billingCodes.find((billingCode) => {
      return (
        (medicalCode?.id && billingCode.medical_code?.id === medicalCode.id) ||
        (tenantMedicalCode?.id &&
          billingCode.tenant_medical_code?.id === tenantMedicalCode.id)
      );
    });
    if (!billingCode) {
      toast.error('Could not find the billing code to remove');
      return;
    }

    const billingCodeRecommendationIdToUpdate = recommendations.find((code) =>
      medicalCode
        ? code.medical_code?.id === medicalCode.id
        : tenantMedicalCode
          ? code.tenant_medical_code?.id === tenantMedicalCode.id
          : false,
    )?.id;

    await removeBillingCode({
      variables: {
        appointmentBillingCodeId: billingCode.id,
        appointmentBillingCodeRecommendationId:
          billingCodeRecommendationIdToUpdate ?? null,
        skipUpdate: !billingCodeRecommendationIdToUpdate,
      },
      optimisticResponse: () => {
        return {
          deleteAppointmentBillingCodesByPk: {
            __typename: 'AppointmentBillingCodes' as const,
            id: billingCode.id,
          },
          updateAppointmentBillingCodeRecommendations: {
            returning: billingCodeRecommendationIdToUpdate
              ? [
                  {
                    id: billingCodeRecommendationIdToUpdate,
                    __typename:
                      'AppointmentBillingCodeRecommendations' as const,
                  },
                ]
              : [],
          },
        };
      },
      update: (_cache) => {
        if (billingCodeRecommendationIdToUpdate) {
          cacheUtils.updateFragment(
            {
              fragment: appointmentBillingCodeRecommendationsFragment,
              key: {
                id: appointmentId,
              },
            },
            (existing) => {
              if (!existing) {
                return existing;
              }
              return produce(existing, (draft) => {
                const index = draft.billing_code_recommendations.findIndex(
                  (rec) => rec.id === billingCodeRecommendationIdToUpdate,
                );
                if (index !== -1 && draft.billing_code_recommendations[index]) {
                  draft.billing_code_recommendations[index].isFinal = false;
                }
              });
            },
          );
        }

        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            if (!existing) {
              return existing;
            }
            // Optimistically remove the billing code from the list
            return produce(existing, (draft) => {
              if (medicalCode) {
                const idx = draft.billingCodes.findIndex(
                  (c) => c.medical_code?.id === medicalCode.id,
                );
                if (idx !== -1) {
                  draft.billingCodes.splice(idx, 1);
                }
              }
              if (tenantMedicalCode) {
                const idx = draft.billingCodes.findIndex(
                  (c) => c.tenant_medical_code?.id === tenantMedicalCode.id,
                );
                if (idx !== -1) {
                  draft.billingCodes.splice(idx, 1);
                }
              }
            });
          },
        );
      },
    });
  };

  const onAddBillingCode = async ({
    medicalCode,
    tenantMedicalCode,
  }: {
    medicalCode?: MedicalCode;
    tenantMedicalCode?: MedicalCode;
  }) => {
    if (isReadonly) {
      return;
    }

    await addBillingCode({
      variables: {
        appointmentId,
        billingCodeId: medicalCode?.id ?? null,
        tenantCodeId: tenantMedicalCode?.id ?? null,
        price: null,
        quantity: 1,
      },
      onCompleted(data) {
        const id = data.insertAppointmentBillingCodesOne?.id;
        if (!id) {
          return;
        }
        if (medicalCode) {
          setInheritedBillingCodePrice(
            id,
            medicalCode.id,
            medicalCode.codeType,
          );
        }
      },
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            const updatedId = data?.insertAppointmentBillingCodesOne?.id;
            if (!existing || !updatedId) {
              return existing;
            }

            // Optimistically add the billing code to the list of accepted billing codes
            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: updatedId,
                price: null,
                quantity: 1,
                linked_codes: [],
                medical_code: medicalCode
                  ? {
                      __typename: 'MedicalCodes',
                      ...medicalCode,
                    }
                  : null,
                tenant_medical_code: tenantMedicalCode
                  ? {
                      __typename: 'TenantMedicalCodes',
                      ...tenantMedicalCode,
                    }
                  : null,
              });
            });
          },
        );
      },
    });
  };

  const onAcceptRecommendation = async ({
    medicalCode,
    tenantMedicalCode,
  }: {
    medicalCode?: MedicalCode;
    tenantMedicalCode?: MedicalCode;
  }) => {
    if (isReadonly) {
      return;
    }
    const where: AppointmentBillingCodeRecommendationsBoolExp = {
      appointmentId: { _eq: appointmentId },
      _or: [],
    };

    if (medicalCode) {
      where._or!.push({ codeId: { _eq: medicalCode.id } });
    }
    if (tenantMedicalCode) {
      where._or!.push({ tenantCodeId: { _eq: tenantMedicalCode.id } });
    }

    await acceptRecommendation({
      variables: {
        appointmentId,
        billingCodeId: medicalCode?.id,
        tenantCodeId: tenantMedicalCode?.id,
        price: null,
        quantity: 1,
        where,
      },
      onCompleted(data) {
        const id = data.insertAppointmentBillingCodesOne?.id;
        if (!id) {
          return;
        }
        if (medicalCode) {
          setInheritedBillingCodePrice(
            id,
            medicalCode.id,
            medicalCode.codeType,
          );
        }
      },
      update: (_cache, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: appointmentBillingCodesFragment,
            key: {
              id: appointmentId,
            },
          },
          (existing) => {
            const updatedId = data?.insertAppointmentBillingCodesOne?.id;
            if (!existing || !updatedId) {
              return existing;
            }

            return produce(existing, (draft) => {
              draft.billingCodes.push({
                __typename: 'AppointmentBillingCodes',
                id: updatedId,
                price: null,
                quantity: 1,
                linked_codes: [],
                medical_code: medicalCode
                  ? {
                      __typename: 'MedicalCodes',
                      ...medicalCode,
                    }
                  : null,
                tenant_medical_code: tenantMedicalCode
                  ? { __typename: 'TenantMedicalCodes', ...tenantMedicalCode }
                  : null,
              });
            });
          },
        );
      },
    });
  };

  if (isReadonly && billingCodes.length === 0) {
    return null;
  }

  return (
    <VStack gap={4} className="mb-10">
      <HStack gap={3}>
        <H4>Billing Codes</H4>
      </HStack>
      <VStack gap={6}>
        <HStack>
          <SearchableBillingCodes
            codeTypes={['CPT', 'ICD_10']}
            disabled={isReadonly}
            onCodeAdded={(code) =>
              onAddBillingCode({
                medicalCode: code.source === 'medical_codes' ? code : undefined,
                tenantMedicalCode:
                  code.source === 'tenant_medical_codes' ? code : undefined,
              })
            }
            onCodeRemoved={(code) =>
              onRemoveBillingCode({
                medicalCode: code.source === 'medical_codes' ? code : undefined,
                tenantMedicalCode:
                  code.source === 'tenant_medical_codes' ? code : undefined,
              })
            }
            selectedCodes={
              billingCodes
                .map((c) => {
                  const code = c.tenant_medical_code ?? c.medical_code;
                  return {
                    ...code,
                    source: c.tenant_medical_code
                      ? 'tenant_medical_codes'
                      : 'medical_codes',
                  };
                })
                .filter(Boolean) as BillingCodeOption[]
            }
            tenantId={tenantId}
            medicalCodeSource={medicalCodesSource}
          />
        </HStack>
        {Boolean(codeRecommendationsToShow.length && !isReadonly) && (
          <VStack gap={3}>
            <h6
              className={textStyles.body({
                weight: 'semibold',
                size: 's',
                className: 'uppercase text-brandGray600',
              })}
            >
              Recommended Codes
            </h6>
            <HStack gap={3} wrap>
              {codeRecommendationsToShow.map(
                ({ medical_code, tenant_medical_code }) => {
                  const {
                    id: codeId,
                    code,
                    codeType,
                    description,
                  } = (tenant_medical_code ?? medical_code)!;
                  return (
                    <MedicalCode
                      onClick={() => {
                        onAcceptRecommendation({
                          tenantMedicalCode: tenant_medical_code ?? undefined,
                          medicalCode: medical_code ?? undefined,
                        });
                      }}
                      startAdornment={
                        <div className="pl-1">
                          <Icon
                            size="xs"
                            name="Plus"
                            className="text-brandGray600"
                          />
                        </div>
                      }
                      key={codeId}
                      code={code}
                      codeType={codeType}
                      description={description ?? ''}
                      type="outlineDashed"
                    />
                  );
                },
              )}
            </HStack>
          </VStack>
        )}
        <FeatureFlaggedComponent flag="BILLING_CODE_PRICES">
          <AppointmentBillingCodePrices
            appointmentId={appointmentId}
            isReadonly={isReadonly}
            tenantId={tenantId}
          />
        </FeatureFlaggedComponent>
      </VStack>
    </VStack>
  );
};
