import { createStoreWithProducer } from '@xstate/store';
import { useSelector } from '@xstate/store/react';
import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';
import ReactJson from 'react-json-view';
import { VariantProps } from 'tailwind-variants';
import { match } from 'ts-pattern';

import { AudioPlayerHandle } from '@eluve/audio-player';
import {
  TooltipLabel,
  TranscriptText,
  TranscriptTimestampDivider,
} from '@eluve/blocks';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  FCC,
  HStack,
  NewButton,
  P,
  VStack,
  textStyles,
  tv,
} from '@eluve/components';
import { FragmentOf } from '@eluve/graphql.tada';
import { EluveAdminOnly } from '@eluve/smart-blocks';
import { toSentences } from '@eluve/utils';

import { SegmentAudioControls } from './SegmentAudioControls';
import { appointmentFilesFragment, segmentFragment } from './operations';
import { useSegementAudioData } from './useTranscriptAudioAnalysisData';

const transcriptConfidenceStyles = tv({
  base: '',
  variants: {
    confidence: {
      10: 'opacity-100',
      9: 'opacity-90',
      8: 'opacity-80',
      7: 'opacity-70',
      6: 'opacity-60',
      5: 'opacity-50',
      4: 'opacity-40',
      3: 'opacity-30',
      2: 'opacity-20',
    },
  },
});

type TranscriptConfidenceStyleVariantProps = VariantProps<
  typeof transcriptConfidenceStyles
>;

type ProcessedTranscriptParagraph = {
  sentences: {
    words: {
      word: string;
      punctuated_word?: string;
      start: number;
      end: number;
      confidence: number;
    }[];
  }[];
};

type SegmentAudioContext = {
  audioUrl: string | null;
  segmentId: string;
  transcript: string | null;
  startedAt: string;
  pausedAt?: string;
  paragraphs: ProcessedTranscriptParagraph[];
  seekTo?: number;
};

const createSegmentStore = () => {
  return createStoreWithProducer(produce, defaultContext, {
    updateTimestamp: (ctx, evt: { timestamp: number }) => {
      ctx.currentTimestamp = evt.timestamp;
    },
  });
};

type SegmentStore = ReturnType<typeof createSegmentStore>;

type SegementAudioContextValue = {
  store: SegmentStore;
  audioPlayerHandle: React.RefObject<AudioPlayerHandle>;
};

const SegmentAudioContext =
  React.createContext<SegementAudioContextValue | null>(null);

export const useSegmentAudioContext = () => {
  const context = React.useContext(SegmentAudioContext);
  if (context === null) {
    throw new Error(
      'useSegmentAudioContext must be used within a SegmentAudioContextProvider',
    );
  }
  return context;
};

type SegmentAudioContextProviderProps = {
  index: number;
  tenantId: string;
  appointmentId: string;
  seg: FragmentOf<typeof segmentFragment>;
  appointmentFiles: FragmentOf<
    typeof appointmentFilesFragment
  >['appointmentFiles'];
};

const defaultContext = {
  currentTimestamp: 0,
};

const TranscriptWord: React.FC<{
  className?: string;
  showConfidenceStyles: boolean;
  w: {
    confidence: number;
    punctuated_word?: string;
    word: string;
    start: number;
    end: number;
  };
}> = ({ w, showConfidenceStyles, className }) => {
  const { audioPlayerHandle } = useSegmentAudioContext();

  return (
    <TooltipLabel
      label={`Word: "${w.punctuated_word ?? w.word}", Confidence: ${w.confidence}`}
    >
      <span
        onClick={() => {
          audioPlayerHandle?.current?.seekTo(w.start);
        }}
        className={textStyles.body({
          className: [
            className,
            'inline-flex cursor-pointer hover:underline',
            showConfidenceStyles
              ? transcriptConfidenceStyles({
                  confidence: Math.floor(
                    w.confidence * 10,
                  ) as TranscriptConfidenceStyleVariantProps['confidence'],
                })
              : '',
          ],
        })}
      >
        {w.punctuated_word ?? w.word}
      </span>
    </TooltipLabel>
  );
};

const MemoizedTranscriptWord = React.memo(TranscriptWord, isEqual);

export const SegmentAudioContextProvider: FCC<
  SegmentAudioContextProviderProps
> = ({ appointmentFiles, appointmentId, seg, tenantId, index }) => {
  const showConfidenceStyles = true;

  const store = useMemo(() => createSegmentStore(), []);
  const audioPlayerRef = useRef<AudioPlayerHandle>(null);

  const value: SegementAudioContextValue = useMemo(
    () => ({
      store,
      audioPlayerHandle: audioPlayerRef,
    }),
    [store],
  );

  const timestamp = useSelector(
    store,
    (state) => state.context.currentTimestamp,
  );

  const { isLoading, context: ctx } = useSegementAudioData({
    appointmentFiles,
    appointmentId,
    seg,
    tenantId,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <SegmentAudioContext.Provider value={value}>
      <VStack className="sticky top-0 z-10 bg-white">
        <TranscriptTimestampDivider
          timestamp={ctx.startedAt}
          action={index === 0 ? 'started' : 'resumed'}
        />
        {ctx.audioUrl && <SegmentAudioControls audioUrl={ctx.audioUrl} />}
        <EluveAdminOnly>
          {!isEmpty(ctx.rawMetadata) && (
            <Dialog>
              <DialogTrigger asChild>
                <NewButton type="outlineFilled" text="Raw Metadata" />
              </DialogTrigger>
              <DialogContent className="max-w-[90vw]">
                <VStack className="max-h-[600px] w-full overflow-scroll">
                  <ReactJson enableClipboard={false} src={ctx.rawMetadata} />
                </VStack>
              </DialogContent>
            </Dialog>
          )}
        </EluveAdminOnly>
      </VStack>
      <VStack className="max-h-[600px] overflow-scroll">
        {match(Boolean(ctx.paragraphs.length))
          .with(true, () => {
            return (
              <>
                {ctx.paragraphs.map((p, pIdx) => {
                  return (
                    <VStack key={pIdx}>
                      <HStack gap={6} align="start">
                        {p.speakerId && (
                          <P
                            className={textStyles.body({
                              size: 'l',
                              className: 'whitespace-nowrap underline',
                            })}
                          >
                            {p.speakerId}
                          </P>
                        )}
                        <VStack>
                          {p.sentences.map((s, sIdx) => (
                            <HStack gap={1} key={sIdx} wrap>
                              {s.words.map((w, wIdx) => (
                                <MemoizedTranscriptWord
                                  key={wIdx}
                                  showConfidenceStyles={showConfidenceStyles}
                                  w={w}
                                  className={
                                    timestamp >= w.start && timestamp <= w.end
                                      ? 'bg-orange text-orangeContrast'
                                      : ''
                                  }
                                />
                              ))}
                            </HStack>
                          ))}
                        </VStack>
                      </HStack>
                    </VStack>
                  );
                })}
              </>
            );
          })
          .otherwise(() => (
            <TranscriptText>{toSentences(ctx.transcript ?? '')}</TranscriptText>
          ))}

        {ctx.pausedAt && (
          <TranscriptTimestampDivider
            timestamp={ctx.pausedAt}
            action="paused"
          />
        )}
      </VStack>
    </SegmentAudioContext.Provider>
  );
};
