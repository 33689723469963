import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Pencil } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  H2,
  H3,
  Textarea,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useNamedLogger } from '@eluve/logger';

import { sessionTypeFragment } from './session-type.operations';

const updateSessionTypeMutation = graphql(`
  mutation updateSessionType(
    $tenantId: uuid!
    $id: uuid!
    $context: String
    $isInitialVisitType: Boolean!
  ) {
    updateSessionTypesByPk(
      pkColumns: { tenantId: $tenantId, id: $id }
      _set: { context: $context, isInitialVisitType: $isInitialVisitType }
    ) {
      __typename
      id
      context
      isInitialVisitType
    }
  }
`);

const formSchema = z.object({
  context: z.string().optional(),
  isInitialVisitType: z.boolean().optional(),
});

type Form = z.infer<typeof formSchema>;

export interface SessionTypesActionCellProps {
  tenantId: string;
  id: string;
}

export const SessionTypesActionCell: React.FC<SessionTypesActionCellProps> = ({
  tenantId,
  id,
}) => {
  const logger = useNamedLogger('SessionTypesActionCell');
  const [isOpen, setIsOpen] = useState(false);

  const [updateSessionType] = useMutation(updateSessionTypeMutation, {
    onCompleted: () => {
      toast.success('Updated session type');
      setIsOpen(false);
    },
    onError: () => toast.error('Failed to update session type'),
    optimisticResponse: ({ context, isInitialVisitType }) => ({
      updateSessionTypesByPk: {
        __typename: 'SessionTypes' as const,
        id,
        isInitialVisitType,
        context: context ?? null,
      },
    }),
  });

  const onSubmit = async ({ context, isInitialVisitType = false }: Form) => {
    await updateSessionType({
      variables: {
        tenantId,
        id,
        context: context ?? null,
        isInitialVisitType,
      },
    });
  };

  const sessionType = useCompleteFragment({
    fragment: sessionTypeFragment,
    key: {
      id,
    },
  });

  const form = useForm<Form>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      context: sessionType.context ?? undefined,
      isInitialVisitType: sessionType.isInitialVisitType ?? false,
    },
  });

  return (
    <>
      <Box hStack>
        <TooltipLabel label="Edit this Session Type">
          <Button size="smallIcon" variant="outline">
            <Pencil className="size-5" onClick={() => setIsOpen(true)} />
          </Button>
        </TooltipLabel>
      </Box>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Form {...form}>
          <DialogContent onInteractOutside={(e) => e.preventDefault()}>
            <H2>Update Session Type</H2>
            <H3>{sessionType.name}</H3>
            <form
              onSubmit={form.handleSubmit(onSubmit, (invalid) =>
                logger.debug(
                  `Invalid form submission attempted: ${JSON.stringify(invalid)}`,
                ),
              )}
              className="flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="isInitialVisitType"
                render={({ field }) => (
                  <FormItem className="flex items-center gap-2 space-y-0">
                    <FormLabel>Is Initial Visit Type</FormLabel>
                    <FormControl>
                      <Checkbox
                        className="m-0"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="context"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="max-w-lg">
                    <FormLabel>Context</FormLabel>
                    <FormControl>
                      <Textarea
                        className="bg-white/5"
                        placeholder="Description"
                        {...field}
                      />
                    </FormControl>
                    {error && (
                      <FormMessage className="mt-4">
                        {error.message}
                      </FormMessage>
                    )}
                  </FormItem>
                )}
              />
              <DialogFooter>
                <Button variant={'outline'} onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!form.formState.isDirty}>
                  Save Changes
                </Button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Form>
      </Dialog>
    </>
  );
};
