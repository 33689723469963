import { useLazyQuery } from '@apollo/client';
import { usePrevious } from 'react-use';

import { useCompleteFragment } from '@eluve/apollo-client';
import { toast } from '@eluve/components';
import {
  allCompletedSoapNotesFragment,
  getAllSummaries,
  useAppointmentActivity,
  useAppointmentContext,
} from '@eluve/frontend-appointment-hooks';

export const useListenForAnySummaries = () => {
  const { isAnySummarizationInProgress } = useAppointmentActivity();

  const { tenantId, appointmentId } = useAppointmentContext();

  const [getLatest] = useLazyQuery(getAllSummaries, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const updateSummary = async () => {
    await getLatest();
    toast.success('Summaries are ready!', {
      description: 'Your summaries are ready to be reviewed.',
    });
  };

  const allSoapNotes = useCompleteFragment({
    fragment: allCompletedSoapNotesFragment,
    key: { id: appointmentId },
    strict: false,
  });

  const allSummaryCount = allSoapNotes?.appointment_llm_outputs?.length ?? 0;
  const previousSummaryCount = usePrevious(allSummaryCount) ?? 0;

  const wasSummarizationInProgress = usePrevious(
    isAnySummarizationInProgress ?? false,
  );

  if (
    wasSummarizationInProgress &&
    !isAnySummarizationInProgress &&
    allSummaryCount > previousSummaryCount
  ) {
    updateSummary();
  }
};
