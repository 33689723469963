import { type SyncPrerecordedResponse } from '@deepgram/sdk';
import isNumber from 'lodash/isNumber';
import { useEffect, useState } from 'react';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import { FragmentOf } from '@eluve/graphql.tada';

import { appointmentFilesFragment, segmentFragment } from './operations';

const rawMetadataSchema = z.object({
  results: z.object({
    channels: z.array(
      z.object({
        alternatives: z.array(z.any()),
      }),
    ),
  }),
});

type ProcessedTranscriptParagraph = {
  speakerId?: string;
  sentences: {
    words: {
      word: string;
      punctuated_word?: string;
      start: number;
      end: number;
      confidence: number;
    }[];
  }[];
};

type SegmentAudioContext = {
  audioUrl: string | null;
  segmentId: string;
  transcript: string | null;
  startedAt: string;
  pausedAt?: string;
  paragraphs: ProcessedTranscriptParagraph[];
  seekTo?: number;
  rawMetadata?: Record<string, unknown>;
};

export const useSegementAudioData = ({
  tenantId,
  appointmentId,
  seg,
  appointmentFiles,
}: {
  tenantId: string;
  appointmentId: string;
  seg: FragmentOf<typeof segmentFragment>;
  appointmentFiles: FragmentOf<
    typeof appointmentFilesFragment
  >['appointmentFiles'];
}) => {
  const api = useApiClient();
  const [ctx, setCtx] = useState<
    | { isLoading: true; context: null }
    | {
        isLoading: false;
        context: SegmentAudioContext;
      }
  >({ isLoading: true, context: null });

  let audioUrl: string | null = null;

  useEffect(() => {
    let rawMetadata: Record<string, unknown> = {};
    const paragraphs: ProcessedTranscriptParagraph[] = [];
    const processAudio = async () => {
      const file = seg.raw_transcript?.[0]?.user_upload;
      if (file) {
        const fileId = file.id;
        const result = await api.reviewer.getAppointmentFileSignedUrl({
          params: { tenantId, appointmentId, fileId: fileId! },
        });

        const rawMetadataFile = appointmentFiles.find(
          (af) =>
            af.userUpload.fileUrl?.startsWith(file.fileUrl ?? 'missing') &&
            af.fileType === 'RAW_TRANSCRIPT_METADATA',
        );

        if (rawMetadataFile) {
          const metaDataResult = await api.reviewer.getAppointmentFileSignedUrl(
            {
              params: {
                tenantId,
                appointmentId,
                fileId: rawMetadataFile.fileId,
              },
            },
          );

          if (
            metaDataResult.status === 200 &&
            metaDataResult.body.presignedUrl
          ) {
            // We need to download the raw metadata and make the JSON available
            const meta = await fetch(metaDataResult.body.presignedUrl);
            const json = await meta.json();
            // We currently only support the Deepgram metadata format
            const parsed = rawMetadataSchema.safeParse(json);
            if (parsed.success) {
              rawMetadata = json;
              // We want to turn this into a more usable data structure so that we can easily
              // show confidence per word in the transcript while maintaining grouping of paragraphs
              // and sentences
              const prerecordedResponse =
                parsed.data as SyncPrerecordedResponse;

              const alternative =
                prerecordedResponse.results!.channels[0]!.alternatives[0]!;

              const rawParagraphs = alternative.paragraphs?.paragraphs ?? [];
              for (const p of rawParagraphs) {
                const paragraph: ProcessedTranscriptParagraph = {
                  speakerId: isNumber(p.speaker)
                    ? `Speaker ${p.speaker}`
                    : undefined,
                  sentences: p.sentences.map((s) => {
                    return {
                      words: alternative.words.filter(
                        (w) => w.start >= s.start && w.end <= s.end,
                      ),
                    };
                  }),
                };

                paragraphs.push(paragraph);
              }
            }
          }
        }

        if (result.status === 200 && result.body.presignedUrl) {
          audioUrl = result.body.presignedUrl;
        }
      }

      setCtx({
        isLoading: false,
        context: {
          rawMetadata,
          audioUrl,
          segmentId: seg.id,
          transcript: seg.transcript,
          startedAt: seg.recordingStartedAt,
          pausedAt: seg.recordingDuration
            ? new Date(
                new Date(seg.recordingStartedAt).getTime() +
                  seg.recordingDuration,
              ).toISOString()
            : undefined,
          paragraphs,
        },
      });
    };

    processAudio();
  }, []);

  return ctx;
};
