import { useMutation, useSuspenseQuery } from '@apollo/client';
import fromPairs from 'lodash/fromPairs';
import sortBy from 'lodash/sortBy';
import { MapPinned } from 'lucide-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { cacheUtils } from '@eluve/apollo-client';
import { Button, FormItem, Input, P, toast } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import { DepthContainer } from './DepthContainer';
import { TreeNodeContainer } from './TreeNodeContainer';
import {
  Location,
  locationFragment,
  tenantLocationsFragment,
} from './locations.gql';
import { TreeNode, buildTree } from './treeUtils';

export const getTenantLocationsQuery = graphql(
  `
    query getLocationPageData($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantLocations
        layers {
          __typename
          id
          name
          path
          depth
          tenantId
        }
      }
    }
  `,
  [tenantLocationsFragment],
);

// prettier-ignore
export const createLocationMutation = graphql(
  `#graphql
    mutation createLocation($input: LocationsInsertInput!) {
      insertLocationsOne(object: $input) {
        ...Location
      }
    }
  `,
  [locationFragment],
);

const OPTIMISTIC_ID = 'optimistic';

const CreateLocation: React.FC<{
  tenantId: string;
  layerName: string;
  layerId: string;
  parentName: string;
  parentPath: string;
  depth: number;
}> = ({ layerName, parentPath, depth, layerId, parentName, tenantId }) => {
  const [isCreatingLocation, setIsCreatingLocation] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createLocation] = useMutation(createLocationMutation, {
    optimisticResponse: ({ input }) => ({
      insertLocationsOne: {
        __typename: 'Locations' as const,
        name: input.name!,
        depth,
        path: `${input.path!}.${OPTIMISTIC_ID}`,
        id: OPTIMISTIC_ID,
        isRoot: false,
        timezone: '',
        tenantId,
        externalEhrId: null,
        address: '',
        logoUrl: '',
        phoneNumber: '',
        externalId: '',
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: tenantLocationsFragment,
          key: {
            id: tenantId,
          },
        },
        (existing) => {
          if (!existing || !data?.insertLocationsOne) {
            return existing;
          }

          return {
            ...existing,
            locations: [...existing.locations, data.insertLocationsOne],
          };
        },
      );
    },
  });

  const submit = async () => {
    if (!newLocationName) {
      return;
    }
    try {
      setIsSubmitting(true);
      await createLocation({
        variables: {
          input: {
            path: parentPath,
            name: newLocationName,
            layerId,
          },
        },
      });
      setIsCreatingLocation(false);
      setNewLocationName('');
      toast.success(`Location '${newLocationName}' created`);
    } catch (e) {
      toast.error(`Failed to create location '${newLocationName}'`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DepthContainer depth={depth}>
      {!isCreatingLocation && (
        <button
          type="button"
          className="border-gray-300 hover:border-gray-400 relative block w-full rounded-lg border-2 border-dashed px-4 py-2 text-center focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          onClick={() => setIsCreatingLocation(true)}
        >
          <MapPinned className="text-gray-400 mx-auto h-6 w-6" />
          <span className="text-gray-900 mt-2 block text-sm font-semibold">
            Add a new {layerName} in {parentName}
          </span>
        </button>
      )}
      {isCreatingLocation && (
        <div className="w-full p-2">
          <FormItem>
            <Input
              onChange={(e) => setNewLocationName(e.target.value)}
              onKeyUp={(evt) => {
                if (evt.key === 'Enter') {
                  submit();
                }
              }}
              autoFocus
            />
            <div className="flex items-center gap-2">
              <Button onClick={submit} disabled={isSubmitting}>
                Save
              </Button>
              <Button
                variant={'outline'}
                disabled={isSubmitting}
                onClick={() => {
                  setIsCreatingLocation(false);
                  setNewLocationName('');
                }}
              >
                Cancel
              </Button>
            </div>
          </FormItem>
        </div>
      )}
    </DepthContainer>
  );
};

const LocationTree: React.FC<{
  layers: Record<number, { name: string; id: string }>;
  tree: TreeNode<Location>[];
  tenantId: string;
  parent?: Location;
}> = ({ layers, tree, parent, tenantId }) => {
  const childLayer = parent ? layers[parent.depth! + 1] : undefined;

  const showLayersHelper = Boolean(!layers[1] && !parent);

  const sortedTree = sortBy(tree, (t) => t.data.name);

  return (
    <>
      <div className="grid gap-2">
        {sortedTree.map((t) => {
          const { data, children } = t;
          const { depth, name, id } = data;

          return (
            <React.Fragment key={id}>
              <TreeNodeContainer depth={depth!}>
                <Link className="underline" to={id}>
                  {name}
                </Link>
              </TreeNodeContainer>
              <LocationTree
                tree={children ?? []}
                layers={layers}
                parent={data}
                tenantId={tenantId}
              />
            </React.Fragment>
          );
        })}
        {childLayer && (
          <CreateLocation
            tenantId={tenantId}
            depth={parent!.depth! + 1}
            layerName={childLayer.name}
            layerId={childLayer.id}
            parentPath={parent!.path!}
            parentName={parent!.name!}
          />
        )}
      </div>
      {showLayersHelper && (
        <div className="mt-4 grid gap-2">
          <P>
            In order to create new locations you need to configure a Layer
            Hierarchy
          </P>
          <Link to="../layers">
            <Button className="w-fit">Go to Layers Page</Button>
          </Link>
        </div>
      )}
    </>
  );
};

export const LocationsTree: React.FC<{ tenantId: string }> = ({ tenantId }) => {
  const { data } = useSuspenseQuery(getTenantLocationsQuery, {
    variables: {
      tenantId,
    },
  });

  const layers = data.tenantsByPk?.layers ?? [];

  const layerLookup = fromPairs(
    layers.map((l) => [l.depth!, { name: l.name, id: l.id }]),
  );

  const locations = sortBy(data?.tenantsByPk?.locations ?? [], (l) => l.path);

  const treeData = buildTree(locations);

  return (
    <LocationTree tree={treeData} layers={layerLookup} tenantId={tenantId} />
  );
};
