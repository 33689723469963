import { useMutation } from '@apollo/client';
import { LayoutDashboard, Users } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useApiClient } from '@eluve/api-client-provider';
import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import { Box, Button, toast } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useIsEluveAdmin, useUserIdFromSession } from '@eluve/session-helpers';

import { useAddUserToTenant } from '../$tenantId/users/hooks/useAddUserToTenant';

import { DeleteTenantAction } from './DeleteTenantAction';
import { RenameTenantAction } from './RenameTenantAction';
import { getAdminTenantsSummaryQuery } from './query';

const removeUserFromTenantMutation = graphql(`
  mutation removeUserFromTenant($tenantId: uuid!, $userId: uuid!) {
    deleteTenantUsersByPk(tenantId: $tenantId, userId: $userId) {
      tenantId
      userId
    }
  }
`);

export const usersTenantsFragment = graphql(`
  fragment UsersTenants on Users {
    __typename
    id
    tenants {
      __typename
      userId
      tenantId
      role
    }
  }
`);

export interface TenantActionsColumnProps {
  tenantId: string;
}

export const TenantActionsColumn: React.FC<TenantActionsColumnProps> = ({
  tenantId,
}) => {
  const userId = useUserIdFromSession();
  const apiClient = useApiClient();
  const isEluveAdmin = useIsEluveAdmin();
  const addUserToTenant = useAddUserToTenant(tenantId, async () => {
    cacheUtils.updateFragment(
      {
        fragment: usersTenantsFragment,
        key: { id: userId },
      },
      (existing) => {
        if (!existing) return existing;

        return {
          ...existing,
          tenants: [
            ...existing.tenants,
            {
              tenantId,
              __typename: 'TenantUsers' as const,
              userId,
              role: 'tenant-admin',
            },
          ],
        };
      },
    );

    await apiClient.auth.createTenantSession({
      body: {
        tenantId,
      },
    });
  });

  const [removeUser] = useMutation(removeUserFromTenantMutation, {
    refetchQueries: [getAdminTenantsSummaryQuery],
    onCompleted: (data) => {
      toast.success('User removed from tenant');
      // When an eluve admin removes themselves from a tenant, also update their session
      // to remove the tenant context from their JWT immediatley instead of waiting
      // for next login
      if (data?.deleteTenantUsersByPk?.userId === userId) {
        apiClient.auth.createTenantSession();
      }
    },
    onError: () => toast.error('Error removing user from tenant'),
    update: () => {
      cacheUtils.updateFragment(
        {
          fragment: usersTenantsFragment,
          key: { id: userId },
        },
        (existing) => {
          if (!existing) return existing;

          return {
            ...existing,
            tenants: existing.tenants.filter((t) => t.tenantId !== tenantId),
          };
        },
      );
    },
  });

  const usersTenants = useCompleteFragment({
    fragment: usersTenantsFragment,
    key: { id: userId },
    strict: false,
  });

  const isProvisioned = (usersTenants?.tenants ?? []).some(
    (t) => t.tenantId === tenantId,
  );

  const isAdminOfTenant = (usersTenants?.tenants ?? []).some(
    (t) => t.tenantId === tenantId && t.role === 'tenant-admin',
  );

  return (
    <Box hStack>
      {isProvisioned && (
        <>
          <Button
            onClick={() =>
              removeUser({
                variables: {
                  tenantId,
                  userId,
                },
              })
            }
            size="xs"
            variant="destructive"
          >
            Deprovision
          </Button>
          <Link to={`${tenantId}/dash`}>
            <Button variant="outline" size="icon">
              <TooltipLabel label="Dashboard">
                <LayoutDashboard />
              </TooltipLabel>
            </Button>
          </Link>
          <Link to={`${tenantId}/users`}>
            <Button variant="outline" size="icon">
              <TooltipLabel label="Users">
                <Users />
              </TooltipLabel>
            </Button>
          </Link>
          {isAdminOfTenant && <RenameTenantAction tenantId={tenantId} />}
        </>
      )}
      {!isProvisioned && (
        <Button
          size="xs"
          onClick={() =>
            addUserToTenant({
              variables: {
                tenantId,
                userId,
                role: 'tenant-admin',
              },
            })
          }
        >
          Provision
        </Button>
      )}
      {isEluveAdmin && <DeleteTenantAction tenantId={tenantId} />}
    </Box>
  );
};
