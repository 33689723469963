import { CircleFadingArrowUpIcon } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLatest } from 'react-use';

import { usePreventAutoReloadOnNewVersion } from '@eluve/blocks';
import { Box, Button, textStyles, toast } from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';
import { useCaptureEvent, useCaptureEventOnMount } from '@eluve/posthog-react';

type VersionCheckerProps = {
  children: React.ReactNode;
  intervalMinutes?: number;
};

const ReloadForNewVersion: React.FC<{ newAppVersion: string }> = ({
  newAppVersion,
}) => {
  const [reloadingCountdown, setReloadingCountdown] = useState(5);
  const preventAutoReloadOnNewVersion = usePreventAutoReloadOnNewVersion();

  const intervalIdRef = useRef<number | undefined>(undefined);

  const startCountdown = () => {
    if (usePreventAutoReloadOnNewVersion.getState()) {
      return;
    }
    intervalIdRef.current = setInterval(() => {
      setReloadingCountdown((countdown) => countdown - 1);
    }, 1000) as unknown as number;
  };

  const stopCountdown = () => {
    clearInterval(intervalIdRef.current);
  };

  useCaptureEventOnMount('user_viewed_new_version_toast', {
    newAppVersion,
  });

  const captureEvent = useCaptureEvent({
    newAppVersion,
  });

  const reloadForNewVersion = useCallback(() => {
    captureEvent('user_reloaded_for_new_version', undefined, {
      send_instantly: true,
    });

    // Give analytics events a chance to be sent before reloading
    setTimeout(() => {
      window.location.reload();
    }, 50);
  }, [captureEvent]);

  useEffect(() => {
    startCountdown();
    return stopCountdown;
  }, []);

  useEffect(() => {
    if (reloadingCountdown === 0) {
      reloadForNewVersion();
    }
  }, [reloadForNewVersion, reloadingCountdown]);

  return (
    <Box hStack className="gap-4">
      <CircleFadingArrowUpIcon size={32} className="mx-1 text-primary" />
      <Box vStack className="flex">
        <p className={textStyles.body({ size: 'm', weight: 'medium' })}>
          A new version of Eluve is available!
        </p>
        <Box hStack className="mt-1 w-full items-center">
          <Button
            variant="toastprimary"
            size="xs"
            className="h-7"
            onClick={reloadForNewVersion}
            onMouseEnter={stopCountdown}
            onMouseLeave={startCountdown}
          >
            {preventAutoReloadOnNewVersion
              ? 'Reload Now'
              : `Reload in ${reloadingCountdown} sec`}
          </Button>
          <Button
            variant="toastsecondary"
            size="xs"
            onClick={() => {
              captureEvent('user_dismissed_new_version_toast');
              toast.dismiss();
            }}
          >
            Dismiss
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const VersionChecker: React.FC<VersionCheckerProps> = ({
  children,
  intervalMinutes = 5,
}) => {
  const logger = useNamedLogger('VersionChecker');
  const [version, setVersion] = useState<string | null>(null);
  const [toastOpen, setToastOpen] = useState<boolean>(false);

  const toastOpenRef = useLatest(toastOpen);
  const versionRef = useLatest(version);

  useEffect(() => {
    const checkVersion = async () => {
      const currentVersion = versionRef.current;
      const currentToastOpen = toastOpenRef.current;
      try {
        const headers = new Headers();
        if (currentVersion) {
          headers.append('If-None-Match', currentVersion);
        }
        const response = await fetch(window.location.href, {
          method: 'HEAD',
          headers,
        });

        const newVersion = response.headers.get('ETag');
        if (!newVersion) {
          return;
        }
        if (!currentVersion) {
          setVersion(newVersion);
          return;
        }
        if (newVersion !== currentVersion && !currentToastOpen) {
          logger.info(
            'Prompting user to reload the page to get the latest updates',
            { newVersion, currentVersion },
          );
          setToastOpen(true);
        }
      } catch (error) {
        logger.warn('Error checking version:', {
          error: (error as Error)?.message,
        });
      }
    };
    const intervalId = setInterval(checkVersion, intervalMinutes * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [
    versionRef,
    setVersion,
    setToastOpen,
    intervalMinutes,
    logger,
    toastOpenRef,
  ]);

  useEffect(() => {
    if (!toastOpen) {
      return;
    }
    toast(<ReloadForNewVersion newAppVersion={version ?? ''} />, {
      position: 'bottom-right',
      duration: Infinity,
      onDismiss: () => {
        setToastOpen(false);
      },
    });
  }, [toastOpen, setToastOpen, version]);

  return children;
};
