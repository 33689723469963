import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@eluve/components';
import { useTenantName } from '@eluve/smart-blocks';

export const TenantBreadCrumbs: React.FC<{
  tenantId: string;
  appointmentId?: string;
}> = ({ tenantId, appointmentId }) => {
  const location = useLocation();

  const isTenantRoute = location.pathname.endsWith(tenantId);
  const tenantName = useTenantName(tenantId);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {!isTenantRoute && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink
                text={tenantName}
                to={`/admin/tenant/${tenantId}`}
              />
            </BreadcrumbItem>
          </>
        )}
        {Boolean(appointmentId) && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink
                text="Appointments"
                to={`/admin/tenant/${tenantId}/appointments`}
              />
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
