import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import 'supertokens-auth-react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';

import {
  BrowserSessionProvider,
  apolloClientFactory,
  defaultCache,
  sessionAccessTokenProvider,
} from '@eluve/apollo-client';
import { PatientDetailsPage, PatientsListPage } from '@eluve/feature-patients';
import {
  AppointmentDetailsOutput,
  AppointmentDetailsPage,
  AppointmentPdfPage,
} from '@eluve/frontend-feature-appointment';
import { EHRSyncStoreProvider } from '@eluve/frontend-feature-ehr-sync';
import { CreatePromptTemplatePage } from '@eluve/frontend-feature-prompt-templates';
import { tenantAdminRoutes } from '@eluve/frontend-feature-tenants';
import { createReviewRoutes } from '@eluve/frontend-review-dash';
import { EluveAdminOnly, FeatureFlaggedPage } from '@eluve/smart-blocks';
import { AppointmentFilesList } from '@eluve/user-local-files';
import { AppointmentTasksMachineProvider } from '@eluve/user-local-files';

import { AnalyticsProvider } from './AnalyticsProvider';
import { createAdminRoutes } from './admin-routes';
import { appConfig } from './config';
import { ErrorPage } from './routes/ErrorPage';
import { LoginCallbackPage } from './routes/LoginCallbackPage';
import { LoginPage } from './routes/LoginPage';
import { MagicLinkVerifyPage } from './routes/MagicLinkVerifyPage';
import { NotFoundPage } from './routes/NotFoundPage';
import { SsoPage } from './routes/SsoPage';
import { WaitListPage } from './routes/WaitlistPage';
import { AppIndexPage } from './routes/app/AppIndexPage';
import { AppLayout } from './routes/app/AppLayout';
import { SingularEntityRedirect } from './routes/app/SingularEntityRedirect';
import { UserContextProvider } from './routes/app/UserContextProvider';
import { VersionChecker } from './routes/app/VersionChecker';
import { createEvalRoutes } from './routes/app/eval/eval-routes';
import { PromptsGuard } from './routes/app/prompts/PromptsGuard';
import { SettingsPage } from './routes/app/settings/SettingsPage';
import { HomePage } from './routes/app/tenant/$tenantId/HomePage';
import { PeriodicFileSyncProviderBridge } from './routes/app/tenant/$tenantId/PeriodicFileSyncProviderBridge';
import { ServerAppointmentsTablePage } from './routes/app/tenant/$tenantId/ServerAppointmentsTablePage';
import { TenantAdminGuard } from './routes/app/tenant/$tenantId/TenantAdminGuard';
import { TenantGuard } from './routes/app/tenant/$tenantId/TenantGuard';
import { TenantLayout } from './routes/app/tenant/$tenantId/TenantLayout';
import { TenantProvider } from './routes/app/tenant/$tenantId/TenantProvider';
import { TenantSettingsMainLayout } from './routes/app/tenant/$tenantId/TenantSettingsMainLayout';
import { AppointmentPage } from './routes/app/tenant/$tenantId/appointments/Appointment.Page';
import { AppointmentsListPage } from './routes/app/tenant/$tenantId/appointments/AppointmentsListPage';
import { AppointmentsMainLayout } from './routes/app/tenant/$tenantId/appointments/AppointmentsMainLayout';
import { SummaryComparisonPage } from './routes/app/tenant/$tenantId/appointments/SummaryComparison.Page';
import { TranscriptionMachineProvider } from './routes/app/tenant/$tenantId/appointments/machines/transcription/TranscriptionMachineProvider';
import { TranscriptionWidget } from './routes/app/tenant/$tenantId/appointments/machines/transcription/components/TranscriptionWidget';
import { TenantOutputTemplateDetailsPage } from './routes/app/tenant/$tenantId/output-templates/TenantOutputTemplateDetailsPage';
import { TenantOutputTemplatesListPage } from './routes/app/tenant/$tenantId/output-templates/TenantOutputTemplatesListPage';
import { TenantUserSettingsPage } from './routes/app/tenant/$tenantId/settings/TenantUserSettingsPage';
import { PendingApprovalPage } from './routes/app/tenant/PendingApprovalPage';
import { TenantsPage } from './routes/app/tenant/TenantsPage';
import { TenantsHeader } from './routes/app/tenant/components/TenantsHeader';

const rootApolloClient = apolloClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  cacheInstance: defaultCache,
  accessTokenProvider: sessionAccessTokenProvider(new BrowserSessionProvider()),
});

const evalApolloClient = apolloClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  desiredRole: 'eval-user',
  cacheInstance: defaultCache,
  accessTokenProvider: sessionAccessTokenProvider(new BrowserSessionProvider()),
});

const reviewerApolloClient = apolloClientFactory({
  uri: appConfig.VITE_APOLLO_CLIENT_URI,
  desiredRole: 'reviewer',
  cacheInstance: defaultCache,
  accessTokenProvider: sessionAccessTokenProvider(new BrowserSessionProvider()),
});

export const reactRouter = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/login/callback/*',
    element: <LoginCallbackPage />,
  },
  {
    path: '/sso',
    element: <SsoPage />,
  },
  {
    path: '/waitlist',
    element: <WaitListPage />,
  },
  {
    path: '/auth/verify',
    element: <MagicLinkVerifyPage />,
  },
  {
    path: '/',
    element: (
      <ErrorBoundary
        showDialog={true}
        fallback={({ resetError }) => <ErrorPage reset={resetError} />}
      >
        <SessionAuth>
          <AnalyticsProvider>
            <UserContextProvider>
              <VersionChecker intervalMinutes={5}>
                <ApolloProvider client={rootApolloClient}>
                  <AppLayout />
                </ApolloProvider>
              </VersionChecker>
            </UserContextProvider>
          </AnalyticsProvider>
        </SessionAuth>
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <AppIndexPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'tenant/*',
        element: <SingularEntityRedirect entityName="tenant" />,
      },
      {
        path: 'tenants',
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <TenantsPage />,
          },
          {
            path: 'pending-approval',
            element: <PendingApprovalPage />,
          },
          {
            path: ':tenantId',
            element: (
              <TenantGuard>
                <Suspense
                  fallback={
                    <div className="grid h-screen place-content-center">
                      Loading...
                    </div>
                  }
                >
                  <EHRSyncStoreProvider
                    initializer={(set) => ({
                      lastSuccessImportCompletedAt: undefined,
                      isDataImporting: undefined,
                      setIsDataImporting: (isLoading) =>
                        set({ isDataImporting: isLoading }),
                    })}
                  >
                    <TenantProvider>
                      <AppointmentTasksMachineProvider>
                        <TranscriptionMachineProvider>
                          <PeriodicFileSyncProviderBridge />
                          <TenantLayout />
                          <TranscriptionWidget />
                        </TranscriptionMachineProvider>
                      </AppointmentTasksMachineProvider>
                    </TenantProvider>
                  </EHRSyncStoreProvider>
                </Suspense>
              </TenantGuard>
            ),
            children: [
              {
                index: true,
                element: <Navigate to="home" replace />,
              },
              {
                path: 'server-appointments',
                element: (
                  <EluveAdminOnly>
                    <ServerAppointmentsTablePage />
                  </EluveAdminOnly>
                ),
              },
              {
                path: 'home',
                element: <HomePage />,
              },
              {
                path: 'settings',
                element: <TenantUserSettingsPage />,
              },
              {
                path: 'admin',
                element: (
                  <TenantAdminGuard>
                    <Helmet>
                      <title>Admin | Eluve</title>
                    </Helmet>
                    <Outlet />
                  </TenantAdminGuard>
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="layers" replace />,
                  },
                  {
                    element: <TenantSettingsMainLayout />,
                    children: tenantAdminRoutes,
                  },
                ],
              },
              {
                path: 'files',
                element: (
                  <FeatureFlaggedPage flag="LOCAL_FILES_MANAGEMENT">
                    <Helmet>
                      <title>Files | Eluve</title>
                    </Helmet>
                    <AppointmentFilesList />
                  </FeatureFlaggedPage>
                ),
              },
              {
                path: 'patient/*',
                element: <SingularEntityRedirect entityName="patient" />,
              },
              {
                path: 'appointments',
                element: (
                  <FeatureFlaggedPage flag="APPOINTMENTS_VIEW">
                    <Helmet>
                      <title>Appointments | Eluve</title>
                    </Helmet>
                    <AppointmentsListPage />
                  </FeatureFlaggedPage>
                ),
              },
              {
                path: 'patients',
                element: (
                  <FeatureFlaggedPage flag="PATIENTS_VIEW">
                    <PatientsListPage />
                  </FeatureFlaggedPage>
                ),
              },
              {
                path: 'patients/:patientId',
                element: (
                  <FeatureFlaggedPage flag="PATIENTS_VIEW">
                    <PatientDetailsPage />
                  </FeatureFlaggedPage>
                ),
              },
              {
                path: 'output-templates',
                element: (
                  <EluveAdminOnly>
                    <TenantOutputTemplatesListPage />
                  </EluveAdminOnly>
                ),
              },
              {
                path: 'output-templates/:outputTemplateId',
                element: (
                  <EluveAdminOnly>
                    <TenantOutputTemplateDetailsPage />
                  </EluveAdminOnly>
                ),
              },
              {
                path: 'output-templates/:outputTemplateId/variant/:variantNumber',
                element: (
                  <EluveAdminOnly>
                    <TenantOutputTemplateDetailsPage />
                  </EluveAdminOnly>
                ),
              },
              {
                path: 'appointment/*',
                element: <SingularEntityRedirect entityName="appointment" />,
              },
              {
                path: 'appointments/:appointmentId',
                element: <AppointmentsMainLayout />,
                children: [
                  {
                    index: true,
                    element: <AppointmentPage />,
                  },
                  {
                    path: 'comparison',
                    element: <SummaryComparisonPage />,
                  },
                  {
                    path: 'pdf',
                    element: (
                      <FeatureFlaggedPage flag="PDF_OUTPUT">
                        <AppointmentPdfPage />
                      </FeatureFlaggedPage>
                    ),
                  },
                  {
                    path: 'details',
                    element: (
                      <FeatureFlaggedPage flag="LLM_OUTPUT_DETAILS">
                        <AppointmentDetailsPage />
                      </FeatureFlaggedPage>
                    ),
                    children: [
                      {
                        index: true,
                        element: (
                          <div className="text-gray-10">
                            Select Output types
                          </div>
                        ),
                      },
                      {
                        path: ':outputType',
                        element: <AppointmentDetailsOutput />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'prompts',
        element: (
          <Suspense fallback={'Loading...'}>
            <PromptsGuard>
              <Helmet>
                <title>Prompts | Eluve</title>
              </Helmet>
              <div>
                <TenantsHeader />
                <div className="p-8 pt-4">
                  <CreatePromptTemplatePage onDoneNavigateTo="/" />
                </div>
              </div>
            </PromptsGuard>
          </Suspense>
        ),
      },
      ...createAdminRoutes('admin'),
      ...createEvalRoutes('eval'),
      ...createReviewRoutes('review', reviewerApolloClient),
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
