import { useSubscription } from '@apollo/client';
import Markdown from 'react-markdown';

import { useApiClient } from '@eluve/api-client-provider';
import {
  Card,
  CardContent,
  CardHeader,
  NewButton,
  P,
  Skeleton,
  toast,
} from '@eluve/components';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';
import { differentialDiagnosesSchema } from '@eluve/llm-outputs';

// Constants

const outputSubscription = graphql(`
  subscription getAppointmentOutput(
    $appointmentId: uuid!
    $outputType: LlmOutputTypesEnum!
  ) {
    appointmentLlmOutputs(
      where: {
        appointmentId: { _eq: $appointmentId }
        output: {
          modelPromptTemplate: {
            template: { outputType: { _eq: $outputType } }
          }
        }
      }
      limit: 1
      orderBy: { output: { startedAt: DESC } }
    ) {
      __typename
      llmOutputId
      output {
        __typename
        id
        status
        content
      }
    }
  }
`);

// Public Components

export const AppointmentInsights: React.FC = () => {
  const { appointmentId, tenantId } = useAppointmentContext();

  const apiClient = useApiClient();

  const { data, loading } = useSubscription(outputSubscription, {
    variables: {
      appointmentId,
      outputType: 'DIFFERENTIAL_DIAGNOSES',
    },
  });

  const llmOutput = data?.appointmentLlmOutputs?.[0];
  const content = llmOutput?.output?.content;
  const diagnoses = differentialDiagnosesSchema.safeParse(content);
  const hasContent = content != null && diagnoses.success;
  const isLoading = (loading && !data) || (llmOutput && !content);

  const handleClick = async () => {
    try {
      const result = await apiClient.llm.generateAppointmentOutput({
        params: { tenantId, appointmentId },
        body: { outputType: 'DIFFERENTIAL_DIAGNOSES' },
      });

      if (result.status !== 201) {
        toast.error(
          'We could not generate your insights. Please try again later!',
        );
        return;
      }
    } catch (e) {
      toast.error(
        'We could not generate your insights. Please try again later!',
      );
    }
  };

  return (
    <Card className="my-4 w-full rounded-xl border-gray-4">
      <CardHeader className="flex h-10 flex-row items-center justify-between justify-items-center rounded-t-xl border-b bg-gray-1 p-0 px-5 tracking-normal">
        <P>Eluve Insights</P>
      </CardHeader>
      <hr className="sr-only" />
      <CardContent className="flex flex-col items-start justify-between gap-5 rounded-b-xl bg-white p-5">
        {!hasContent && !isLoading && (
          <>
            <P className="text-contentSecondary">
              Let us know when you are ready to take a look at your insights:
            </P>
            <NewButton
              type="subtle"
              onClick={handleClick}
              text="Run Insights"
            />
          </>
        )}
        {isLoading && <Skeleton className="h-6 self-stretch" />}
        {hasContent && (
          <Markdown className="prose ml-2 [&>ul]:list-disc">
            {'Differential Diagnoses:\n' +
              diagnoses.data
                .map((diagnosis) => `- ${diagnosis.diagnosis}`)
                .join('\n')}
          </Markdown>
        )}
      </CardContent>
    </Card>
  );
};
