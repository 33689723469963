import { useSubscription } from '@apollo/client';
import { Plus } from 'lucide-react';
import React, { useEffect } from 'react';

import { useApiClient } from '@eluve/api-client-provider';
import { Button, Skeleton, toast } from '@eluve/components';
import { LlmOutputTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';

import { GeneratingOutput } from '../GeneratingOutput';

export const NO_OUTPUT_ID = 'no-output-available';

const outputSubscription = graphql(`
  subscription getAppointmentOutput(
    $appointmentId: uuid!
    $outputType: LlmOutputTypesEnum!
  ) {
    appointmentLlmOutputs(
      where: {
        appointmentId: { _eq: $appointmentId }
        output: {
          modelPromptTemplate: {
            template: { outputType: { _eq: $outputType } }
          }
        }
      }
      limit: 1
      orderBy: { output: { startedAt: DESC } }
    ) {
      __typename
      llmOutputId
      output {
        __typename
        id
        status
        content
      }
    }
  }
`);

export interface NoOutputAvailableProps {
  tenantId: string;
  appointmentId: string;
  outputType: LlmOutputTypesEnum;
  refetch: () => Promise<any>;
}

export const NoOutputAvailable: React.FC<NoOutputAvailableProps> = ({
  tenantId,
  appointmentId,
  outputType,
  refetch,
}) => {
  const apiClient = useApiClient();

  const generateOutput = async () => {
    try {
      const result = await apiClient.llm.generateAppointmentOutput({
        params: { tenantId, appointmentId },
        body: { outputType },
      });

      if (result.status === 422) {
        toast.error(result.body.message);
        return;
      }

      if (result.status !== 201) {
        toast.error('Error generating output. Please try again later.');
        return;
      }
    } catch (e) {
      toast.error('Error generating output. Please try again later.');
    }
  };

  const { data, loading } = useSubscription(outputSubscription, {
    variables: {
      appointmentId,
      outputType,
    },
  });

  useEffect(() => {
    if (data?.appointmentLlmOutputs?.[0]?.output?.content) {
      refetch();
    }
  }, [refetch, data]);

  const ele = document.getElementById(NO_OUTPUT_ID);

  const particleColor = ele
    ? `hsl(${getComputedStyle(ele)
        .getPropertyValue('--brand-11')
        .split(' ')
        .map((v, i) => `${parseInt(v)}${i === 0 ? '' : '%'}`)
        .join(', ')})`
    : undefined;

  const llmOutput = data?.appointmentLlmOutputs?.[0];

  if (loading || !data) {
    return (
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
        <Skeleton className="h-4 w-[200px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    );
  }

  if (data?.appointmentLlmOutputs?.length === 0) {
    return (
      <div className="border border-dashed py-4 text-center">
        <h3 className="mt-2 text-sm font-semibold text-contentPrimary">
          No Output Available
        </h3>
        <p className="mt-1 text-sm text-contentSecondary"></p>
        <div className="mt-2">
          <Button onClick={generateOutput}>
            <Plus className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Generate
          </Button>
        </div>
      </div>
    );
  }

  if (llmOutput && !llmOutput.output.content) {
    return <GeneratingOutput particleColor={particleColor} />;
  }
};
