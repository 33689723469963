import React from 'react';
import { Outlet } from 'react-router-dom';

import { TabNav } from '@eluve/smart-blocks';

export interface UserGroupDetailsLayoutProps {}

export const UserGroupDetailsLayout: React.FC<
  UserGroupDetailsLayoutProps
> = () => {
  return (
    <div>
      <TabNav
        tabs={[
          {
            name: 'Users',
            link: 'users',
          },
          {
            name: 'Configs',
            link: 'configs',
          },
        ]}
      />
      <Outlet />
    </div>
  );
};
