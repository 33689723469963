/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { createStoreWithProducer } from '@xstate/store';
import { produce } from 'immer';
import camelCase from 'lodash/camelCase';
import { match } from 'ts-pattern';
import { v6 as uuid } from 'uuid';

import { LlmOutputTypesEnum } from '@eluve/graphql-types';
import {
  Block,
  BlockType,
  CheckboxBlock,
  DynamicArtifactTemplate,
  GroupBlock,
  RangeBlock,
  TextFieldBlock,
} from '@eluve/llm-outputs';

import { AsBuilderBlock, BuilderBlock } from './utilities';

export type BuilderStoreContext = {
  name: string;
  outputType: LlmOutputTypesEnum;
  description: string;
  variantNotes?: string;
  showTips: boolean;
  blocks: BuilderBlock[];
  isReadOnly: boolean;
  checkboxOptions?: string[];
  isImported?: boolean;
};

const defaultContext: BuilderStoreContext = {
  name: '',
  outputType: 'SOAP_NOTE',
  description: '',
  showTips: false,
  blocks: [],
  isReadOnly: false,
  checkboxOptions: undefined,
};

const findBlock = (
  blockId: UniqueIdentifier,
  blocks: BuilderBlock[],
): BuilderBlock | undefined => {
  const found = blocks.find((b) => b.id === blockId);
  if (found) {
    return found;
  }

  // If we didn't find it on the top level, look inside the nested groups
  for (const block of blocks) {
    if (block.type === 'group') {
      const groupBlocks = block.blocks as BuilderBlock[];
      const found = groupBlocks.find((b) => b.id === blockId);
      if (found) {
        return found;
      } else {
        const block = findBlock(blockId, groupBlocks);
        if (block) {
          return block;
        }
      }
    }
  }
};

const findContainingBlocks = (
  blockId: UniqueIdentifier,
  blocks: BuilderBlock[],
): BuilderBlock[] | undefined => {
  const found = blocks.find((b) => b.id === blockId);
  if (found) {
    return blocks;
  }

  for (const block of blocks) {
    if (block.type === 'group') {
      const groupBlocks = block.blocks as BuilderBlock[];
      const found = groupBlocks.find((b) => b.id === blockId);
      if (found) {
        return groupBlocks;
      } else {
        const block = findContainingBlocks(blockId, groupBlocks);
        if (block) {
          return block;
        }
      }
    }
  }
};

const collapse = (blocks: BuilderBlock[], collapsed: boolean) => {
  blocks.forEach((block) => {
    block.collapsed = collapsed;
    if (block.type === 'group') {
      collapse(block.blocks as BuilderBlock[], collapsed);
    }
  });
};

export const templateBuilderStore = createStoreWithProducer(
  produce,
  defaultContext,
  {
    setOutputType: (context, event: { outputType: LlmOutputTypesEnum }) => {
      context.outputType = event.outputType;
    },
    setName: (context, event: { name: string }) => {
      context.name = event.name;
    },
    setDescription: (context, event: { description: string }) => {
      context.description = event.description;
    },
    setVariantNotes: (context, event: { variantNotes: string }) => {
      context.variantNotes = event.variantNotes;
    },
    toggleTips: (context) => {
      context.showTips = !context.showTips;
    },
    setBlocks: (context, event: { blocks: BuilderBlock[] }) => {
      context.blocks = event.blocks;
    },
    toggleCollapseAll: (context, event: { collapsed: boolean }) => {
      collapse(context.blocks, event.collapsed);
    },
    setIsReadOnly: (context, event: { isReadOnly: boolean }) => {
      context.isReadOnly = event.isReadOnly;
    },
    setIsImported: (context, event: { isImported: boolean }) => {
      context.isImported = event.isImported;
    },
    hydrateArtifact: (
      context,
      event: {
        artifactTemplate: DynamicArtifactTemplate;
        outputType: LlmOutputTypesEnum;
        isImported?: boolean;
      },
    ) => {
      const { artifactTemplate, outputType, isImported } = event;
      const { name, description, blocks, variantNotes } = artifactTemplate;
      context.name = name;
      context.outputType = outputType;
      context.description = description ?? '';
      context.variantNotes = variantNotes ?? '';
      context.isImported = Boolean(isImported);

      // We need to recursively hydrate the blocks by giving them ids
      const convertToBuilderBlocks = (inputBlocks: Block[]): BuilderBlock[] => {
        const builderBlocks: BuilderBlock[] = [];
        for (const block of inputBlocks) {
          const { type } = block;
          const builderBlock: BuilderBlock = {
            ...block,
            id: uuid(),
            isValid: true,
          };

          // The type will always be the same here but we need to check it to make TS
          // happy for both
          if (builderBlock.type === 'group' && type === 'group') {
            builderBlock.blocks = convertToBuilderBlocks(block.blocks);
          }

          builderBlocks.push(builderBlock);
        }

        return builderBlocks;
      };

      const hydratedBlocks = convertToBuilderBlocks(blocks);

      context.blocks = hydratedBlocks;
    },
    reset: () => defaultContext,
    toggleCollapse: (
      context,
      event: { id: UniqueIdentifier; collapsed: boolean },
    ) => {
      const { id, collapsed } = event;
      const block = findBlock(id, context.blocks);
      if (block) {
        collapse([block], collapsed);
      }
    },
    moveInsideGroup: (
      context,
      event: {
        parentId: UniqueIdentifier | null;
        activeId: UniqueIdentifier;
        overId: UniqueIdentifier;
      },
    ) => {
      const { parentId, activeId, overId } = event;
      if (parentId === null) {
        const activeIndex = context.blocks.findIndex((b) => b.id === activeId);
        const overIndex = context.blocks.findIndex((b) => b.id === overId);
        context.blocks = arrayMove(context.blocks, activeIndex, overIndex);
      } else {
        // find the parent group block by searching recursively inside the
        // blocks array

        const groupBlock = findBlock(
          parentId,
          context.blocks,
        ) as AsBuilderBlock<GroupBlock>;
        const groupBlocks = groupBlock.blocks as BuilderBlock[];
        const activeIndex = groupBlocks.findIndex((b) => b.id === activeId);
        const overIndex = groupBlocks.findIndex((b) => b.id === overId);

        groupBlock.blocks = arrayMove(
          groupBlock.blocks,
          activeIndex,
          overIndex,
        );
      }
    },
    moveBlockBetweenGroups: (
      context,
      event: {
        from: UniqueIdentifier | null;
        to: {
          id: UniqueIdentifier | null;
          activeId: UniqueIdentifier;
          overId: UniqueIdentifier;
        };
      },
    ) => {
      const { from, to } = event;

      if (from === null) {
        // This is a top level block moving into a nested group
        const activeIndex = context.blocks.findIndex(
          (b) => b.id === to.activeId,
        );

        const moved = context.blocks.splice(activeIndex, 1)[0]!;
        const toGroup = findBlock(to.id!, context.blocks) as GroupBlock;
        const toGroupBlocks = toGroup.blocks as BuilderBlock[];
        const overIndex = toGroupBlocks.findIndex((b) => b.id === to.overId);

        toGroup.blocks.splice(overIndex, 0, moved);
      } else {
        const fromBlock = findBlock(from, context.blocks) as GroupBlock;
        const fromGroupBlocks = fromBlock.blocks as BuilderBlock[];
        const activeIndex = fromGroupBlocks.findIndex(
          (b) => b.id === to.activeId,
        );

        const moved = (fromBlock.blocks as BuilderBlock[]).splice(
          activeIndex,
          1,
        )[0]!;
        const toBlocks =
          to.id === null
            ? context.blocks
            : ((findBlock(to.id, context.blocks) as GroupBlock)
                .blocks as BuilderBlock[]);

        const overIndex = toBlocks.findIndex((b) => b.id === to.overId);
        toBlocks.splice(overIndex, 0, moved);
      }
    },
    setBlockDetails: (
      context,
      event: {
        id: UniqueIdentifier;
        label: string;
        description?: string;
        isRequired?: boolean;
        isAiEnabled?: boolean;
        isEmailEnabled?: boolean;
        checkboxOptions?: string[];
      },
    ) => {
      const { id, label, description, checkboxOptions } = event;
      const block = findBlock(id, context.blocks);
      if (block) {
        block.label = label;
        block.key = camelCase(label);
        block.description = description;
        block.isRequired = event.isRequired ?? false;
        block.isEmailEnabled = event.isEmailEnabled ?? false;
        block.isAiEnabled = event.isAiEnabled ?? true;
        block.isValid = true;

        if (block.type === 'checkbox' && checkboxOptions?.length) {
          block.options = checkboxOptions.map((label) => ({
            label,
            isChecked: false,
          }));
        }
      }
    },
    removeBlock: (context, event: { id: UniqueIdentifier }) => {
      const containingBlocks = findContainingBlocks(event.id, context.blocks);
      if (containingBlocks) {
        const index = containingBlocks.findIndex((b) => b.id === event.id);
        containingBlocks.splice(index, 1);
      }
    },
    addBlock: (
      context,
      event: {
        parentId: UniqueIdentifier | null;
        blockType: BlockType;
        placeAtIndex?: number;
      },
    ) => {
      const { parentId } = event;
      const key = uuid();
      const sharedBlockProps = {
        id: key,
        key,
        isValid: false,
        label: '',
        collapsed: false,
      };

      const newBlock: BuilderBlock = match(event.blockType)
        .with('group', (type) => {
          const group: AsBuilderBlock<GroupBlock> = {
            type,
            ...sharedBlockProps,
            blocks: [],
          };

          return group;
        })
        .with('text', (type) => {
          const text: AsBuilderBlock<TextFieldBlock> = {
            type,
            ...sharedBlockProps,
          };
          return text;
        })
        .with('checkbox', (type) => {
          const checkbox: AsBuilderBlock<CheckboxBlock> = {
            type,
            options: [],
            ...sharedBlockProps,
          };
          return checkbox;
        })
        .with('range', (type) => {
          const range: AsBuilderBlock<RangeBlock> = {
            type,
            min: 0,
            max: 10,
            step: 1,
            ...sharedBlockProps,
          };

          return range;
        })
        .exhaustive();

      const toBlocks =
        parentId === null
          ? context.blocks
          : ((findBlock(parentId, context.blocks) as GroupBlock)
              .blocks as BuilderBlock[]);

      if (event.placeAtIndex !== undefined) {
        toBlocks.splice(event.placeAtIndex, 0, newBlock);
      } else {
        toBlocks.push(newBlock);
      }
    },
  },
);
