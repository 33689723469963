import { z } from 'zod';

import { MatchingRecord } from '@eluve/utils';

import { FeedbackTypeEnum } from './types';

export const FeedbackTypeLookup: MatchingRecord<FeedbackTypeEnum> = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
} as const satisfies MatchingRecord<FeedbackTypeEnum>;

export const FeedbackTypeSchema = z.nativeEnum(FeedbackTypeLookup);
