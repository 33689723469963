import { useSuspenseQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import React, { useMemo } from 'react';

import {
  eluveAdminHasuraContext,
  useCompleteFragment,
} from '@eluve/apollo-client';
import { Switch, VStack, toast } from '@eluve/components';
import { ColDefBuilder, DataTable, H3, P } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

const llmOutputTemplateFragment = graphql(`
  fragment LlmOutputTemplate on LlmOutputTemplates @_unmask {
    __typename
    id
    name
    description
    llmOutputType
    updatedAt
  }
`);

const tenantLlmOutputTemplateFragment = graphql(
  `
    fragment TenantLlmOutputTemplate on TenantLlmOutputTemplates @_unmask {
      __typename
      tenantId
      llmOutputTemplateId
      isEnabled
      updatedAt
      llm_output_template {
        ...LlmOutputTemplate
      }
    }
  `,
  [llmOutputTemplateFragment],
);

const tenantLlmOutputTemplatesFragment = graphql(
  `
    fragment TenantLlmOutputTemplates on Tenants @_unmask {
      id
      __typename
      llmOutputTemplates {
        llmOutputTemplateId
        tenantId
        __typename
        updatedAt
        isEnabled
        llm_output_template {
          ...LlmOutputTemplate
        }
      }
    }
  `,
  [tenantLlmOutputTemplateFragment, llmOutputTemplateFragment],
);

const getLlmOutputTemplatesQuery = graphql(
  `
    query getLlmOutputTemplates($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        __typename
        id
        ...TenantLlmOutputTemplates
      }
    }
  `,
  [tenantLlmOutputTemplatesFragment],
);

const setTenantLlmOutputTemplateMutation = graphql(
  `
    mutation setTenantLlmOutputTemplate(
      $tenantId: uuid!
      $llmOutputTemplateId: uuid!
      $isEnabled: Boolean!
    ) {
      insertTenantLlmOutputTemplatesOne(
        object: {
          tenantId: $tenantId
          llmOutputTemplateId: $llmOutputTemplateId
          isEnabled: $isEnabled
        }
        onConflict: {
          constraint: tenant_llm_output_templates_pkey
          updateColumns: [isEnabled]
        }
      ) {
        ...TenantLlmOutputTemplate
      }
    }
  `,
  [tenantLlmOutputTemplateFragment],
);

type OutputTemplateRow = {
  isEnabled: boolean;
  id: string;
  name: string;
  description: string;
  outputType: string;
  tenantId: string;
  templateUpdatedAt: string;
  accessUpdatedAt: string;
};

const TenantLlmOutputTemplateToggle: React.FC<{
  tenantId: string;
  name: string;
  llmOutputTemplateId: string;
}> = ({ tenantId, name, llmOutputTemplateId }) => {
  const data = useCompleteFragment({
    fragment: tenantLlmOutputTemplateFragment,
    key: {
      llmOutputTemplateId,
      tenantId,
    },
    strict: false,
  });

  const [updateTenantLlmOutputTemplate] = useMutation(
    setTenantLlmOutputTemplateMutation,
    {
      onError: () =>
        toast.error(`Failed to update access to output template ${name}`),
      optimisticResponse: (data) => ({
        insertTenantLlmOutputTemplatesOne: {
          __typename: 'TenantLlmOutputTemplates' as const,
          updatedAt: new Date().toISOString(),
          ...data,
          llm_output_template: {
            __typename: 'LlmOutputTemplates' as const,
            id: llmOutputTemplateId,
            name,
            description: '',
            updatedAt: '',
            llmOutputType: 'SOAP_NOTE' as const,
          },
        },
      }),
    },
  );
  const submit = async (isEnabled: boolean) => {
    await updateTenantLlmOutputTemplate({
      context: eluveAdminHasuraContext,
      variables: {
        tenantId,
        llmOutputTemplateId,
        isEnabled,
      },
    });
  };

  return (
    <Switch
      isCompact
      onCheckedChange={(isChecked) => submit(isChecked)}
      checked={data?.isEnabled ?? false}
    />
  );
};

const tenantOutputTemplateColumns = new ColDefBuilder<OutputTemplateRow>()
  .linkSortable('name', (row) => `/admin/output-templates/${row.id}`)
  .defaultSortable('description')
  .defaultSortable('outputType', 'Output Type')
  .defaultSortable('isEnabled', {
    label: 'Is Enabled',
    cellRenderer: (row) => {
      return (
        <TenantLlmOutputTemplateToggle
          tenantId={row.tenantId}
          llmOutputTemplateId={row.id}
          name={row.name}
        />
      );
    },
  })
  .dateSortable('templateUpdatedAt', 'Template Updated At')
  .dateSortable('accessUpdatedAt', 'Access Updated At')
  .build();

const TenantLlmOutputTemplates: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;
  const data = useCompleteFragment({
    fragment: tenantLlmOutputTemplatesFragment,
    key: {
      id: tenantId,
    },
  });
  const rows = useMemo(() => {
    return (data?.llmOutputTemplates ?? []).map<OutputTemplateRow>(
      (llmOutputTemplate) => ({
        id: llmOutputTemplate.llm_output_template.id,
        name: llmOutputTemplate.llm_output_template.name,
        tenantId,
        description: llmOutputTemplate.llm_output_template.description ?? '',
        outputType: llmOutputTemplate.llm_output_template.llmOutputType,
        isEnabled: llmOutputTemplate.isEnabled,
        accessUpdatedAt: llmOutputTemplate.updatedAt,
        templateUpdatedAt: llmOutputTemplate.llm_output_template.updatedAt,
      }),
    );
  }, [data, tenantId]);

  return (
    <VStack className="grid gap-2">
      <VStack>
        <H3>LLM Output Templates</H3>
        <P>
          Control which LLM output templates this tenant will have access to
        </P>
      </VStack>
      <DataTable
        data={rows}
        columns={tenantOutputTemplateColumns}
        isPaginated={false}
        enableGlobalSearch
        isCompact
        initialSortingState={[
          {
            id: 'templateUpdatedAt',
            desc: true,
          },
        ]}
      />
    </VStack>
  );
};

export const AdminTenantLlmOutputTemplatesPage: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;

  useSuspenseQuery(getLlmOutputTemplatesQuery, {
    variables: {
      tenantId,
    },
    context: eluveAdminHasuraContext,
  });
  return (
    <VStack>
      <TenantLlmOutputTemplates />
    </VStack>
  );
};
