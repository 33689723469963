import { Loader2 } from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInAndUp } from 'supertokens-auth-react/recipe/thirdparty';

import { toast } from '@eluve/components';

export const LoginCallbackPage: React.FC = () => {
  const navigate = useNavigate();
  const didSignIn = useRef(false);

  useEffect(() => {
    const doSignInAndUp = async () => {
      try {
        const response = await signInAndUp();
        if (response.status !== 'OK') {
          toast.error('Sign in response failed');
        }
      } catch (e) {
        toast.error('An error occurred during authentication');
      } finally {
        navigate('/');
      }
    };

    // https://github.com/reactwg/react-18/discussions/18
    // We only want to run this once. In StrictMode, it will try and
    // consume the OAuth params twice which can lead to weird errors
    // or race conditions in local development.
    if (didSignIn.current === false) {
      didSignIn.current = true;
      doSignInAndUp();
    }
  }, [navigate]);

  return (
    <div className="grid h-screen place-items-center">
      <Loader2 className="size-8 animate-spin text-brand-8" />
    </div>
  );
};
