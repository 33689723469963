import { ChevronDown } from 'lucide-react';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@eluve/components';

const factVerifcationPaths = [
  { name: 'Workflows', path: '' },
  { name: 'Source Artifacts', path: 'artifacts' },
  { name: 'Datasets', path: 'datasets' },
  { name: 'Create Workflow', path: 'workflow/create' },
];

const ActiveLocationBreadcrumb = () => {
  const location = useLocation();

  let currentPathName = 'Workflows';

  factVerifcationPaths.forEach((p) => {
    if (p.path.length) {
      if (location.pathname.includes(p.path)) {
        currentPathName = p.name;
      }
    }
  });

  return (
    <BreadcrumbItem>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center gap-1">
          {currentPathName}
          <ChevronDown size={18} />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {factVerifcationPaths.map((location) => (
            <DropdownMenuItem key={location.name} asChild>
              <NavLink to={`fact-verification/${location.path}`}>
                {location.name}
              </NavLink>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </BreadcrumbItem>
  );
};

export const FactVerifactionBreadcrumbs: React.FC = () => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            text="Fact Verification"
            to="/admin/fact-verification"
          />
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <ActiveLocationBreadcrumb />
      </BreadcrumbList>
    </Breadcrumb>
  );
};
