import { useMutation } from '@apollo/client';
import { PackageMinus, PackagePlus } from 'lucide-react';
import React from 'react';
import { match } from 'ts-pattern';

import { useApiClient } from '@eluve/api-client-provider';
import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  P,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

export const modelArgsSettingsFragment = graphql(`
  fragment ModelArgs on LlmModelArgs @_unmask {
    __typename
    isGlobalDefault
    isActive
  }
`);

const setModelArgsActive = graphql(`
  mutation setModelArgsActive($id: uuid!) {
    updateLlmModelArgsByPk(pkColumns: { id: $id }, _set: { isActive: true }) {
      __typename
      id
      isActive
    }
  }
`);

export interface ToggleModelActiveActionProps {
  modelArgsId: string;
}

export const ToggleModelActiveAction: React.FC<
  ToggleModelActiveActionProps
> = ({ modelArgsId }) => {
  const modelArgsSettings = useCompleteFragment({
    fragment: modelArgsSettingsFragment,
    key: {
      id: modelArgsId,
    },
    strict: false,
  });

  const [markModelArgsActive] = useMutation(setModelArgsActive, {
    optimisticResponse: (data) => ({
      updateLlmModelArgsByPk: {
        __typename: 'LlmModelArgs' as const,
        id: data.id,
        isActive: true,
      },
    }),
    onError: () => {
      toast.error('Failed to update model args');
    },
  });

  const apiClient = useApiClient();

  const deactivateModel = async () => {
    const result = await apiClient.prompts.deactivateModelArgs({
      body: {
        modelArgsId,
      },
    });

    if (result.status !== 201) {
      toast.error('Failed to deactivate model args');
      return;
    }

    cacheUtils.updateFragment(
      {
        fragment: modelArgsSettingsFragment,
        key: {
          id: modelArgsId,
        },
      },
      () => {
        return {
          __typename: 'LlmModelArgs' as const,
          isActive: false,
          isGlobalDefault: modelArgsSettings!.isGlobalDefault,
        };
      },
    );

    toast.success('Successfully deactivated model args');
  };

  const toggleActive = async () => {
    if (modelArgsSettings!.isActive) {
      await deactivateModel();
    } else {
      await markModelArgsActive({
        variables: {
          id: modelArgsId,
        },
      });
    }
  };

  if (!modelArgsSettings || modelArgsSettings.isGlobalDefault) {
    return null;
  }

  const { label, description, action, Component } = match(
    modelArgsSettings.isActive,
  )
    .with(true, () => {
      const description = (
        <div>
          <P>
            Marking this model as inactive will make it unavailable to be used
            with prompts
          </P>
        </div>
      );

      return {
        label: 'Mark this model args as inactive',
        description,
        action: 'Deactivate',
        Component: <PackageMinus className="text-orange" />,
      };
    })
    .otherwise(() => ({
      label: 'Mark this model as active',
      description: (
        <P>
          Marking this model as active will make it available to be used with
          prompts
        </P>
      ),
      action: 'Activate',
      Component: <PackagePlus className="text-green" />,
    }));

  return (
    <AlertDialog>
      <TooltipLabel label={label}>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline">
            {Component}
          </Button>
        </AlertDialogTrigger>
      </TooltipLabel>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-gray-11">
            Are you sure?
          </AlertDialogTitle>
          <AlertDialogDescription asChild>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel autoFocus>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={toggleActive}
          >
            {action}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
