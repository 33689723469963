import { PanelLeftOpenIcon } from 'lucide-react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import {
  Button,
  H1,
  HStack,
  ReskinContent,
  ReskinHeader,
  ReskinMain,
  SidebarTrigger,
} from '@eluve/components';
import { TabNav } from '@eluve/smart-blocks';

export const TenantSettingsMainLayout: React.FC = () => {
  return (
    <ReskinMain>
      <ReskinHeader>
        <SidebarTrigger asChild>
          <Button size="icon" variant="ghost">
            <PanelLeftOpenIcon />
          </Button>
        </SidebarTrigger>
        <HStack>
          <H1 p="p-2">Tenant Settings</H1>
        </HStack>
        {/* <AdminLocationsBreadcrumbs /> */}
      </ReskinHeader>

      <ReskinContent variant="container">
        <TabNav
          tabs={[
            {
              link: 'session-types',
              name: 'Session Types',
              featureFlag: 'SESSION_TYPES',
            },
            {
              link: 'layers',
              name: 'Layers',
            },
            {
              link: 'locations',
              name: 'Locations',
            },
            {
              link: 'user-groups',
              name: 'User Groups',
              featureFlag: 'USER_GROUPS',
            },
          ]}
        />
        <Outlet />
      </ReskinContent>
    </ReskinMain>
  );
};
