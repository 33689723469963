import { graphql } from '@eluve/graphql.tada';

export const searchBillingCodesQuery = graphql(`
  query searchBillingCodes(
    $query: String!
    $limit: Int!
    $types: [MedicalCodeTypesEnum!]!
  ) {
    searchMedicalCodes(
      args: { search_term: $query, _limit: $limit }
      where: { codeType: { _in: $types } }
    ) {
      __typename
      id
      code
      codeType
      description
    }
  }
`);

export const searchTenantMedicalCodesQuery = graphql(`
  query searchTenantMedicalCodes(
    $query: String!
    $tenantId: uuid!
    $limit: Int!
    $types: [MedicalCodeTypesEnum!]!
  ) {
    searchTenantMedicalCodes(
      args: { search_term: $query, search_tenant_id: $tenantId, _limit: $limit }
      where: { codeType: { _in: $types } }
    ) {
      __typename
      id
      code
      codeType
      description
    }
  }
`);
