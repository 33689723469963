import noop from 'lodash/noop';
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { useParams } from 'react-router-dom';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  H2,
  H4,
  H5,
  P,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  VStack,
} from '@eluve/components';
import { SummarySectionMarkdown } from '@eluve/smart-blocks';
import { removeSpecialCharacters } from '@eluve/utils';
import {
  AppointmentSummaryKeys,
  summaryKeyToSectionTitleMap,
} from '@eluve/utils';
import { getVendorProvider } from '@eluve/vendor';

import { ehrDiffContentFragment } from './operations';

export const EhrDiffContent: React.FC = () => {
  const { appointmentId } = useParams() as {
    tenantId: string;
    appointmentId: string;
  };
  const appointment = useCompleteFragment({
    fragment: ehrDiffContentFragment,
    key: { id: appointmentId },
  });

  const humanSoapNote = appointment?.humanOutputs?.[0];
  const humanSoapNoteContent = humanSoapNote?.output?.content;
  const { additionalNotes } = appointment?.doctor_interaction ?? {};

  let ehrSignedNote: { [key in AppointmentSummaryKeys]?: string } = {};
  let chartParts: Record<string, unknown>[] = [];
  const externalChart = appointment?.external_charts?.[0];
  const externalEhrVendor = externalChart?.external_ehr?.vendor;
  if (externalEhrVendor) {
    const vendorProvider = getVendorProvider(externalEhrVendor);
    if (vendorProvider.convertChartToSummary) {
      ehrSignedNote = vendorProvider.convertChartToSummary(
        externalChart?.rawData ?? {},
      );
    }
    if (vendorProvider.convertChartToParts) {
      chartParts = vendorProvider.convertChartToParts(externalChart?.rawData);
    }
  }

  return (
    <div className="flex flex-col gap-8">
      {Object.entries(summaryKeyToSectionTitleMap).map(
        ([key, sectionTitle]) => {
          const summaryKey = key as AppointmentSummaryKeys;
          return (
            <VStack key={summaryKey}>
              <H5>{sectionTitle}</H5>
              <div className="text-sm">
                <ReactDiffViewer
                  oldValue={removeSpecialCharacters(
                    humanSoapNoteContent?.[summaryKey],
                  )}
                  newValue={removeSpecialCharacters(
                    ehrSignedNote?.[summaryKey],
                  )}
                  splitView={true}
                  showDiffOnly={true}
                />
              </div>
            </VStack>
          );
        },
      )}

      {additionalNotes && (
        <SummarySectionMarkdown
          appointmentId={appointmentId}
          key="AdditionalNotes"
          summaryKey={`AdditionalNotes` as any}
          sectionTitle="Additional Notes"
          content={additionalNotes}
          disabled={true}
          handleContentChange={noop}
        />
      )}
      {Boolean(chartParts?.length) && (
        <div>
          <H2>View Raw EHR Chart</H2>
          {chartParts.map((part, index) => {
            return (
              <div key={`chart-part-${index}`} className="mb-4 mt-4">
                <Table className="min-w-full divide-y divide-brandGray200">
                  <TableHeader className="bg-brandGray50">
                    <H4 cn="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                      Chart Entry
                    </H4>
                  </TableHeader>
                  <TableBody className="divide-y divide-brandGray200 bg-white">
                    {Object.entries(part).map(([key, value], rowIndex) => (
                      <TableRow
                        key={`row-${index}-${rowIndex}`}
                        className={
                          rowIndex % 2 === 0 ? 'bg-brandGray50' : 'bg-white'
                        }
                      >
                        <TableCell className="whitespace-nowrap px-6 py-4 text-sm font-medium text-contentPrimary">
                          <P>{key}</P>
                        </TableCell>
                        <TableCell className="whitespace-nowrap px-6 py-4 text-sm text-contentSupporting">
                          <P>{JSON.stringify(value)}</P>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
