import React, { useState } from 'react';
import Markdown from 'react-markdown';

import { useCompleteFragment } from '@eluve/apollo-client';
import {
  Box,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  H6,
  HStack,
  Icon,
  Icons,
  VStack,
  textStyles,
} from '@eluve/components';
import { patientOverviewFragment } from '@eluve/frontend-appointment-hooks';
import { patientOverviewSchema } from '@eluve/llm-outputs';
import { nestedObjToMarkdown } from '@eluve/utils';

interface PatientOverviewProps {
  patientOverviewId: string;
}

export const PatientOverview: React.FC<PatientOverviewProps> = ({
  patientOverviewId,
}) => {
  const [opened, setOpened] = useState(false);
  const patientOverview = useCompleteFragment({
    fragment: patientOverviewFragment,
    key: { id: patientOverviewId },
    strict: false,
  });
  const parsedPatientOverview = patientOverviewSchema.safeParse(
    patientOverview?.content ?? '',
  );

  if (!parsedPatientOverview.success) {
    return null;
  }
  const { details, insights } = parsedPatientOverview.data;

  if (!details.length && !insights.length) {
    return null;
  }

  const patientDetails = nestedObjToMarkdown({ details }).details;
  const patientInsights = nestedObjToMarkdown({ insights }).insights;

  return (
    <VStack
      wFull
      align="stretch"
      className="rounded-lg bg-mainBackground p-5"
      gap={0}
    >
      <Collapsible open={opened} onOpenChange={setOpened}>
        <CollapsibleTrigger className="w-full">
          <HStack wFull align="stretch" justify="between">
            <H6>Patient Overview</H6>
            <Icons.ChevronDown
              className='size-5 transition-transform duration-300 ease-in-out data-[state="open"]:-rotate-180'
              data-state={opened ? 'open' : 'closed'}
            />
          </HStack>
        </CollapsibleTrigger>

        <CollapsibleContent className="py-5">
          <HStack
            className={textStyles.body({
              color: 'secondary',
              weight: 'normal',
              size: 'm',
            })}
          >
            <Markdown className="prose ml-2 [&>ul]:list-disc">
              {patientDetails}
            </Markdown>
          </HStack>
        </CollapsibleContent>
      </Collapsible>

      {!opened && (
        <HStack
          className={textStyles.body({
            color: 'secondary',
            weight: 'normal',
            size: 'm',
            className: 'pb-5 pt-5',
          })}
        >
          <Markdown className="prose ml-2 [&>ul]:list-disc">
            {`${patientDetails?.substring(0, 150)}...`}
          </Markdown>
        </HStack>
      )}

      {insights && (
        <Box className="rounded-lg border bg-gray-1 p-3 shadow-md">
          <HStack
            align="start"
            className={textStyles.body({
              color: 'tertiary',
              weight: 'medium',
              size: 's',
            })}
          >
            <Box className="p-1">
              <Icon
                className="rounded-lg bg-purple p-1 text-purpleContrast"
                name="Lightbulb"
                size="lg"
              />
            </Box>
            <Box>
              <Markdown>{patientInsights}</Markdown>
            </Box>
          </HStack>
        </Box>
      )}
    </VStack>
  );
};

PatientOverview.displayName = 'PatientOverview';
