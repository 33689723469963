import { useSuspenseQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import React from 'react';

import {
  eluveAdminHasuraContext,
  useCompleteFragment,
} from '@eluve/apollo-client';
import { Switch, toast } from '@eluve/components';
import { ColDefBuilder, DataTable, H3, P } from '@eluve/components';
import { MedicalCodeTypesEnum } from '@eluve/graphql-types';
import { graphql } from '@eluve/graphql.tada';
import { useAssignedTenantIdFromParams } from '@eluve/session-helpers';

const tenantMedicalCodeTypeFragment = graphql(`
  fragment TenantMedicalCodeType on TenantMedicalCodeTypes @_unmask {
    __typename
    tenantId
    codeTypeName
    isEnabled
    updatedAt
  }
`);

const tenantMedicalCodeTypesFragment = graphql(
  `
    fragment TenantMedicalCodeTypes on Tenants @_unmask {
      __typename
      id
      codeTypes {
        ...TenantMedicalCodeType
      }
    }
  `,
  [tenantMedicalCodeTypeFragment],
);

const getTenantMedicalCodeTypesQuery = graphql(
  `
    query getLlmOutputTemplates($tenantId: uuid!) {
      tenantsByPk(id: $tenantId) {
        ...TenantMedicalCodeTypes
      }
    }
  `,
  [tenantMedicalCodeTypesFragment],
);

const setTenantMedicalCodeTypeMutation = graphql(
  `
    mutation setTenantMedicalCodeType(
      $tenantId: uuid!
      $medicalCodeType: MedicalCodeTypesEnum!
      $isEnabled: Boolean!
    ) {
      insertTenantMedicalCodeTypesOne(
        object: {
          tenantId: $tenantId
          codeTypeName: $medicalCodeType
          isEnabled: $isEnabled
        }
        onConflict: {
          constraint: tenant_medical_code_types_pkey
          updateColumns: [isEnabled]
        }
      ) {
        ...TenantMedicalCodeType
      }
    }
  `,
  [tenantMedicalCodeTypeFragment],
);

type CodeTypeRow = {
  tenantId: string;
  codeTypeName: MedicalCodeTypesEnum;
  isEnabled: boolean;
  updatedAt: string;
};

const TenantCodeTypeToggle: React.FC<{
  tenantId: string;
  codeTypeName: MedicalCodeTypesEnum;
}> = ({ tenantId, codeTypeName }) => {
  const data = useCompleteFragment({
    fragment: tenantMedicalCodeTypeFragment,
    key: {
      tenantId,
      codeTypeName,
    },
    strict: false,
  });

  const [updateTenantMedicalCodeType] = useMutation(
    setTenantMedicalCodeTypeMutation,
    {
      onError: () =>
        toast.error(`Failed to update access to Code Type ${codeTypeName}`),
      optimisticResponse: (data) => ({
        insertTenantMedicalCodeTypesOne: {
          __typename: 'TenantMedicalCodeTypes' as const,
          codeTypeName: data.medicalCodeType,
          isEnabled: data.isEnabled,
          tenantId: tenantId,
          updatedAt: new Date().toISOString(),
        },
      }),
    },
  );

  const submit = async (isEnabled: boolean) => {
    try {
      await updateTenantMedicalCodeType({
        context: eluveAdminHasuraContext,
        variables: {
          tenantId,
          medicalCodeType: codeTypeName,
          isEnabled,
        },
      });
    } catch (e) {
      toast.error(`Failed to update access to Code Type ${codeTypeName}`);
    }
  };

  return (
    <Switch
      isCompact
      onCheckedChange={(isChecked) => submit(isChecked)}
      checked={data?.isEnabled ?? false}
    />
  );
};

const columns = new ColDefBuilder<CodeTypeRow>()
  .defaultSortable('codeTypeName', 'Code Type')
  .defaultSortable('isEnabled', {
    label: 'Is Enabled',
    cellRenderer: (row) => {
      return (
        <TenantCodeTypeToggle
          tenantId={row.tenantId}
          codeTypeName={row.codeTypeName}
        />
      );
    },
  })
  .dateSortable('updatedAt', 'Access Updated At')
  .build();

export const AdminTenantCodeTypesPage: React.FC = () => {
  const tenantId = useAssignedTenantIdFromParams();

  const { data } = useSuspenseQuery(getTenantMedicalCodeTypesQuery, {
    variables: {
      tenantId,
    },
    context: eluveAdminHasuraContext,
  });

  const rows = data?.tenantsByPk?.codeTypes ?? [];

  return (
    <div className="grid gap-2">
      <div>
        <H3>Code Types</H3>
        <P>Control which Medical Code types this tenant will have access to</P>
      </div>
      <div className="w-full overflow-hidden">
        <DataTable
          data={rows}
          columns={columns}
          isPaginated={false}
          enableGlobalSearch
          isCompact
        />
      </div>
    </div>
  );
};
