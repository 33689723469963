import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { llmOutputTypesSchema } from '@eluve/graphql-types';

const c = initContract();

export const PromptMessageRoleTypeSchema = z.enum([
  'system',
  'user',
  'assistant',
  // TODO(jesse)[ELU-980]: Implement if/when we start actually leveraging functions
  // 'function',
]);
export type PromptMessageRoleType = z.infer<typeof PromptMessageRoleTypeSchema>;

export const PromptMessageSchema = z.object({
  role: PromptMessageRoleTypeSchema,
  content: z.string(),
});
export type PromptMessage = z.infer<typeof PromptMessageSchema>;
export const SubmittablePromptSchema = z.array(PromptMessageSchema);

export const CreatePromptTemplateSchema = z
  .object({
    name: z.string().min(3),
    description: z.string().optional(),
    outputType: llmOutputTypesSchema,
    template: SubmittablePromptSchema,
    variantNotes: z.string().optional(),
  })
  .refine(
    (v) => v.template.some((m) => m.role === 'user' && m.content.length > 5),
    {
      message: 'Content for a user message is required',
      path: ['template'],
    },
  );

export type CreatePromptTemplate = z.infer<typeof CreatePromptTemplateSchema>;

/**
 * Represents a "hydrated" prompt template which has had its
 * context (eg transcript) filled in and is ready to be submitted
 * to an LLM for completion.
 */
export type SubmittablePrompt = z.infer<typeof SubmittablePromptSchema>;

export const promptsContract = c.router(
  {
    createPromptTemplate: {
      method: 'POST',
      path: '',
      body: CreatePromptTemplateSchema,
      responses: {
        201: z.object({
          id: z.string(),
        }),
      },
    },
    setGlobalDefaultModelArgsForSOAPNoteOutputType: {
      method: 'POST',
      path: 'models',
      body: z.object({
        modelArgsId: z.string().uuid(),
      }),
      responses: {
        201: z.object({}),
      },
    },
    deactivateModelArgs: {
      method: 'POST',
      path: 'models/deactivate',
      body: z.object({
        modelArgsId: z.string().uuid(),
      }),
      responses: {
        201: z.object({}),
      },
    },
  },

  { pathPrefix: '/prompts' },
);
