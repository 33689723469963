import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@eluve/components';

export const OutputTemplateBreadcrumbs: React.FC = () => {
  const { outputTemplateId } = useParams() as {
    outputTemplateId?: string;
  };

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            text="Output Templates"
            to="/admin/output-templates"
          />
        </BreadcrumbItem>
        {outputTemplateId && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink
                text="Output Template Details"
                to={`/admin/output-templates/${outputTemplateId}`}
              />
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
