import noop from 'lodash/noop';
import React from 'react';

import { useCompleteFragment } from '@eluve/apollo-client';
import { VStack } from '@eluve/components';
import {
  DynamicArtifactTemplate,
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import {
  DynamicArtifactRenderer,
  SummarySectionMarkdown,
} from '@eluve/smart-blocks';
import {
  AppointmentSummaryKeys,
  summaryKeyToSectionTitleMap,
} from '@eluve/utils';

import { useAppointmentParams } from '../useAppointmentParams';

import { summaryContentFragment } from './operations';

export const SummaryContent: React.FC = () => {
  const { appointmentId } = useAppointmentParams();

  const appointment = useCompleteFragment({
    fragment: summaryContentFragment,
    key: { id: appointmentId },
  });

  let hydratedLlmSummary: DynamicArtifactTemplate | undefined = undefined;

  const humanSoapNote = appointment?.humanOutputs?.[0];
  const llmSoapNoteContent = humanSoapNote?.output?.llm_output?.content;

  const outputVariant =
    humanSoapNote?.output?.llm_output?.metadata?.output_variant;

  if (outputVariant) {
    const artifactTemplate = dynamicArtifactTemplateSchema.parse(
      outputVariant.template,
    );
    hydratedLlmSummary = hydrateDynamicArtifactTemplate(
      artifactTemplate,
      llmSoapNoteContent ?? {},
    );
  }
  return (
    <VStack>
      {hydratedLlmSummary ? (
        <DynamicArtifactRenderer
          isReadonly={true}
          blocks={hydratedLlmSummary.blocks}
        />
      ) : (
        <VStack>
          {Object.entries(summaryKeyToSectionTitleMap)
            .filter(([summaryKey]) => llmSoapNoteContent?.[summaryKey])
            .map(([key, sectionTitle]) => {
              const summaryValue = llmSoapNoteContent?.[key];
              const summaryKey = key as AppointmentSummaryKeys;
              return (
                <SummarySectionMarkdown
                  appointmentId={appointmentId}
                  key={summaryKey}
                  summaryKey={summaryKey}
                  sectionTitle={sectionTitle}
                  content={summaryValue}
                  disabled={true}
                  handleContentChange={noop}
                  className="w-full"
                />
              );
            })}
        </VStack>
      )}
    </VStack>
  );
};
