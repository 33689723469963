import { Navigate } from 'react-router-dom';

import { FCC, toast } from '@eluve/components';
import { useRolesFromSession } from '@eluve/session-helpers';

export const TenantAdminGuard: FCC = ({ children }) => {
  const roles = useRolesFromSession();
  const isTenantAdmin = roles.includes('tenant-admin');

  if (!isTenantAdmin) {
    toast.error('Access denied');
    return <Navigate to="/" replace />;
  }

  return children;
};
