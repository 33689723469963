import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';
import { usePrevious } from 'react-use';

import { eluveAdminHasuraContext } from '@eluve/apollo-client';
import { toast } from '@eluve/components';
import {
  getCurrentSummary,
  getCurrentSummaryForEluveAdmin,
  useAppointmentActivity,
  useAppointmentContext,
} from '@eluve/frontend-appointment-hooks';
import { WorkerStatusTypesLookup } from '@eluve/graphql-types';
import { useIsEluveAdmin } from '@eluve/session-helpers';

import { SUMMARY_TAB, useAppointmentTabNav } from './useAppointmentTabNav';

export const useListenForSummary = () => {
  const { isSummarizationInProgress, latestSummaryStatus } =
    useAppointmentActivity();
  const { tenantId, appointmentId } = useAppointmentContext();
  const { setTab } = useAppointmentTabNav();
  const isEluveAdmin = useIsEluveAdmin();
  const summaryQuery = useMemo(
    () => (isEluveAdmin ? getCurrentSummaryForEluveAdmin : getCurrentSummary),
    [isEluveAdmin],
  );

  const [getLatest] = useLazyQuery(summaryQuery, {
    variables: {
      tenantId,
      appointmentId,
    },
    context: isEluveAdmin ? eluveAdminHasuraContext : undefined,
  });

  const updateSummary = async () => {
    await getLatest();
    setTab(SUMMARY_TAB);

    if (latestSummaryStatus === WorkerStatusTypesLookup.COMPLETED) {
      toast.success('Summary is ready!', {
        description: 'Your summary is ready to be reviewed.',
      });
    }
  };

  const wasSummarizationInProgress = usePrevious(
    isSummarizationInProgress ?? false,
  );

  if (wasSummarizationInProgress && !isSummarizationInProgress) {
    updateSummary();
  }
};
