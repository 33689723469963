import { useQuery } from '@apollo/client';

import {
  ClassicSummary,
  DynamicSummary,
  dynamicArtifactTemplateSchema,
  hydrateDynamicArtifactTemplate,
} from '@eluve/llm-outputs';
import { AppointmentSummarySchema } from '@eluve/utils';

import { useAppointmentContext } from './appointment.context';
import { getAllSummaries } from './operations';

type TemplateIdAndVariant = {
  id: string;
  variantNumber: number;
};

type LlmSummary = {
  summaryId: string;
  llmModelPromptTemplateId: string | null;
  promptTemplate: TemplateIdAndVariant;
  summary: ClassicSummary | DynamicSummary;
  isSummaryAvailable: boolean;
  summarizationCompletedAt: string | null;
  outputTemplate?: TemplateIdAndVariant;
  rawContent?: Record<string, any> | null;
};

export const useAllSummaries = (): {
  summaries: LlmSummary[];
} => {
  const { tenantId, appointmentId } = useAppointmentContext();
  const { data } = useQuery(getAllSummaries, {
    variables: {
      tenantId,
      appointmentId,
    },
  });

  const llmOutputs = data?.appointmentsByPk?.appointment_llm_outputs ?? [];

  const summaries = llmOutputs
    // safety check to enforce that all summaries we display should have a prompt template and variant
    .filter(
      (llo) =>
        Boolean(llo.output.metadata?.prompt_template) &&
        Boolean(llo.output.metadata?.prompt_variant),
    )
    .map<LlmSummary>(({ output: llmOutput }) => {
      const summary = llmOutput?.content ?? null;

      const shared: Omit<LlmSummary, 'summary' | 'isSummaryAvailable'> = {
        summaryId: llmOutput.id,
        llmModelPromptTemplateId: llmOutput.modelPromptTemplate.id,
        promptTemplate: {
          id: llmOutput.metadata!.prompt_template!.id,
          variantNumber: llmOutput.metadata!.prompt_variant!.variantNumber,
        },
        summarizationCompletedAt: llmOutput.completedAt,
      };

      const outputVariant = llmOutput.metadata?.output_variant;

      if (outputVariant) {
        const outputTemplateParsed = dynamicArtifactTemplateSchema.parse(
          outputVariant.template,
        );

        const hydrated = hydrateDynamicArtifactTemplate(
          outputTemplateParsed,
          summary ?? {},
        );

        return {
          ...shared,
          isSummaryAvailable: true,
          outputTemplate: {
            id: outputVariant.outputTemplateId,
            variantNumber: outputVariant.variantNumber,
          },
          rawContent: summary,
          summary: {
            type: 'DYNAMIC',
            blocks: hydrated.blocks,
          },
        };
      }

      const parsed = AppointmentSummarySchema.safeParse(llmOutput?.content);

      if (parsed.success) {
        return {
          ...shared,
          summary: {
            type: 'SOAP',
            data: parsed.data,
          },
          isSummaryAvailable: true,
        };
      }
      return {
        ...shared,
        summary: {
          type: 'SOAP',
          data: {
            SUBJECTIVE: null,
            OBJECTIVE: null,
            ASSESSMENT: null,
            CLIENT_RECAP: null,
          },
        },
        isSummaryAvailable: false,
      };
    });

  return { summaries };
};
