import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { CodeType, MedicalCode, MedicalCodeTag } from '@eluve/blocks';
import {
  Checkbox,
  CurrencyInput,
  Dialog,
  DialogContent,
  DialogFooter,
  Divider,
  HStack,
  Icon,
  NewButton,
  P,
  Skeleton,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';

import {
  addAppointmentBillingCodeLinkMutation,
  removeAppointmentBillingCodeLinkMutation,
  updateAppointmentBillingCodePriceMutation,
  updateAppointmentBillingCodeQuantityMutation,
} from './AppointmentBillingCodePrices.operations';
import { appointmentBillingCodesFragment } from './AppointmentBillingCodes.operations';

// Types

type AppointmentBillingCode = {
  id: string;
  medical_code: AppointmentMedicalCode | null;
  price: number | null;
  quantity: number | null;
};

type AppointmentMedicalCode = {
  id: string;
  code: string;
  codeType: CodeType;
  description: string | null;
};

// Private Components

interface PriceControlProps {
  medicalCode: AppointmentMedicalCode;
  onChange: (value: number) => void;
  price: number | null;
}

const PriceControl: React.FC<PriceControlProps> = ({
  medicalCode,
  onChange,
  price,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string | undefined>(
    ((price ?? 0) / 100).toFixed(2),
  );

  useEffect(() => {
    setValue(((price ?? 0) / 100).toFixed(2));
  }, [price]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    onChange(Math.floor(Number(value) * 100));
    setOpen(false);
    event.preventDefault();
  };

  return (
    <HStack
      className="rounded-lg border border-brandGray200 p-1 pl-4"
      gap={1}
      inline
      wFull={false}
    >
      <div
        className={textStyles.body({
          size: 's',
          className: 'text-brandGray800',
        })}
      >
        {price == null ? (
          <Skeleton className="h-4 w-8" />
        ) : (
          `$${((price ?? 0) / 100).toFixed(2)}`
        )}
      </div>
      <NewButton
        disabled={price == null}
        icon={{ name: 'Pencil' }}
        onClick={() => {
          setOpen(true);
        }}
        spacing="compact"
        type="ghost"
      />
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-sm pt-9">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <VStack align="center">
              <MedicalCodeTag
                code={medicalCode.code}
                codeType={medicalCode.codeType}
              />
              <P className="text-center">{medicalCode.description}</P>
            </VStack>
            <CurrencyInput onChange={setValue} value={value} />
            <DialogFooter className="flex w-full flex-col sm:justify-center">
              <NewButton submit text="Update Price" />
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </HStack>
  );
};

interface QuantityControlProps {
  max?: number;
  min?: number;
  onChange: (value: number) => void;
  value?: number;
}

const QuantityControl: React.FC<QuantityControlProps> = ({
  max,
  min = 0,
  onChange,
  value = 0,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleDecreaseClick = () => {
    const newValue = currentValue - 1;
    setCurrentValue(newValue);
    onChange(newValue);
  };

  const handleIncreaseClick = () => {
    const newValue = currentValue + 1;
    setCurrentValue(newValue);
    onChange(newValue);
  };

  return (
    <HStack
      className="rounded-lg border border-brandGray200 p-1"
      gap={1}
      inline
      wFull={false}
    >
      <NewButton
        disabled={currentValue <= min}
        icon={{ name: 'MinusCircle' }}
        onClick={handleDecreaseClick}
        spacing="compact"
        type="ghost"
      />
      <div
        className={textStyles.body({
          size: 's',
          className: 'min-w-4 select-none text-center text-brandGray800',
        })}
      >
        {currentValue}
      </div>
      <NewButton
        disabled={max != null && currentValue >= max}
        icon={{ name: 'PlusCircle' }}
        onClick={handleIncreaseClick}
        spacing="compact"
        type="ghost"
      />
    </HStack>
  );
};

// Public Components

const textClassName = textStyles.body({
  size: 's',
  className: 'text-brandGray800',
});

export interface AppointmentBillingCodePricesProps {
  appointmentId: string;
  isReadonly?: boolean;
  tenantId: string;
}

export const AppointmentBillingCodePrices: React.FC<
  AppointmentBillingCodePricesProps
> = ({ appointmentId, isReadonly = false, tenantId }) => {
  const [codeQuantities, setCodeQuantities] = useState<
    { cptId: string; quantity: number }[]
  >([]);

  const data = useCompleteFragment({
    fragment: appointmentBillingCodesFragment,
    key: { id: appointmentId },
    strict: false,
  });

  const billingCodes = data?.billingCodes ?? [];
  const cptCodes = billingCodes.filter(
    (code) => code.medical_code?.codeType === 'CPT',
  );
  const icdCodes = billingCodes.filter(
    (code) => code.medical_code?.codeType === 'ICD_10',
  );

  const getQuantity = (billingCode: AppointmentBillingCode) => {
    return (
      codeQuantities.find((q) => q.cptId === billingCode.id)?.quantity ??
      billingCode.quantity ??
      1
    );
  };

  const [updateAppointmentBillingCodePrice] = useMutation(
    updateAppointmentBillingCodePriceMutation,
  );

  const updatePrice = (billingCodeId: string, price: number) => {
    updateAppointmentBillingCodePrice({
      variables: {
        billingCodeId,
        price,
      },
      onError: () => toast.error('We could not update the price.'),
      optimisticResponse: ({ billingCodeId, price }) => {
        return {
          updateAppointmentBillingCodesByPk: {
            __typename: 'AppointmentBillingCodes' as const,
            id: billingCodeId,
            price,
          },
        };
      },
    });
  };

  const handlePriceChange = (id: string, price: number) => {
    updatePrice(id, price);
  };

  const [updateAppointmentBillingCodeQuantity] = useMutation(
    updateAppointmentBillingCodeQuantityMutation,
  );

  const _updateQuantity = useCallback(
    (billingCodeId: string, quantity: number) => {
      updateAppointmentBillingCodeQuantity({
        variables: {
          billingCodeId,
          quantity,
        },
        onError: () => {
          toast.error('We could not update the quantity.');

          setCodeQuantities((prev) =>
            prev.filter((quantity) => quantity.cptId !== billingCodeId),
          );
        },
        optimisticResponse: ({ billingCodeId, quantity }) => {
          return {
            updateAppointmentBillingCodesByPk: {
              __typename: 'AppointmentBillingCodes' as const,
              id: billingCodeId,
              quantity,
            },
          };
        },
      });
    },
    [updateAppointmentBillingCodeQuantity],
  );

  const updateQuantity = useMemo(
    () => debounce(_updateQuantity, 250),
    [_updateQuantity],
  );

  const handleQuantityChange = (cptId: string, quantity: number) => {
    updateQuantity(cptId, quantity);
    setCodeQuantities((prev) => {
      const index = prev.findIndex((q) => q.cptId === cptId);
      if (index === -1) {
        return [...prev, { cptId, quantity }];
      }
      return [
        ...prev.slice(0, index),
        { cptId, quantity },
        ...prev.slice(index + 1),
      ];
    });
  };

  const [addAppointmentBillingCodeLink] = useMutation(
    addAppointmentBillingCodeLinkMutation,
    {
      onError: () => {
        toast.error('We could not select the ICD code.');
      },
    },
  );

  const [removeAppointmentBillingCodeLink] = useMutation(
    removeAppointmentBillingCodeLinkMutation,
    {
      onError: () => {
        toast.error('We could not deselect the ICD code.');
      },
    },
  );

  const handleSelectClick = (
    isSelected: boolean,
    cptId: string,
    icdId: string,
  ) => {
    if (isSelected) {
      removeAppointmentBillingCodeLink({
        variables: {
          tenantId,
          appointmentId,
          sourceBillingCodeId: cptId,
          targetBillingCodeId: icdId,
        },
        optimisticResponse: () => ({
          deleteAppointmentBillingCodeLinks: {
            returning: [
              {
                __typename: 'AppointmentBillingCodeLinks' as const,
                id: v4(),
                sourceAppointmentBillingCodeId: cptId,
                targetAppointmentBillingCodeId: icdId,
              },
            ],
          },
        }),
        update: (_cache) => {
          cacheUtils.updateFragment(
            {
              fragment: appointmentBillingCodesFragment,
              key: {
                id: appointmentId,
              },
            },
            (existing) => {
              if (!existing) {
                return existing;
              }

              return produce(existing, (draft) => {
                const billingCode = draft.billingCodes.find(
                  (code) => code.id === cptId,
                );
                const index =
                  billingCode?.linked_codes.findIndex(
                    (code) => code.targetAppointmentBillingCodeId === icdId,
                  ) ?? -1;
                if (index !== -1) {
                  billingCode?.linked_codes.splice(index, 1);
                }
              });
            },
          );
        },
      });
    } else {
      addAppointmentBillingCodeLink({
        variables: {
          appointmentId,
          sourceBillingCodeId: cptId,
          targetBillingCodeId: icdId,
        },
        optimisticResponse: () => ({
          insertAppointmentBillingCodeLinksOne: {
            __typename: 'AppointmentBillingCodeLinks' as const,
            id: v4(),
            sourceAppointmentBillingCodeId: cptId,
            targetAppointmentBillingCodeId: icdId,
          },
        }),
        update: (_cache, { data }) => {
          cacheUtils.updateFragment(
            {
              fragment: appointmentBillingCodesFragment,
              key: {
                id: appointmentId,
              },
            },
            (existing) => {
              const linkId = data?.insertAppointmentBillingCodeLinksOne?.id;
              if (!existing || !linkId) {
                return existing;
              }

              return produce(existing, (draft) => {
                const billingCode = draft.billingCodes.find(
                  (code) => code.id === cptId,
                );
                billingCode?.linked_codes.push({
                  __typename: 'AppointmentBillingCodeLinks' as const,
                  id: linkId,
                  sourceAppointmentBillingCodeId: cptId,
                  targetAppointmentBillingCodeId: icdId,
                });
              });
            },
          );
        },
      });
    }
  };

  if (cptCodes.length === 0) {
    return null;
  }

  return (
    <VStack gap={3}>
      <h6
        className={textStyles.body({
          weight: 'semibold',
          size: 's',
          className: 'uppercase text-brandGray600',
        })}
      >
        Billing Details
      </h6>
      {cptCodes.map(
        (cptCode) =>
          cptCode.medical_code && (
            <div
              key={cptCode.id}
              className="w-full rounded-lg border border-brandGray200 bg-white p-2"
            >
              <HStack gap={6} justify="between">
                <MedicalCode
                  code={cptCode.medical_code.code}
                  codeType={cptCode.medical_code.codeType}
                  description={cptCode.medical_code.description ?? ''}
                  type="ghost"
                />
                <HStack gap={3} wFull={false} inline className="pr-3.5">
                  {isReadonly ? (
                    <div className={textClassName}>{getQuantity(cptCode)}</div>
                  ) : (
                    <QuantityControl
                      max={50}
                      min={1}
                      onChange={(value) => {
                        handleQuantityChange(cptCode.id, value);
                      }}
                      value={getQuantity(cptCode)}
                    />
                  )}
                  <Icon name="X" size="xs" className="text-brandGray400" />
                  {isReadonly ? (
                    <div className={textClassName}>
                      {`$${((cptCode.price ?? 0) / 100).toFixed(2)}`}
                    </div>
                  ) : (
                    <PriceControl
                      medicalCode={cptCode.medical_code}
                      onChange={(value) => {
                        handlePriceChange(cptCode.id, value);
                      }}
                      price={cptCode.price}
                    />
                  )}
                  <Icon name="Equal" size="xs" className="text-brandGray400" />
                  <div className={textClassName}>
                    {`$${((getQuantity(cptCode) * (cptCode.price ?? 0)) / 100).toFixed(2)}`}
                  </div>
                </HStack>
              </HStack>
              <Divider />
              {icdCodes.length ? (
                <HStack gap={3} wrap>
                  {icdCodes.map((icdCode) => {
                    const isSelected = cptCode.linked_codes.some(
                      (link) =>
                        link.targetAppointmentBillingCodeId === icdCode.id,
                    );

                    if (isReadonly && !isSelected) {
                      return null;
                    }

                    if (!icdCode.medical_code) return null;

                    return (
                      <MedicalCode
                        key={icdCode.id}
                        code={icdCode.medical_code.code}
                        codeType={icdCode.medical_code.codeType}
                        description={icdCode.medical_code.description ?? ''}
                        onClick={
                          isReadonly
                            ? undefined
                            : () =>
                                handleSelectClick(
                                  isSelected,
                                  cptCode.id,
                                  icdCode.id,
                                )
                        }
                        startAdornment={
                          isReadonly ? null : (
                            <Checkbox checked={isSelected} className="ml-1" />
                          )
                        }
                        type={isSelected ? 'outline' : 'outlineDashed'}
                      />
                    );
                  })}
                </HStack>
              ) : (
                <VStack align="center" className="p-3">
                  <P className="text-center text-brandGray600">
                    No ICD codes added above yet.
                  </P>
                </VStack>
              )}
            </div>
          ),
      )}
    </VStack>
  );
};
