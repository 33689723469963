import { useCompleteFragment } from '@eluve/apollo-client';
import { graphql } from '@eluve/graphql.tada';

import { useAppointmentId } from './appointment.context';

export const appointmentActivityFragment = graphql(`
  fragment appointmentActiveState on Appointments {
    isSummarizationInProgress
    isAnySummarizationInProgress
    isTranscriptionInProgress
    isPendingAudioFileUpload

    appointment_llm_jobs(
      distinctOn: llmOutputType
      orderBy: [{ llmOutputType: ASC }, { createdAt: DESC }]
    ) {
      llmOutputType
      jobStatus
      numAttempts
      createdAt
    }
  }
`);

export const useAppointmentActivity = () => {
  const id = useAppointmentId();

  const data = useCompleteFragment({
    fragment: appointmentActivityFragment,
    key: {
      id,
    },
  });

  const {
    isSummarizationInProgress,
    isTranscriptionInProgress,
    isPendingAudioFileUpload,
    isAnySummarizationInProgress,
  } = data;

  const appointmentLlmJobs = data.appointment_llm_jobs ?? [];
  const summaryJob = appointmentLlmJobs.find(
    (job) => job.llmOutputType === 'SOAP_NOTE',
  );
  const latestSummaryStatus = summaryJob?.jobStatus;
  const latestSummaryNumAttempts = summaryJob?.numAttempts;

  return {
    latestSummaryNumAttempts,
    latestSummaryStatus,
    isSummarizationInProgress: isSummarizationInProgress ?? false,
    isAnySummarizationInProgress,
    isTranscriptionInProgress:
      Boolean(isTranscriptionInProgress) || Boolean(isPendingAudioFileUpload),
    appointmentLlmJobs,
  };
};
