import { useMutation, useQuery } from '@apollo/client';
import { produce } from 'immer';
import React, { useMemo, useState } from 'react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import {
  Combobox,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxSelectButton,
  HStack,
  Icon,
  NewButton,
  VStack,
  textStyles,
  toast,
} from '@eluve/components';
import { FragmentOf, graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { tenantReviewerFragment, userFragment } from './gql-operations';

const getUsersQuery = graphql(
  `
    query getUser {
      users {
        ...User
      }
    }
  `,
  [userFragment],
);

const addTenantReviewerMutation = graphql(`
  mutation addTenantReviewer($tenantId: uuid!, $userId: uuid!) {
    insertTenantReviewersOne(object: { tenantId: $tenantId, userId: $userId }) {
      __typename
      tenantId
      userId
    }
  }
`);

export const AddTenantReviewer: React.FC = () => {
  const tenantId = useTenantIdFromParams()!;
  const { data } = useQuery(getUsersQuery);

  const existingReviewers = useCompleteFragment({
    fragment: tenantReviewerFragment,
    key: { id: tenantId },
  });

  const [selectedUser, setSelectedUser] = useState<FragmentOf<
    typeof userFragment
  > | null>(null);

  const users = useMemo(() => {
    return (
      data?.users.filter(
        (user) =>
          !existingReviewers.reviewers.some(
            (existing) => existing.userId === user.id,
          ),
      ) ?? []
    );
  }, [data, existingReviewers]);

  const [addTenantReviewer] = useMutation(addTenantReviewerMutation, {
    onCompleted: () => {
      toast.success('Reviewer added');
      setSelectedUser(null);
    },
    onError: () => {
      toast.error('Failed to add reviewer');
      setSelectedUser(null);
    },
    optimisticResponse: (data) => ({
      insertTenantReviewersOne: {
        __typename: 'TenantReviewers' as const,
        tenantId: data.tenantId,
        userId: data.userId,
        createdAt: new Date().toISOString(),
        user: {
          __typename: 'Users' as const,
          id: data.userId,
          email: selectedUser!.email,
          firstName: selectedUser!.firstName,
          lastName: selectedUser!.lastName,
        },
      },
    }),
    update: (_cache, { data }) => {
      cacheUtils.updateFragment(
        {
          fragment: tenantReviewerFragment,
          key: { id: tenantId },
        },
        (existing) =>
          produce(existing, (draft) => {
            if (!draft) return;
            if (!data?.insertTenantReviewersOne) return;

            draft.reviewers ??= [];
            const { insertTenantReviewersOne } = data;
            const reviewerExists = draft.reviewers.some(
              (reviewer) =>
                insertTenantReviewersOne &&
                reviewer.userId === insertTenantReviewersOne.userId,
            );
            if (!reviewerExists) {
              draft.reviewers.push({
                ...insertTenantReviewersOne,
                createdAt: new Date().toISOString(),
                user: selectedUser!,
              });
            }
          }),
      );
    },
  });

  const addSelectedUserAsReviewer = async () => {
    if (!selectedUser) {
      toast.error('Please select a user');
      return;
    }

    await addTenantReviewer({
      variables: {
        tenantId: tenantId,
        userId: selectedUser.id,
      },
    });
  };

  return (
    <VStack>
      <span className={textStyles.title({ size: 's', weight: 'bold' })}>
        Add Reviewer
      </span>
      <HStack>
        <Combobox>
          <ComboboxSelectButton className="min-w-80" disabled={!users.length}>
            {selectedUser ? (
              <VStack gap={0}>
                <span className={textStyles.body()}>
                  {selectedUser.firstName} {selectedUser.lastName}
                </span>
                <span className={textStyles.label()}>{selectedUser.email}</span>
              </VStack>
            ) : (
              'Select User'
            )}
          </ComboboxSelectButton>
          <ComboboxDropdown searchPlaceholder="Search for user">
            {users.map((u) => {
              const searchValue = [u.firstName, u.lastName, u.email].join(' ');

              return (
                <ComboboxOption
                  key={u.id}
                  searchValue={searchValue}
                  onSelect={() => setSelectedUser(u)}
                >
                  <HStack>
                    <VStack gap={0}>
                      <span className={textStyles.body()}>
                        {u.firstName} {u.lastName}
                      </span>
                      <span className={textStyles.label()}>{u.email}</span>
                    </VStack>

                    {selectedUser?.id === u.id && <Icon name="Check" />}
                  </HStack>
                </ComboboxOption>
              );
            })}
          </ComboboxDropdown>
        </Combobox>

        <NewButton
          text="Add Reviewer"
          disabled={!selectedUser}
          onClick={addSelectedUserAsReviewer}
        />
      </HStack>
    </VStack>
  );
};
