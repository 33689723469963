import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { signIn, signUp } from 'supertokens-auth-react/recipe/emailpassword';
import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-auth-react/recipe/thirdparty';
import { match } from 'ts-pattern';
import { z } from 'zod';

import { useApiClient } from '@eluve/api-client-provider';
import { AuthFormMode, EmailPasswordAuthForm } from '@eluve/blocks';
import {
  Box,
  Button,
  EluveNameLogo,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  GoogleLogo,
  H3,
  Input,
  P,
  cn,
  toast,
} from '@eluve/components';
import { useNamedLogger } from '@eluve/logger';

import { appConfig } from '../config';

const LoginControls: React.FC<{
  initialEmail?: string;
  initialMode?: AuthFormMode;
  enableEmailPassword: boolean;
  enableSocial: boolean;
}> = ({ enableEmailPassword, enableSocial, initialEmail, initialMode }) => {
  const logger = useNamedLogger('LoginPage');
  const navigate = useNavigate();

  return (
    <Box vStack className="w-full items-center">
      {enableEmailPassword && (
        <EmailPasswordAuthForm
          initialEmail={initialEmail}
          initialMode={initialMode}
          onSubmit={async ({ email, password, type }) => {
            const authMethod = type === 'login' ? signIn : signUp;
            const response = await authMethod({
              formFields: [
                {
                  id: 'email',
                  value: email,
                },
                {
                  id: 'password',
                  value: password,
                },
              ],
            });

            if (response.status === 'OK') {
              navigate('/');
            } else {
              logger.warn('Failed to authenticate', {
                status: response.status,
                email,
              });

              if (
                response.status === 'FIELD_ERROR' &&
                response.formFields.some(
                  (f) =>
                    f.id === 'email' &&
                    f.error.includes('email already exists'),
                )
              ) {
                toast.error('Email already exists. Redirecting to login.');
                return 'login';
              } else {
                toast.error(
                  match(response.status)
                    .with('FIELD_ERROR', () => 'Invalid email or password')
                    .with('WRONG_CREDENTIALS_ERROR', () => 'Incorrect password')
                    .otherwise(() => 'Failed to authenticate'),
                );
              }
            }
          }}
        />
      )}
      {match([enableEmailPassword, enableSocial])
        .with([true, true], () => (
          <Box hStack className="my-2 w-full gap-2">
            <div className="h-0.5 w-full bg-gray-4"></div>
            <P className="w-full flex-1 whitespace-nowrap">Or continue with</P>
            <div className="h-0.5 w-full bg-gray-4"></div>
          </Box>
        ))
        .with([false, true], () => (
          <P className="mb-1">Select a provider to login</P>
        ))
        .otherwise(() => null)}
      {enableSocial && (
        <Box hStack>
          <Button
            variant="outline"
            size="lg"
            onClick={async () => {
              try {
                const authUrl =
                  await getAuthorisationURLWithQueryParamsAndSetState({
                    thirdPartyId: 'google',
                    frontendRedirectURI: `${window.location.origin}/login/callback/google`,
                  });

                window.location.assign(authUrl);
              } catch {
                toast.error('Sign in failed');
              }
            }}
          >
            <GoogleLogo className="mr-2" />
            Google
          </Button>
        </Box>
      )}
    </Box>
  );
};

const formSchema = z.object({
  email: z.string().email(),
});

export const LoginPage: React.FC = () => {
  const apiClient = useApiClient();
  const [search] = useSearchParams();
  const errorMessage = search.get('errorMessage') ?? '';
  const logger = useNamedLogger('LoginPage');
  const register = search.get('register');

  const [isFallbackLogin, setIsFallbackLogin] = useState(register === 'true');

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (errorMessage) {
      logger.warn(errorMessage);
      toast.error(errorMessage);
    }
  }, [errorMessage, logger]);

  const email = form.watch('email');

  const tryInitiateLogin = async (email: string) => {
    try {
      const response = await apiClient.auth.tryInitiateLogin({
        body: {
          email,
          appType: 'web',
        },
      });

      if (response.status !== 200) {
        throw new Error('tryInitiateLogin failed');
      }

      const authAction = match(response.body)
        .with({ type: 'saml' }, ({ redirectUrl }) => {
          return async () => {
            const isGoogleSaml = redirectUrl.includes('accounts.google.com');
            const finalRedirect = match(isGoogleSaml)
              .with(true, () => {
                // If logging in with Google SAML, force the user to the account
                // chooser page even if there is only one logged-in in their browser
                const encoded = encodeURIComponent(redirectUrl);
                return `https://accounts.google.com/AccountChooser?continue=${encoded}`;
              })
              .otherwise(() => redirectUrl);

            return window.location.assign(finalRedirect);
          };
        })
        .with({ type: 'social' }, ({ thirdPartyId }) => {
          return async () => {
            try {
              const authUrl =
                await getAuthorisationURLWithQueryParamsAndSetState({
                  thirdPartyId,
                  frontendRedirectURI: `${window.location.origin}/login/callback/${thirdPartyId}`,
                });

              // If logging in with Google OAuth, force the user to choose
              // the account to use even if there is only one logged-in in their browser
              const finalAuthUrl =
                thirdPartyId === 'google'
                  ? `${authUrl}&prompt=consent`
                  : authUrl;

              window.location.assign(finalAuthUrl);
            } catch {
              toast.error('Sign in failed');
            }
          };
        })
        .with({ type: 'email' }, () => {
          return async () => {
            setIsFallbackLogin(true);
          };
        })
        .exhaustive();

      await authAction();
    } catch {
      toast.error('Sign in failed');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && email) {
      form.handleSubmit((f) => tryInitiateLogin(f.email));
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Eluve</title>
      </Helmet>
      <div className="grid h-screen place-items-center p-2">
        <Box vStack className="gap-0.5">
          <EluveNameLogo height={60} />
          <Box className="max-w-[512px]">
            <H3 m="ml-1">
              An AI assistant that automatically does your clinical paperwork
              for you
            </H3>
          </Box>
          <div
            className={cn([
              'mt-2 flex w-full flex-col gap-5 rounded-md border border-gray-5 p-4 shadow-lg',
              'md:w-[512px]',
            ])}
          >
            {match(isFallbackLogin)
              .with(false, () => (
                <>
                  <P className="text-lg font-normal text-gray-10">
                    Get started by entering your email
                  </P>
                  <Form {...form}>
                    <form
                      className="w-full space-y-2"
                      onSubmit={form.handleSubmit((f) =>
                        tryInitiateLogin(f.email),
                      )}
                    >
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <Input
                                placeholder="Email"
                                {...field}
                                onKeyDown={handleKeyDown}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <Button type="submit" className="w-full">
                        Submit
                      </Button>
                    </form>
                  </Form>
                </>
              ))
              .otherwise(() => (
                <LoginControls
                  initialEmail={email}
                  initialMode={register === 'true' ? 'register' : undefined}
                  enableEmailPassword={
                    appConfig.VITE_ENABLE_EMAIL_PASSWORD_AUTH
                  }
                  enableSocial={appConfig.VITE_ENABLE_SOCIAL_AUTH}
                />
              ))}

            <P>
              Not a member yet?{' '}
              <Link to="/waitlist" className="underline">
                Join our waitlist
              </Link>{' '}
              to be notified when we launch
            </P>
          </div>
          <Box hStack className="mt-2 w-full justify-end gap-3 px-4">
            <a
              className="text-sm text-gray-10 underline hover:text-gray-11"
              href="https://www.eluve.com/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
            <a
              className="text-sm text-gray-10 underline hover:text-gray-11"
              href="https://www.eluve.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </Box>
        </Box>
      </div>
    </>
  );
};
