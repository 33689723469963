import { graphql } from '@eluve/graphql.tada';

export const llmOutputTemplateVariantFragment = graphql(`
  fragment LlmOutputTemplateVariant on LlmOutputTemplateVariants @_unmask {
    __typename
    outputTemplateId
    variantNumber
    template
    isCurrentDefault
    createdAt
    variantNotes
    creator {
      __typename
      id
      firstName
      lastName
    }
  }
`);

export const llmOutputTemplateFragment = graphql(
  `
    fragment LlmOutputTemplate on LlmOutputTemplates @_unmask {
      __typename
      id
      name
      description
      isActive
      llmOutputType
      variants(orderBy: { variantNumber: DESC }) {
        ...LlmOutputTemplateVariant
      }
    }
  `,
  [llmOutputTemplateVariantFragment],
);

export const externalChartTemplateFragment = graphql(`
  fragment ExternalChartTemplate on ExternalChartTemplates @_unmask {
    __typename
    id
    externalEhrId
    externalChartTemplateId
    tenantId
  }
`);

export const llmOutputTemplateWithExternalChartTemplatesFragment = graphql(
  `
    fragment LlmOutputTemplateWithExternalChartTemplatesFragment on LlmOutputTemplates
    @_unmask {
      ...LlmOutputTemplate
      external_chart_templates {
        ...ExternalChartTemplate
      }
    }
  `,
  [llmOutputTemplateFragment, externalChartTemplateFragment],
);

export const activeDynamicOutputPromptTemplatesFragment = graphql(`
  fragment ActiveDynamicOutputPromptTemplates on query_root @_unmask {
    activePromptTemplates(
      where: { outputType: { _in: ["DYNAMIC_OUTPUT"] } }
      orderBy: { name: ASC }
    ) {
      __typename
      id
      isCurrentDefault
      name
      description
      outputType
    }
  }
`);

export const getOutputTemplateQuery = graphql(
  `
    query getOutputTemplate($id: uuid!) {
      llmOutputTemplatesByPk(id: $id) {
        ...LlmOutputTemplateWithExternalChartTemplatesFragment
      }
      ...ActiveDynamicOutputPromptTemplates
    }
  `,
  [
    llmOutputTemplateWithExternalChartTemplatesFragment,
    activeDynamicOutputPromptTemplatesFragment,
  ],
);

export const getOutputTemplateVariantQuery = graphql(
  `
    query getOutputTemplateVariant($id: uuid!, $variantNumber: numeric!) {
      llmOutputTemplatesByPk(id: $id) {
        __typename
        id
        llmOutputType
        variants(where: { variantNumber: { _eq: $variantNumber } }) {
          ...LlmOutputTemplateVariant
        }
      }
    }
  `,
  [llmOutputTemplateVariantFragment],
);

export const createOutputTemplateMutation = graphql(`
  mutation createOutputTemplate($input: LlmOutputTemplatesInsertInput!) {
    insertLlmOutputTemplatesOne(object: $input) {
      __typename
      id
    }
  }
`);

export const createOutputTemplateVariantMutation = graphql(
  `
    mutation createOutputTemplateVariant(
      $outputTemplateId: uuid!
      $template: jsonb!
      $variantNotes: String
    ) {
      insertLlmOutputTemplateVariantsOne(
        object: {
          outputTemplateId: $outputTemplateId
          template: $template
          variantNotes: $variantNotes
        }
      ) {
        ...LlmOutputTemplateVariant
      }
    }
  `,
  [llmOutputTemplateVariantFragment],
);

export const setOutputTemplateAsDefaultMutation = graphql(
  `
    mutation setOutputTemplateAsDefaultMutation(
      $outputTemplateId: uuid!
      $variantNumber: numeric!
    ) {
      updateLlmOutputTemplateVariants(
        _set: { isCurrentDefault: false }
        where: {
          _and: {
            isCurrentDefault: { _eq: true }
            outputTemplateId: { _eq: $outputTemplateId }
          }
        }
      ) {
        returning {
          __typename
          outputTemplateId
          variantNumber
          isCurrentDefault
        }
      }
      updateLlmOutputTemplateVariantsByPk(
        pkColumns: {
          outputTemplateId: $outputTemplateId
          variantNumber: $variantNumber
        }
        _set: { isCurrentDefault: true }
      ) {
        __typename
        outputTemplateId
        variantNumber
        isCurrentDefault
      }
    }
  `,
  [llmOutputTemplateFragment],
);
