import { useMutation } from '@apollo/client';
import { PencilIcon } from 'lucide-react';
import { useState } from 'react';

import { TooltipLabel } from '@eluve/blocks';
import { Button, Input, toast } from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

const UpdateLayerMutation = graphql(`
  mutation updateLayer($id: String!, $tenantId: uuid!, $name: String!) {
    updateLayersByPk(
      pkColumns: { id: $id, tenantId: $tenantId }
      _set: { name: $name }
    ) {
      id
      tenantId
      name
    }
  }
`);

interface RenameLayerActionProps {
  id: string;
  name: string;
}

export const EditableLayerName: React.FC<RenameLayerActionProps> = ({
  id,
  name,
}) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(name);
  const tenantId = useTenantIdFromParams();

  const [updateLayer] = useMutation(UpdateLayerMutation, {
    optimisticResponse: (variables) => ({
      updateLayersByPk: {
        __typename: 'Layers' as const,
        id,
        tenantId: variables.tenantId,
        name: variables.name,
      },
    }),
    onError: () => {
      toast.error('Failed to update layer name');
    },
  });

  const handleDiscard = () => {
    setEditable(false);
    setValue(name);
  };

  const handleSave = () => {
    if (!value) {
      return;
    }

    if (!tenantId || value === name) {
      setEditable(false);
      return;
    }

    updateLayer({
      variables: {
        id,
        tenantId,
        name: value,
      },
    });

    setEditable(false);
  };

  if (!editable) {
    return (
      <div className="flex w-full items-center gap-2">
        <p className="flex-1">{name}</p>
        <TooltipLabel label="Rename layer">
          <Button
            variant="outline"
            size="icon"
            onClick={() => setEditable(true)}
            className="-m-2"
          >
            <PencilIcon size={18} />
          </Button>
        </TooltipLabel>
      </div>
    );
  }

  return (
    <div className="flex w-full items-center gap-2">
      <Input
        className="flex-1"
        placeholder="Layer Name"
        onChange={(e) => setValue(e.target.value.trim())}
        value={value}
        autoFocus
      />
      <Button
        type="button"
        variant="default"
        disabled={!value}
        onClick={handleSave}
      >
        Save
      </Button>
      <Button type="button" variant="outline" onClick={handleDiscard}>
        Close
      </Button>
    </div>
  );
};
