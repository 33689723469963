import { useMutation } from '@apollo/client';
import format from 'date-fns/format';

import { useCompleteFragment } from '@eluve/apollo-client';
import { EditableTitle } from '@eluve/blocks';
import { Box, toast } from '@eluve/components';
import { useAppointmentContext } from '@eluve/frontend-appointment-hooks';
import { graphql } from '@eluve/graphql.tada';

const appointmentNameAndDateFragment = graphql(`
  fragment AppointmentNameFragment on Appointments {
    name
    startDate
  }
`);

const updateAppointmentNameMutation = graphql(`
  mutation UpdateAppointmentName($tenantId: uuid!, $id: uuid!, $name: String!) {
    updateAppointmentsByPk(
      pkColumns: { tenantId: $tenantId, id: $id }
      _set: { name: $name }
    ) {
      id
      name
    }
  }
`);

interface AppointmentNameProps {
  readonly?: boolean;
}

export const AppointmentName: React.FC<AppointmentNameProps> = ({
  readonly,
}) => {
  const { tenantId, appointmentId } = useAppointmentContext();

  const { name, startDate } = useCompleteFragment({
    fragment: appointmentNameAndDateFragment,
    key: {
      id: appointmentId,
    },
  });

  const [updateAppointmentName] = useMutation(updateAppointmentNameMutation, {
    optimisticResponse: (variables) => ({
      updateAppointmentsByPk: {
        id: variables.id,
        name: variables.name,
        __typename: 'Appointments',
      },
    }),
    onError: () => {
      toast.error('Failed to update appointment name');
    },
  });

  const handleNameUpdate = (name: string) => {
    updateAppointmentName({
      variables: {
        tenantId,
        id: appointmentId,
        name,
      },
    });
  };

  return (
    <Box vStack className="gap-1">
      <h6 className="text-gray-10">
        {startDate && format(new Date(startDate), 'MMMM d, yyyy')}
      </h6>
      <EditableTitle
        title={name}
        onTitleChange={handleNameUpdate}
        label="Update Appointment name"
        isReadonly={readonly}
      />
    </Box>
  );
};
