import { useMutation, useQuery } from '@apollo/client';
import { PlusIcon } from 'lucide-react';
import React, { useState } from 'react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { useDialog } from '@eluve/blocks';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  H2,
  H4,
  Label,
  P,
  RadioGroup,
  RadioGroupItem,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';

import {
  llmModelPromptTemplatesDetailsFragment,
  promptTemplateModelsFragment,
} from './prompt.operations';

export const getActiveLlmModelArgsQuery = graphql(`
  query getActiveLlmModelArgs {
    llmModelArgs(where: { isActive: { _eq: true } }) {
      __typename
      id
      name
      description
      modelType
      args
    }
  }
`);

const insertLlmPromptTemplateModelArgsMutation = graphql(
  `
    mutation insertLlmPromptTemplateModelArgs(
      $modelArgsId: uuid!
      $promptTemplateId: uuid!
    ) {
      insertLlmModelPromptTemplatesOne(
        object: {
          modelArgsId: $modelArgsId
          promptTemplateId: $promptTemplateId
        }
      ) {
        ...LlmModelPromptTemplatesDetails
      }
    }
  `,
  [llmModelPromptTemplatesDetailsFragment],
);

export interface AddModelArgsToPromptTemplateDialogProps {
  promptTemplateId: string;
}

export const AddModelArgsToPromptTemplateDialog: React.FC<
  AddModelArgsToPromptTemplateDialogProps
> = ({ promptTemplateId }) => {
  const { isDialogOpen, toggleDialog, setIsDialogOpen } = useDialog();

  const { data: activeLlmModelArgs } = useQuery(getActiveLlmModelArgsQuery);

  const { prompt_models: promptTemplateData } = useCompleteFragment({
    fragment: promptTemplateModelsFragment,
    key: {
      id: promptTemplateId,
    },
  });

  const modelsAvailableToBeAdded =
    activeLlmModelArgs?.llmModelArgs.filter(
      (model) =>
        !promptTemplateData.some(
          (existingModelArgs) => existingModelArgs.modelArgsId === model.id,
        ),
    ) ?? [];

  const [selectedLlmModelArgsId, setSelectedLlmModelArgsId] = useState<
    string | undefined
  >(undefined);
  const selectedModelArgs = selectedLlmModelArgsId
    ? modelsAvailableToBeAdded.find((m) => m.id === selectedLlmModelArgsId)
    : undefined;

  const [insertModelArgs] = useMutation(
    insertLlmPromptTemplateModelArgsMutation,
    {
      onCompleted: () => {
        toast.success('Successfully linked model args to prompt template');
        setSelectedLlmModelArgsId(undefined);
        toggleDialog();
      },
      onError: () =>
        toast.error('Failed to link model args to prompt template'),
      optimisticResponse: {
        insertLlmModelPromptTemplatesOne: {
          __typename: 'LlmModelPromptTemplates',
          id: 'temp-id',
          isDefault: false,
          isActive: true,
          modelArgsId: selectedLlmModelArgsId!,
          promptTemplateId,
          feedback_aggregates: null,
          model_args: {
            __typename: 'LlmModelArgs',
            id: selectedLlmModelArgsId!,
            modelType: selectedModelArgs?.modelType ?? 'CLAUDE_2',
            args: selectedModelArgs?.args ?? null,
          },
        },
      },
      update: (_cachem, { data }) => {
        cacheUtils.updateFragment(
          {
            fragment: promptTemplateModelsFragment,
            key: {
              id: promptTemplateId,
            },
          },
          (existing) => {
            return !data?.insertLlmModelPromptTemplatesOne
              ? existing
              : {
                  __typename: 'PromptTemplates' as const,
                  id: promptTemplateId,
                  prompt_models: (existing?.prompt_models ?? []).concat(
                    data?.insertLlmModelPromptTemplatesOne,
                  ),
                };
          },
        );
      },
    },
  );

  const onConfirm = async () => {
    insertModelArgs({
      variables: {
        modelArgsId: selectedLlmModelArgsId!,
        promptTemplateId,
      },
    });
  };

  return (
    <>
      <Button
        size="xs"
        onClick={toggleDialog}
        disabled={!modelsAvailableToBeAdded.length}
      >
        <PlusIcon className="h-4" />
        Add model args
      </Button>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="flex max-h-[80vh] flex-col gap-5 overflow-y-scroll p-8">
          <H2>Select a model to activate with this template</H2>

          <div>
            <RadioGroup
              defaultValue="comfortable"
              onValueChange={(value) => setSelectedLlmModelArgsId(value)}
            >
              {modelsAvailableToBeAdded.map((modelArgs) => (
                <div key={modelArgs.id} className="flex items-center space-x-2">
                  <RadioGroupItem value={modelArgs.id} id={modelArgs.id} />
                  <Label htmlFor="r1" className="m-1 flex flex-col gap-0.5 p-1">
                    <H4>{modelArgs.modelType}</H4>
                    <code className="rounded-sm bg-gray-3 p-0.5 text-xs text-gray-12">
                      {JSON.stringify(modelArgs.args)}
                    </code>

                    <P>{modelArgs.name}</P>
                    <P>{modelArgs.description}</P>
                  </Label>
                </div>
              ))}
            </RadioGroup>
          </div>

          <DialogFooter>
            <Button onClick={onConfirm} disabled={!selectedLlmModelArgsId}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
