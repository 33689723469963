import { VariantProps } from 'tailwind-variants';

import { tv } from '../cn';

export const inputVariants = tv({
  base: 'rounded border border-transparent bg-transparent p-2 outline-none focus:border-brand-8',
  variants: {
    color: {
      default: 'text-gray-11',
      error:
        'border-negative700 hover:border-negative500 focus:border-negative600',
    },
    size: {
      xs: 'text-xs',
      sm: 'p-1 text-sm',
      md: '',
      lg: 'text-lg',
    },
    variant: {
      default: 'border-gray-3 hover:border-gray-5 focus:border-brand-8',
      subtle: 'border-transparent hover:bg-gray-3 focus:border-gray-2',
    },
    disabled: {
      true: 'pointer-events-none',
    },
  },
  defaultVariants: {
    color: 'default',
    size: 'md',
    variant: 'default',
    disabled: false,
  },
});

export type InputVariants = VariantProps<typeof inputVariants>;
