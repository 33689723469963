import React from 'react';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@eluve/components';

export const UserBreadcrumbs: React.FC = () => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink text="All Users" to="/admin/users" />
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};
