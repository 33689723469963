import { useMutation } from '@apollo/client';
import { CircleMinusIcon } from 'lucide-react';
import { useMemo } from 'react';

import { cacheUtils, useCompleteFragment } from '@eluve/apollo-client';
import { TooltipLabel } from '@eluve/blocks';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  toast,
} from '@eluve/components';
import { graphql } from '@eluve/graphql.tada';
import { useTenantIdFromParams } from '@eluve/session-helpers';

import { tenantLocationsFragment } from './locations.gql';

const DeleteLayerMutation = graphql(`
  mutation deleteLayer($id: String!, $tenantId: uuid!) {
    deleteLayersByPk(id: $id, tenantId: $tenantId) {
      id
    }
  }
`);

interface DeleteLayerActionProps {
  id: string;
  name: string;
  depth: number;
}

export const DeleteLayerAction: React.FC<DeleteLayerActionProps> = ({
  id,
  name,
  depth,
}) => {
  const tenantId = useTenantIdFromParams();

  const tenantsLocations = useCompleteFragment({
    fragment: tenantLocationsFragment,
    key: { id: tenantId! },
  });

  const layerLocations = useMemo(
    () => tenantsLocations.locations.filter((l) => l.depth === depth),
    [tenantsLocations.locations, depth],
  );

  const [deleteLayer] = useMutation(DeleteLayerMutation, {
    optimisticResponse: () => ({
      deleteLayersByPk: {
        __typename: 'Layers' as const,
        id,
      },
    }),
    update: (cache) => {
      cacheUtils.evict({
        typeName: 'Layers',
        key: { id },
      });
      cache.gc();
    },
    onError: () => {
      toast.error(`Failed to delete layer ${name}`);
    },
  });

  const handleRemove = () => {
    if (!tenantId) return;

    deleteLayer({
      variables: {
        id,
        tenantId,
      },
    });
  };

  return (
    <AlertDialog>
      <TooltipLabel label={'Delete layer'}>
        <AlertDialogTrigger asChild disabled={layerLocations.length > 0}>
          <CircleMinusIcon className="cursor-pointer text-destructive" />
        </AlertDialogTrigger>
      </TooltipLabel>
      {layerLocations.length > 0 ? (
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-muted-foreground">
              Cannot delete layer
            </AlertDialogTitle>
            <AlertDialogDescription>
              {'Layer '}
              <strong>{name}</strong>
              {` has `}
              <strong>{layerLocations.length}</strong>
              {` associated location(s).`}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel autoFocus className="text-muted-foreground">
              Close
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      ) : (
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="text-muted-foreground">
              Are you sure?
            </AlertDialogTitle>
            <AlertDialogDescription>
              {'Confirming this action will remove layer '}
              <strong>{name}</strong>
              {' permanently.'}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel autoFocus className="text-muted-foreground">
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
              onClick={handleRemove}
            >
              Remove
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      )}
    </AlertDialog>
  );
};
