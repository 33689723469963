import isNil from 'lodash/isNil';
import merge from 'lodash/merge';

type CacheItem<T> = {
  value: T;
  expiry: number | null;
};

/**
 * A simple in-memory cache for storing persistent values
 * across some application lifecycle with optional TTL (ms).
 */
export class MemoryCache<TCache extends Record<string, unknown>> {
  private readonly cache: Partial<
    Record<keyof TCache, CacheItem<TCache[keyof TCache]>>
  > = {};

  constructor(initialCache: Partial<TCache> = {}) {
    Object.keys(initialCache).forEach((key) => {
      this.cache[key as keyof TCache] = {
        value: initialCache[key as keyof TCache]!,
        expiry: null,
      };
    });
  }

  get = <K extends keyof TCache>(key: K): TCache[K] | null => {
    const cacheItem = this.cache[key];

    if (cacheItem) {
      if (cacheItem.expiry === null || cacheItem.expiry > Date.now()) {
        return cacheItem.value as TCache[K];
      } else {
        delete this.cache[key];
      }
    }

    return null;
  };

  set = <K extends keyof TCache>(
    key: K,
    value: TCache[K],
    ttl?: number,
  ): void => {
    const expiry = ttl ? Date.now() + ttl : null;
    const currentItem = this.cache[key];

    if (
      !isNil(value) &&
      typeof currentItem?.value === 'object' &&
      typeof value === 'object'
    ) {
      this.cache[key] = {
        value: merge(currentItem.value, value),
        expiry,
      };
    } else {
      this.cache[key] = { value, expiry };
    }
  };
}
